.short-label {
  width: 100px !important;
}
.loader-input {
  margin-bottom: 40px !important;
}
.loader-button {
  align-self: center;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.c-story-box{
  .author-title {
    font-size: 1.7rem;
    margin-top: 0.8rem;
    display: flex;
    justify-content: center;
    @extend .fw-medium, .mb-0, .text-black, .text-center;
    div{
      width: 50%!important;
    }
  }  
}
.shimmer-footer-container{
  display: flex;
  justify-content: flex-start !important;
  .shimmer-date{
    width:50px;
  }
}