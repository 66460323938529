.imageBox {
  margin-right: 10px;
  @media (max-width: 767px) {
    padding-left: 0 !important;
    // margin-right: 5px;
  }

  float: left;
  padding-right: 0;
  margin-bottom: 5px;
}

.showImage {
  @media (min-width: 0) {
    height: 75px;
    width: 75px;
  }

  // @media (min-width: 400px) {
  //   height: 55px;
  //   width: 55px;
  // }

  // @media (min-width: 450px) {
  //   height: 60px;
  //   width: 60px;
  // }

  // @media (min-width: 480px) {
  //   height: 65px;
  //   width: 65px;
  // }

  // @media (min-width: 500px) {
  //   height: 80px;
  //   width: 80px;
  // }

  // @media (min-width: 525px) {
  //   height: 70px;
  //   width: 70px;
  // }

  @media (min-width: 576px) {
    height: 90px;
    width: 90px;
  }

  @media (min-width: 768px) {
    height: 100px;
    width: 100px;
  }

  @media (min-width: 992px) {
    height: 125px;
    width: 125px;
  }

  @media (min-width: 1200px) {
    height: 150px;
    width: 150px;
  }

  @media (min-width: 1400px) {
    height: 200px;
    width: 200px;
  }
}

.showDescription {
  // @media (min-width: 0) {
  //   font-size: 1rem !important;
  // }

  // @media (min-width: 992px) {
  //   font-size: 1.2rem !important;
  // }

  // @media (min-width: 1200px) {
  //   font-size: 1.6rem !important;
  // }
  color: white;
  line-height: 1.6rem !important;
  word-break: break-word;
  // overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  // max-height: 11rem;
  font-size: 1.5rem !important;
  font-weight: 300 !important;
  text-align: justify;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
}

.showTitle {
  // @media (min-width: 0) {
  //   font-size: 1.2rem !important;
  // }

  // //   @media (min-width: 576px) {
  // //     font-size: 1rem !important;
  // //   }

  // //   @media (min-width: 768px) {
  // //     font-size: 1.2rem !important;
  // //   }

  // @media (min-width: 992px) {
  //   font-size: 1.8rem !important;
  // }

  // @media (min-width: 1200px) {
  //   font-size: 2.4rem !important;
  // }

  line-height: 2.3rem !important;
  word-break: break-word;
  // overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  // max-height: 3.8rem;
  font-size: 1.8rem !important;
  font-weight: 500 !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  // margin-bottom: 0.1rem !important;
  text-align: justify;
  color: white;
  padding-left: 0 !important;
  margin-bottom: 5px !important;
}

.listAudioEpisodes {
  padding: 2.16rem !important;
}

.showUpperSection {
  display: flow-root;
  @media (min-width: 0) {
    margin: 0 0 15px 0 !important;
  }

  @media (min-width: 768px) {
    margin: 0 0 25px 0 !important;
  }
  // //   @media (min-width: 576px) {
  // //     margin: 0 0 1.52rem !important;
  // //   }

  // @media (min-width: 768px) {
  //   margin: 0 0 2.52rem !important;
  // }

  // //   @media (min-width: 992px) {
  // //     margin: 0 0 1.52rem !important;
  // //   }

  // @media (min-width: 1200px) {
  //   margin: 0 0 3.52rem !important;
  // }

  // //   @media (min-width: 1200px) {
  // //     margin: 0 0 1.52rem !important;
  // //   }
}

.footerShow {
  width: 100%;
  height: auto;
  text-align: center;
  color: white;
  @media (min-width: 0) {
    padding: 0 0 15px 0;
  }
  @media (min-width: 768px) {
    padding: 0 0 25px 0;
  }

  @media (min-width: 0) {
    font-size: 1.4rem !important;
  }

  //   @media (min-width: 576px) {
  //     font-size: 1rem !important;
  //   }

  //   @media (min-width: 768px) {
  //     font-size: 1.2rem !important;
  //   }

  // @media (min-width: 992px) {
  //   font-size: 1.3rem !important;
  // }

  @media (min-width: 1200px) {
    font-size: 1.6rem !important;
  }

  @media (min-width: 1450px) {
    font-size: 1.9rem !important;
  }
}

.footerShowPodcastList li {
  display: inline-block;
  margin: 0 2.5px 0 0;
}

.footerShowPodcastList li:not(:first-child):before {
  content: " | ";
  margin: 0 2.5px;
}

.footerShowPodcastList {
  padding-left: 0 !important;
}

.footerShowDivider {
  height: 2px !important;
  color: white;
  margin: 5px auto;

  @media (min-width: 0) {
    width: 80%;
  }

  @media (min-width: 400px) {
    width: 70%;
  }

  @media (min-width: 450px) {
    width: 65%;
  }

  @media (min-width: 500px) {
    width: 60%;
  }

  @media (min-width: 525px) {
    width: 55%;
  }

  @media (min-width: 768px) {
    width: 60%;
  }

  @media (min-width: 992px) {
    width: 45%;
  }

  @media (min-width: 1200px) {
    width: 35%;
  }

  @media (min-width: 1450px) {
    width: 35%;
  }

  @media (min-width: 1800px) {
    width: 30%;
  }
}

.footerShowIcon {
  @media (min-width: 0) {
    width: 1.5rem;
    height: auto;
    margin-top: -5px;
  }

  @media (min-width: 768px) {
    width: 1.75rem;
    height: auto;
    margin-top: -5px;
  }

  @media (min-width: 1200px) {
    width: 2rem;
    height: auto;
    margin-top: -5px;
  }
}

.footerShowTeleIcon {
  @media (min-width: 0) {
    width: 1rem;
    height: auto;
    margin-top: -5px;
  }

  @media (min-width: 440px) {
    width: 1rem;
    height: auto;
    margin-top: -3px;
  }

  @media (min-width: 768px) {
    width: 1rem;
    height: auto;
    margin-top: -3px;
  }

  @media (min-width: 992px) {
    width: 1.25rem;
    height: auto;
    margin-top: -5px;
  }

  @media (min-width: 1200px) {
    width: 1.5rem;
    height: auto;
    margin-top: -5px;
  }
}

.date {
  // line-height: 1.6rem !important;
  overflow: hidden;
  display: -webkit-box;
  // max-height: 1.6rem;
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: white;
  margin-bottom: 5px;
}

.link {
  color: white;
}

.link:hover {
  color: white !important;
}

.cardColor {
  background-image: linear-gradient(#016369 0, transparent 75%) !important;
}
