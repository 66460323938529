.shorts-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll !important;
  padding: 0;
  background-color: white;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0; /* Safari and Chrome */
  }
  .shorts-body {
    height: -webkit-fill-available;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
    // margin-bottom: 1.5rem;
    // margin-top: 1.5rem;
    .shorts {
      background-color: white;
      // min-height: 95vh;
      height: -webkit-fill-available;
      position: relative;
      // scroll-snap-align: start;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none;
      // margin-bottom: 2rem;
      @media screen and (min-width: 1000px) {
        max-width: 27%;
        min-height: 100vh;
        border-radius: 1.5rem;
        margin: 0 auto 1.5rem;
      }
      @media screen and (min-width: 600px) and (max-width: 1000px) {
        max-width: 45%;
        min-height: 100vh;
        border-radius: 1.5rem;
        margin: 0 auto 1.5rem;
      }

      .short-description {
        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.7));
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000",endColorstr="#a6000000",GradientType=0);
        width: 100%;
        // bottom: 0;
        padding: 8px;
        font-weight: 500;
        h3 {
          display: flex;
          align-items: end;
          color: #fff;
          font-size: 16px;
          margin: 0 0 0.5rem;
          min-height: 4.2rem;
          font-size: 2.3rem !important;
          font-weight: 600 !important;
        }
      }
      .back-arrow {
        position: absolute;
        top: 0;
        left: -60px;
        margin-left: 1rem;
        margin-top: 1rem;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          left: 0px;
        }
        img {
          width: 42px;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          padding: 0.8rem;
          font-size: 2rem;
        }
      }
      .buttons-panel {
        position: absolute;
        right: -18%;
        top: 50%;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 600px) {
          right: 0;
          border-radius: 3rem;
          padding: 0.8rem;
          top: 55%;
          // background: radial-gradient(rgba(0, 0, 0, 0.09) 191%, transparent);
        }
        .like-button {
          // margin-bottom: 2rem;
          i {
            @media screen and (max-width: 600px) {
              font-size: 5rem !important;
            }
          }
        }
        .like-count {
          font-size: 2.5rem;
          color: white;
          margin-top: -1rem;
          display: flex;
          justify-content: center;
        }

        i {
          height: 5rem;
          width: 5rem;
          padding: 1rem;
          border-radius: 50%;
          background-color: rgb(0, 0, 0, 0.05);
          margin: auto;
          font-size: 3rem;
          margin-bottom: 1rem;
          cursor: pointer;
          @media screen and (max-width: 600px) {
            font-size: 5rem !important;
          }
        }
        img {
          height: 5rem;
          width: 5rem;
          padding: 1rem;
          border-radius: 50%;
          background-color: rgb(0, 0, 0, 0.05);
          margin: auto;
          margin-bottom: 1rem;
          cursor: pointer;
          @media screen and (max-width: 600px) {
            height: 7rem;
            width: 7rem;
          }
        }
      }
    }
    .play-icons {
      button {
        position: absolute;
        left: 48%;
        top: 50%;
        padding: 0 !important;
        text-align: left !important;
        border: none;

        img {
          height: 5rem;
          width: 5rem;
        }
      }
    }
  }
}
.vjs-tech-blur {
  filter: brightness(0.8);
}
.vjs-9-16 {
  min-height: 100vh;
}

// .vjs-tech {
//   height: 95% !important;
//   // border-radius: 2rem !important;
// }
// .video-js {
//   background-color: white !important;
// }

.up-down-arrows {
  @media screen and (max-width: 600px) {
    display: none;
  }
}
// .jwplayer.jw-stretch-uniform .jw-media video {
//   object-fit: fill !important;
// }
