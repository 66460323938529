.preference-container {
  // height: auto;
  .preference-title {
    max-width: 24rem;
    width: 100%;
    margin-top: 3.2rem;
  }
  .log_rhs {
    padding: 3.76rem 4.08rem;
  }
  .skip {
    color: $primary;
    font-size: 2.08rem;
    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
    }
  }
  .preference {
    padding: 3.44rem 0 2rem;
    width: 100%;
    min-height: 100%;

    ul {
      max-width: 115rem;
      width: 100%;
      margin: 0 auto;

      li {
        width: calc(100% / 6 - 2rem);
        margin: 0 1.5rem 3.6456rem;
        border: 2px solid $primary;
        border-radius: 1.2rem;
        color: $primary;
        position: relative;
        align-items: center;
        &::after {
          content: "";
          display: block;
          padding-bottom: 100%;

          @include media-breakpoint-down(md) {
            content: none;
          }
        }

        .box {
          position: absolute;
          flex-direction: column;
          @extend .d-flex, .align-items-center;

          @include media-breakpoint-down(md) {
            position: static;
            flex-direction: row;
          }
        }

        i {
          font-size: 4rem;

          @include media-breakpoint-down(md) {
            font-size: 2.4rem;
            margin-right: 1.4rem;
          }
        }
        .preference-img {
          height: 5rem;
          @include media-breakpoint-down(md) {
            margin-right: 2.4rem;
            width: 4rem;
          }
          img {
            max-height: 5rem;
            @include media-breakpoint-down(md) {
              max-width: 4rem;
              height: 4rem;
            }
          }
        }
        .name {
          font-size: 1.6rem;
          font-weight: 600;
          margin-top: 0.5rem;
          @include media-breakpoint-down(md) {
            font-size: 3rem;
            margin-top: 0;
          }
        }
        &.info {
          color: #6fcbd0;
          border-color: #6fcbd0;

          &.bg-color {
            background-color: rgba(111, 203, 208, 0.12);
          }
        }
        &.warning {
          color: #f1b40d;
          border-color: #f1b40d;

          &.bg-color {
            background-color: rgba(241, 180, 13, 0.12);
          }
        }
        &.teal {
          color: #25797e;
          border-color: #25797e;

          &.bg-color {
            background-color: rgba(37, 121, 126, 0.12);
          }
        }
        &.orange {
          color: #f79747;
          border-color: #f79747;

          &.bg-color {
            background-color: rgba(247, 151, 71, 0.12);
          }
        }
        &.primary {
          color: #eb0e8c;
          border-color: #eb0e8c;

          &.bg-color {
            background-color: rgba(235, 14, 140, 0.12);
          }
        }
        @include media-breakpoint-up(md) {
          width: calc(100% / 3 - 3rem);
          margin: 0 1.5rem 3.6456rem;
        }
        @include media-breakpoint-up(lg) {
          width: calc(100% / 6 - 3rem);
        }
        @include media-breakpoint-up(xxl) {
          width: calc(100% / 6 - 3rem);
        }
        @include media-breakpoint-down(md) {
          width: 100%;
          margin: 0 1rem 1.36rem;
          align-items: flex-start;
          border-width: 1px;
          border-radius: 0.5rem;
          padding: 0.8em 1.8rem;
        }
      }
      @include media-breakpoint-down(md) {
        width: calc(100% + 2rem);
        margin: 0 -1rem;
      }
    }
  }
  .log_rhs {
    @include media-breakpoint-down(md) {
      padding: 2rem;
    }
  }
}
.preference-box {
  border-width: 0.2rem;
  border-style: solid;
  border-radius: 1.2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(md) {
    padding: 0.8em 1.8rem;
    width: 100%;
    align-items: flex-start;
    border-radius: 0.5rem;
  }
  &::after {
    @include media-breakpoint-down(md) {
      content: none;
    }
  }
  .box {
    @include media-breakpoint-down(md) {
      display: flex;
      position: static;
      flex-direction: row;
    }
    .preference-img {
      text-align: center;
      @include media-breakpoint-down(md) {
        height: 4rem;
        margin-right: 2.4rem;
      }
      img {
        max-height: 5rem;
        @include media-breakpoint-down(md) {
          max-height: 4rem;
        }
      }
    }
    .name {
      font-size: 1.6rem;
      font-weight: 600;
      margin-top: 0.5rem;
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }
  }
}
