.gamesContainer{
    display: flex;
    justify-content: space-between;
width: 362px;
height: 80px;
left: 0px;
top: 0px;
background: #FFFFFF;
box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
border-radius: 10px;
}

.gameImage{
    margin-top: 13px;
    margin-left: 12px;

    width: 55px;
height: 55px;
left: 12px;
bottom: 192px;

background: #FFFFFF;
border: 0.183333px solid rgba(0, 0, 0, 0.2);
border-radius: 14.6667px;
padding:8px;
}
.gameDescription{
    margin-right:120px ;
}
.gameTitle{
    width: 140px;
height: 16px;
left: 84px;
top: 22px;
margin-top: 22px;
margin-bottom: 5px;
font-family: 'Mukta',system-ui, -apple-system, BlinkMacSystemFont;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 15px;
}