.trending-container {
  padding: 18px 0 10px 15px;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: -15px !important;
  .trending-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }
  .trending-options {
    display: flex;
    flex-direction: column;
    .first-row {
      display: flex;
      flex-direction: row;
      .hashtag-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background: #f1f2fa;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        .hashtag-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
    }
    .second-row {
      margin-top: 6px;
      display: flex;
      flex-direction: row;
      .hashtag-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background: #f1f2fa;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .hashtag-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
    }
  }
}
.search-outer-container {
  margin-top: 10px;
  padding: 8px 0px 10px 15px;
  background-color: white;
}
.header-cotainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  .left-side {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: 5px;
    }
    .category-text {
      color: #eb0e8c;
      //   font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .read-more-text {
      margin-right: 5px;
      color: #868686;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.author-container {
  min-height: 132px;
  .author-image-container {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    img {
      border-radius: 50%;
    }
  }
  .author-name {
    margin-top: 10px;
    div {
      color: #000;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px; /* 135.714% */
      letter-spacing: 0.14px;
      text-transform: capitalize;
    }
  }
}
.video-card-container {
  margin-right: 15px;
  min-width: 225px;
  max-width: 225px;

  margin-bottom: 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.1);
  img {
    border-radius: 5px;
  }
  .video-text {
    margin-top: 10px;
    margin-bottom: 8px;
    max-height: 59px;
    min-height: 59px;

    padding: 0 10px 9px 10px;
    color: #000;
    font-family: "Mukta", system-ui, -apple-system, BlinkMacSystemFont;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.15px;
    text-transform: capitalize;
    white-space: break-spaces;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.searchbar-container {
  background: #eb0e8c;
  padding-bottom: 32px;
  height: 18.8rem;

  .search-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    span {
      color: #fff;
      font-size: 26px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
  }
  .suggest-option-dropdown {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    width: 400px;
    top: 12rem;
    right: 0;
    background-color: white;
    @include media-breakpoint-down(xl) {
      width: 100%;
      max-height: 60vh;
      top: 8.5rem;
      left: 0;
      right: auto;
    }
    .dropdown-list {
      top: 2.5rem;
      right: 0;
      max-height: 50vh;
      overflow-y: scroll;
      box-shadow: 0 0 1rem $dark-gray-400;
      z-index: 2;
      padding: 10px 20px;
      li {
        cursor: pointer !important;
        border-bottom: solid 1px $gray-light-100;
        padding: 8px 0;
        a {
          // color: $gray;
          cursor: pointer;
          span {
            font-weight: 500;
            color: $gray !important;
            @include media-breakpoint-down(xl) {
              display: inline-block;
            }
          }
        }
        &:hover {
          background-color: $gray-light-200;
        }
      }
    }
  }
}
.search-form {
  width: 100%;
  background: #eb0e8c;

  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    position: absolute;
    right: 5%;
  }
}
.search-input {
  border: none;
  outline: none;
  width: 93%;
  height: 100%;
  border-radius: 12px;
  padding-left: 10px;
}
.search-input::placeholder {
  color: rgba(128, 128, 128, 0.808);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.home-dropdown-list {
  width: 93%;
  margin: 0 auto;
}
.menu-group-search {
  display: flex;
  flex-direction: row;
  border-radius: 15px 15px 0px 0px;
  background-color: white;
  margin-top: -10px;
  justify-content: space-around;
  .active {
    border-bottom: 4px solid #eb0e8c;
    color: #eb0e8c !important;
  }
  .inactive {
    border-bottom: 4px solid white;
  }
  div {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 4px;
    margin-top: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      color: black;
    }
  }
}
.search-top-section {
  position: sticky;
  top: 0;
  z-index: 1;
}
.list-group-item-desktop {
  list-style-type: none;
  position: relative;
  border-left: 0;
  border-right: 0;
  padding: 0;
  width: 100%;
  border: 0;
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 0.7rem;
    border-bottom-left-radius: 0.7rem;

    @include media-breakpoint-down(md) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &:last-child {
    border-top-right-radius: 0.9rem;
    border-bottom-right-radius: 0.9rem;
    @include media-breakpoint-down(md) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &::after {
      display: none;
    }
  }
  div {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    color: $gray;
    padding: 1rem 1.6rem;
    justify-content: center;
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }
    &:hover {
      color: $white;
    }
    & > i {
      margin-right: 1.6rem;
    }
    & > span {
      font-size: 1.8rem;
    }
  }
  .active {
    background-color: $skyblue-light-100;
    color: white;

    &:first-child {
      a {
        border-top-left-radius: 0.7rem;
        border-bottom-left-radius: 0.7rem;
      }
    }
    a {
      color: $white;
    }
    &:last-child {
      a {
        border-top-right-radius: 0.7rem;
        border-bottom-right-radius: 0.7rem;
      }
    }
    &::after {
      display: none;
    }
  }
  &:hover {
    background-color: $skyblue-light-100;
    &::after {
      display: none;
    }
  }
  &::after {
    content: "";
    width: 0.1rem;
    height: calc(100% - 1rem);
    position: absolute;
    top: 0.5rem;
    right: 0;
    background-color: #dee2e6;
  }
}
.outer-container-audio {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  .img-cont {
    position: relative;
    img {
      height: 100px;
      width: 100px;
      min-width: 100px;
      min-height: 100px;
      border-radius: 6px;
    }
    .news-type-icon {
      position: absolute;
      padding-left: 0.6rem;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(180deg, transparent, #000);
      border-bottom-left-radius: 8px;
      i {
        color: white;
        padding-right: 0;
      }
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    padding-top: 2px;
    .title {
      line-height: 2.4rem;
      max-height: 8rem;
      min-height: 4.2rem;
      font-size: 2rem;
      white-space: break-spaces;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: #000;
    }
    .date-audio {
    }
  }
}
@media screen and (min-width: 799px) {
  .outer-container-audio {
    margin-bottom: 5px;
  }
  .title {
    font-size: 1.8rem !important;
  }
}
