.storyCard {
  // padding: 4rem;
  background-color: $white;
  border-radius: 1.5rem;
  // @extend .flex-fill;

  // @include media-breakpoint-down(sm) {
  //   padding: 2rem;
  // }
}
.storie-sec {
  & > div {
    @include media-breakpoint-down(md) {
      padding: 0 !important;
    }
  }
  .storie-cards {
    .col {
      @include media-breakpoint-down(md) {
        &:last-child {
          margin-bottom: 0 !important;
        }
        &:nth-last-child(2) {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.stories-wrap {
  .card-right {
    &:empty {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

.story-wrap {
  // max-width: 11rem;
  @extend .position-relative;

  .story-thumb {
    cursor: pointer;
    border-radius: 1.5rem;
    @include img-box(157.37%);

    .overlay {
      @extend .position-absolute;
      cursor: pointer;
    }
  }
}
.storyLabel {
  position: absolute;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

  width: 100%;
  bottom: 0;
  padding: 20px 15px 12px;
  @include media-breakpoint-down(md) {
    padding: 20px 12px 10px;
  }
  label,
  h3 {
    display: flex;
    align-items: end;
    font-size: 1.8rem;
    color: $white;
    margin: 0 0 0.5rem;
    min-height: 5.1rem;
    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
      min-height: 4.5rem;
    }
    @include media-breakpoint-down(md) {
      min-height: 4.6rem;
    }
  }
  .tags {
    div {
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
      color: $green;
      font-size: 1.44rem;
    }
  }
  .tagName {
    text-transform: uppercase;
    color: $pink;
    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
  }
}
.facts {
  position: absolute;
  width: 34px;
  top: 10px;
  right: 10px;
  z-index: 9;
  padding: 0.6rem 0.2rem;
  border-radius: 0.8rem;
  background: radial-gradient(rgba(0, 0, 0, 0.09) 20%, transparent);
  backdrop-filter: blur(2px);
  @include media-breakpoint-down(md) {
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
  }
  i {
    font-size: 1.8rem;
    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }
  div {
    display: flex;
    width: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;

    img {
      position: initial !important;
      left: initial !important;
      top: initial !important;
      -webkit-transform: initial !important;
      transform: initial !important;
      object-fit: initial !important;
      width: 22px !important;
      min-height: initial !important;
      // margin-right: 0.7rem;
      :last-child {
        margin-left: 10px;
      }
    }
  }
  .story-likes {
    min-height: 4.678rem;
    @include media-breakpoint-down(md) {
      min-height: 4.4784rem;
    }
  }
  .story-views {
    min-height: 4.039rem;
    @include media-breakpoint-down(md) {
      min-height: 3.8398rem;
    }
  }
}

.facts-small {
  position: absolute;
  width: 34px;
  bottom: 0;
  right: 8px;
  z-index: 9;
  padding: 0.6rem 0.2rem;
  border-radius: 0.8rem;
  background: radial-gradient(rgba(0, 0, 0, 0.09) 20%, transparent);
  backdrop-filter: blur(2px);
  // @include media-breakpoint-down(md) {
  //   font-size: 1.6rem;
  //   top: 5px;
  //   right: 5px;
  // }
  // i {
  //   font-size: 1.8rem;
  //   @include media-breakpoint-down(md) {
  //     font-size: 1.6rem;
  //   }
  // }
  div {
    display: flex;
    width: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;

    img {
      position: initial !important;
      left: initial !important;
      top: initial !important;
      -webkit-transform: initial !important;
      transform: initial !important;
      object-fit: initial !important;
      width: 22px !important;
      min-height: initial !important;
      // margin-right: 0.7rem;
      :last-child {
        margin-left: 10px;
      }
    }
  }
  .story-likes {
    min-height: 4.678rem;
    @include media-breakpoint-down(md) {
      min-height: 4.4784rem;
    }
  }
  .story-views {
    min-height: 4.039rem;
    @include media-breakpoint-down(md) {
      min-height: 3.8398rem;
    }
  }
}

.storyLabelOnHomepage-desktop {
  position: absolute;
  display: block;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

  width: 100%;
  bottom: 0;
  padding: 8px 8px 8px;
  font-weight: 500;
  @include media-breakpoint-down(md) {
    padding: 8px 8px 8px;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  @media (max-width: 1800px) {
    display: block;
  }
  @media (max-width: 1440px) {
    display: block;
  }
  @media (max-width: 1199px) {
    display: none;
  }
  @media (max-width: 991px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
  label,
  h3 {
    display: flex;
    align-items: end;
    //font-size: 1.4rem;
    color: $white;
    font-size: 16px;
    margin: 0 0 0.5rem;
    min-height: 5.1rem;
    @include media-breakpoint-down(xlg) {
      //font-size: 1.6rem;
      min-height: 4.5rem;
    }
    @include media-breakpoint-down(lg) {
      //font-size: 1.6rem;
      min-height: 4.5rem;
    }
    @include media-breakpoint-down(md) {
      min-height: 4.2rem;
      font-size: 1.6rem;
    }
    @include media-breakpoint-down(sm) {
      min-height: 4.2rem;
      //font-size: 1.6rem;
    }
  }
  .tags {
    div {
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
      color: $green;
      font-size: 1.44rem;
    }
  }
  .tagName {
    text-transform: uppercase;
    color: $pink;
    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
  }
}

.storyLabelOnHomepage-tablet {
  position: absolute;
  display: none;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

  width: 100%;
  bottom: 0;
  padding: 8px 8px 8px;
  font-weight: 500;
  @include media-breakpoint-down(md) {
    padding: 8px 8px 8px;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  @media (max-width: 1800px) {
    display: none;
  }
  @media (max-width: 1440px) {
    display: none;
  }
  @media (max-width: 1199px) {
    display: block;
  }
  @media (max-width: 991px) {
    display: block;
  }
  @media (max-width: 767px) {
    display: none;
  }
  h3 {
    display: flex;
    align-items: end;
    //font-size: 1.4rem;
    color: $white;
    font-size: 16px;
    margin: 0 0 0.5rem;
    min-height: 5.1rem;
    @include media-breakpoint-down(xlg) {
      //font-size: 1.6rem;
      min-height: 4.5rem;
    }
    @include media-breakpoint-down(lg) {
      //font-size: 1.6rem;
      min-height: 4.5rem;
    }
    @include media-breakpoint-down(md) {
      min-height: 4.2rem;
      font-size: 1.6rem;
    }
    @include media-breakpoint-down(sm) {
      min-height: 4.2rem;
      //font-size: 1.6rem;
    }
  }
  .tags {
    div {
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
      color: $green;
      font-size: 1.44rem;
    }
  }
  .tagName {
    text-transform: uppercase;
    color: $pink;
    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
  }
}

.storyLabelOnHomepage-mobile {
  position: absolute;
  display: none;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

  width: 100%;
  bottom: 0;
  padding: 8px 8px 8px;
  font-weight: 500;
  @include media-breakpoint-down(md) {
    padding: 8px 8px 8px;
    display: none;
  }
  @include media-breakpoint-down(sm) {
    display: block;
  }
  @media (max-width: 1800px) {
    display: none;
  }
  @media (max-width: 1440px) {
    display: none;
  }
  @media (max-width: 1199px) {
    display: none;
  }
  @media (max-width: 991px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: block;
  }
  label,
  h3 {
    display: flex;
    align-items: end;
    //font-size: 1.4rem;
    color: $white;
    font-size: 16px;
    margin: 0 0 0.5rem;
    min-height: 5.1rem;
    @include media-breakpoint-down(xlg) {
      //font-size: 1.6rem;
      min-height: 4.5rem;
    }
    @include media-breakpoint-down(lg) {
      //font-size: 1.6rem;
      min-height: 4.5rem;
    }
    @include media-breakpoint-down(md) {
      min-height: 4.2rem;
      //font-size: 1.6rem;
    }
    @include media-breakpoint-down(sm) {
      min-height: 4.2rem;
      //font-size: 1.6rem;
    }
  }
  .tags {
    div {
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
      color: $green;
      font-size: 1.44rem;
    }
  }
  .tagName {
    text-transform: uppercase;
    color: $pink;
    @include media-breakpoint-down(lg) {
      font-size: 1.6rem;
    }
  }
}
.short-slider-image-customstyle {
  object-fit: initial !important;
}
