.regi_modal {
  max-width: 35rem;
  border-radius: 1rem;
  margin: 0.5rem auto;
  .modal-content {
    border: none;
    .RegiModalBody {
      text-align: center;
      max-width: 25rem;
      margin: 0 auto;
      h3 {
        margin: 1rem auto 1rem;
        color: $primary;
      }
      p {
        font-size: 1.8rem;
        color: $black;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      padding-bottom: 3rem;
      .btn {
        color: $white;
        font-weight: 500;
        border-radius: 0.6rem;
        width: 20rem;
      }
    }
  }
}

// .stories-slider {
//   max-width: 49.28rem;
//   margin: 0 auto;
//   position: relative;
//   min-height: 100vh;
//   @extend .d-flex, .flex-column, .justify-content-center;

//   .slider-arrow {
//     position: absolute;
//     z-index: 1;
//     background-color: $white;
//     width: 4.5rem;
//     height: 4.5rem;
//     border-radius: 50%;
//     border: 0;
//     color: #d9368b;
//     @extend .d-flex, .justify-content-center, .align-items-center;

//     &.slider-prev {
//       left: -5.5rem;
//     }
//     &.slider-next {
//       right: -5.5rem;
//     }
//   }
//   .slick-list {
//     z-index: 1;
//   }
//   @include media-breakpoint-down(md) {
//     max-width: 767px;
//   }
// }
.stories-modal {
  .modal-content {
    background-color: #4d4d51;

    .modal-body {
      padding: 0;
      overflow: hidden;
    }
    .stories-action {
      position: absolute;
      top: 2rem;
      width: 100%;

      border: 0;
      display: flex;
      @extend .justify-content-between, .align-items-start;

      .back-btn,
      .btn-link {
        min-width: 5rem;
        font-size: 2rem;
        color: $white;
        z-index: 1;
      }
      .logo {
        margin-right: 2.5rem;
        z-index: 1;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      @include media-breakpoint-down(md) {
        // display: none;
      }
    }
    .stories-card {
      border-radius: 1.5rem;
      overflow: hidden;
      @extend .position-relative;
      margin-top: 0.5rem;

      .stories-thumb {
        max-height: calc(100vh - 2rem);
        @include img-box-react(165.9%);

        > div {
          position: absolute !important;
          width: 100% !important;
          height: 100% !important;
        }
        @include media-breakpoint-down(md) {
          @include img-box-react(177%);
          max-height: 100vh;
        }
      }
      @include media-breakpoint-down(md) {
        border-radius: 0;
        margin-top: 0;
      }
    }
  }
}
// .stories-overlay {
//   @extend .position-absolute, .d-flex, .align-items-end;
//   background: linear-gradient(
//     180deg,
//     rgba(0, 0, 0, 0) 0%,
//     rgba(0, 0, 0, 0.76) 100%
//   );
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 50%;
//   padding: 0 3.76rem 3rem;
//   color: $white;
//   z-index: 1000;

//   .text {
//     font-size: 2rem;
//   }
// }
.story-share {
  @extend .position-absolute;
  right: 3.76rem;
  top: 3.76rem;
  z-index: 1000;

  a {
    color: $white;
    font-size: 2.5rem;
  }
}

.login-next {
  padding: 1rem 0;
  h4 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary;
    text-align: center;
  }
  .modal-footer {
    justify-content: center;
    padding: 2rem 0 1rem;
    .btn {
      margin: 0 0.5rem;
    }
  }
}
.feedback-submited {
  .modal-body {
    padding-bottom: 0;
  }
  .modal-footer {
    padding-top: 0;
  }
}
.frist-login {
  @include media-breakpoint-down(md) {
    max-width: 35rem;
    margin: 0 auto;
  }
}

.modal-rating{
  .modal-header{
    justify-content: center;
  }
  .btn-close{
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
}