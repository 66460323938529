.homepage-banner {
  width: 100%;
  img {
    width: 100%;
    border-radius: 1.5rem;
    @media screen and (max-width: 500px) {
      border-radius: 1rem;
    }
  }
  margin-left: 0 !important;
}

.homepage-banner .carousel .carousel-indicators [data-bs-target] {
  background-color: #cecdcd;
}

.homepage-banner .carousel .carousel-indicators .active {
  background-color: #106369;
}

.bannerIframe,
.bannerIframe0 {
  iframe {
    min-height: 100px;
    border-radius: 1.5rem;
    width: 100%;
    height: 100px;
    @media screen and (max-width: 500px) {
      border-radius: 1rem;
    }
    //       @media screen and (max-width: 820px) {
    //           height: 200px;
    //       }
    //       @media screen and (max-width: 620px) {
    //           height: 200px;
    //       }
    //       @media screen and (max-width: 500px) {
    //           height: 200px !important;
    //       }
  }
}

.bannerIframeElection {
  iframe {
    min-height: 100px;
    border-radius: 1.5rem;
    width: 100%;
    height: 100px;
    @media screen and (max-width: 600px) {
      border-radius: 0.8rem;
      min-height: 75px;
      height: 75px;
    }
    //       @media screen and (max-width: 820px) {
    //           height: 200px;
    //       }
    //       @media screen and (max-width: 620px) {
    //           height: 200px;
    //       }
    //       @media screen and (max-width: 500px) {
    //           height: 200px !important;
    //       }
  }
}
