.cricket-outer-container {
  width: 96vw;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  cursor: pointer;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  // margin-right: 10px;
  // margin-left: 10px;
  .top-bar {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // background-color: rgb(241, 242, 246);

    padding-left: 10px;
    padding-top: 10px;
    .heading-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;
      .heading-text {
        height: 34px;
        font-size: 12px;
        color: rgb(17, 17, 17);
        max-height: 40px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        max-width: 80%;
      }
      .isLive-bar {
        // position: relative;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 12px 5px 12px;
        // width: 44.5px;
        height: 24px;
        background: rgb(210, 5, 20);
        border-radius: 5px 0px 0px 5px;
        margin-top: 5px;
      }
    }
    .first-team,
    .second-team {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4px;
      padding-right: 8px;
      .left-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img {
          width: 28px;
          height: 28px;
          justify-content: space-between;
        }
        .teama-short-name {
          font-size: 14px;
          text-align: left;
          line-height: 20px;
          margin-left: 8px;
          font-weight: 700;
          color: rgb(17, 17, 17);
        }
      }
      .right-side {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        margin-right: 4px;
        color: rgb(17, 17, 17);
      }
    }
    .vs {
      border-radius: 50%;
      background: rgb(255, 255, 255);
      padding: 3px;
      max-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 12px;
        color: rgb(17, 17, 17);
      }
    }
  }
  .lower-bar {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 5px 0 5px 10px;
    .match-center {
      background-color: rgb(0, 163, 0);
      padding: 5px;
      border-radius: 10px;
      font-weight: 400;
      color: rgb(255, 255, 255);
    }
    .match-status {
      color: rgb(166, 169, 184);
      margin-left: 10px;
      font-size: 12px;
      font-weight: 500;
      margin-top: 0 !important;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      width: 65%;
      -webkit-box-orient: vertical;
      display: flex;
      align-items: center;
    }
  }
}
.custom-dot-sports {
  padding: 0 !important;
}
.cricket-dot-list {
  padding: 0 !important;
  bottom: -2% !important;

  li {
    button {
      width: 8px !important;
      height: 8px !important;
    }
  }
}
// .cricket-carousel-wrapper {
//   background-color: white;
// }

@media (min-width: 700px) {
  .cricket-carousel-wrapper {
    max-width: 36rem;
  }
}
@media (max-width: 499px) {
  .cricket-carousel-wrapper {
    max-width: 100vw;
    overflow: hidden;
  }
}
.teams {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cricket-iframe {
  height: 100vh !important;
  // clip-path: inset(4% 0 0 0);
  // margin-top: -8%;
}
.noMarginBottom {
  margin-bottom: 0 !important;
}
.teams-height {
  height: 76px !important;
}
.width-90 {
  max-width: 90% !important;
}
.cricket-scorecard {
  margin-top: 5px;
  span {
    color: #000;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
  }
}
.w-50 {
  width: 50%;
}
.jc-end {
  justify-content: end !important;
}
