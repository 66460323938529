// @import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');

// @font-face {
//   font-family: "Hind";
//   src: url("../fonts/Hind-Regular.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Hind";
//   src: url("../fonts/Hind-Bold.woff2") format("woff2");
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Hind";
//   src: url("../fonts/Hind-Light.woff2") format("woff2");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Hind";
//   src: url("../fonts/Hind-Medium.woff2") format("woff2");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Hind";
//   src: url("../fonts/Hind-SemiBold.woff2") format("woff2");
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

//start here

// @font-face {
//   font-family: "Mukta";
//   src: url("../../../public/fonts/Mukta-Bold.woff2") format("woff2"),
//     url("../../../public/fonts/Mukta-Bold.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Mukta";
//   src: url("../../../public/fonts/Mukta-SemiBold.woff2") format("woff2"),
//     url("../../../public/fonts/Mukta-SemiBold.woff") format("woff");
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Mukta";
//   src: url("../../../public/fonts/Mukta-ExtraBold.woff2") format("woff2"),
//     url("../../../public/fonts/Mukta-ExtraBold.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Mukta";
//   src: url("../../../public/fonts/Mukta-Regular.woff2") format("woff2"),
//     url("../../../public/fonts/Mukta-Regular.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Mukta";
//   src: url("../../../public/fonts/Mukta-ExtraLight.woff2") format("woff2"),
//     url("../../../public/fonts/Mukta-ExtraLight.woff") format("woff");
//   font-weight: 200;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Mukta";
//   src: url("../../../public/fonts/Mukta-Medium.woff2") format("woff2"),
//     url("../../../public/fonts/Mukta-Medium.woff") format("woff");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Mukta";
//   src: url("../../../public/fonts/Mukta-Light.woff2") format("woff2"),
//     url("../../../public/fonts/Mukta-Light.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

html,
body {
  min-height: 100vh;
}
body {
  overflow-x: hidden;
  background-color: $gray-light-200;
  @include media-breakpoint-down(md) {
    background-color: #f4f4f4;
  }
}
p {
  line-height: 1.5;
}
.fs-15 {
  font-size: 1.5rem !important;
}
.fs-16 {
  font-size: 1.6rem !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.h3-title {
  font-size: 2.8rem;
}

@include media-breakpoint-down(md) {
  :root {
    font-size: 50%;
  }
}