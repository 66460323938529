.polls-wrapper {
  @extend .d-flex;
  @extend .flex-wrap;
  padding: 1.7rem 1.44rem 1.7rem 1.7rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
  margin: 0 0 1.2rem;
  @extend .align-items-start;

  .images {
    flex: 0 0 22.36rem;
    margin-right: 1.6rem;
    @include img-box(52.26%);
    border-radius: 0.5rem;

    @include media-breakpoint-down(md) {
      flex: 0 0 14.2rem;
    }
  }
  @include media-breakpoint-down(md) {
    margin: 0;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;

    &:last-child {
      border-bottom: 0;
    }
  }
}
.polls-content {
  @extend .col;

  .title {
    font-size: 1.44rem;
    color: $primary;
    font-weight: 600;
  }
  .content {
    font-size: 1.76rem;
    font-weight: 500;
    margin: 0 0 0.6rem;
  }
  ul {
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .justify-content-between;
    @extend .list-unstyled;
    margin: 0;

    li {
      width: calc(100% - 0.64rem);
      margin-bottom: 1rem;
      @include media-breakpoint-down(lg) {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}
