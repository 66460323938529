.site-footer {
  background-color: $green;
  padding-top: 4rem;
  margin-bottom: 50px;
  min-height: 50rem;
  display: flex;
  @extend .mt-auto;

  // .footer-download-title {
  //   font-size: 1.5rem;
  //   color: $yellow;
  //   letter-spacing: 0.03em;
  //   @extend .text-uppercase, .mb-3, .fw-semibold;
  //   @include media-breakpoint-down(sm) {
  //     text-align: center;
  //   }
  // }

  // .link-menu-list {
  //   @extend .list-unstyled, .mb-4;
  //   @include media-breakpoint-down(sm) {
  //     max-width: fit-content;
  //     margin: 0 auto;
  //     text-align: center;
  //   }

  //   > li {
  //     a {
  //       color: $white;
  //       transition: all 0.25s ease-in-out;
  //       @extend .d-block, .text-decoration-none, .fw-semibold, .fs-15;

  //       &:hover {
  //         color: $primary;
  //       }
  //     }

  //     &:not(:last-child) {
  //       a {
  //         padding-bottom: 1.3rem;
  //         margin-bottom: 1.3rem;
  //       }
  //     }
  //   }
  // }

  // .link-submenu-list {
  //   @extend .list-unstyled, .mb-4, .d-flex, .flex-wrap;
  //   @include media-breakpoint-down(sm) {
  //     max-width: fit-content;
  //     margin: 0 auto;
  //     text-align: center;
  //     display: inherit !important;
  //   }
  //   > li {
  //     flex: 0 0 50%;
  //     @include media-breakpoint-down(md) {
  //       flex: 0 0 100%;
  //     }
  //     a {
  //       color: $white;
  //       transition: all 0.25s ease-in-out;
  //       word-break: break-all;
  //       @extend .d-block, .text-decoration-none, .fs-15;

  //       &:hover {
  //         color: $primary;
  //       }
  //     }

  //     &:not(:last-child) {
  //       a {
  //         padding-bottom: 0.8rem;
  //         margin-bottom: 0.8rem;
  //       }
  //     }
  //     &:nth-child(1n + 6) {
  //       @include media-breakpoint-down(md) {
  //         display: none;
  //       }
  //     }
  //   }
  // }
  // .download-apps {
  //   @include media-breakpoint-down(sm) {
  //     justify-content: center;
  //   }
  // }
  .copy-right-text {
    color: $white;
    font-size: 1.5rem;
    border-top: dashed 1px $white;
    padding: 1.6rem 0;
    letter-spacing: 0.02em;
    @extend .my-0, .text-center, .fw-medium;
  }
}
.social-link-list {
  @extend .d-flex, .list-unstyled;
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  li {
    a {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: $white;
      color: $green;
      font-size: 1.8rem;
      transition: all 0.25s ease-in-out;
      @extend .d-flex,
        .align-items-center,
        .justify-content-center,
        .text-decoration-none;

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
    + li {
      margin-left: 1.4rem;
    }
  }
}

.linksApp {
  @media (max-width: 576px) {
    flex-direction: column;
    text-align: center;
  }
}

.linkGoogle {
  @media (max-width: 576px) {
    margin-bottom: 1.6rem !important;
  }
}

.linkApple {
  @media (max-width: 576px) {
    margin-left: 0rem !important;
  }
}
.qr-image-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 4px;
  .qr-image {
    width: 150px;
    height: 150px;
    @include media-breakpoint-down(md) {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 766px) {
    margin-left: 0 !important;
  }
  @media (max-width: 575px) {
    justify-content: center;
  }
}
.footer-main {
  background-color: $green;
  padding-top: 4rem;
  margin-bottom: 50px;
  min-height: 50rem;
  content-visibility: auto;
}
.footer-main-row {
  display: flex;
  gap: 2rem;
  justify-content: center;
  // width: 50%;
  // margin: 0 auto;
}
.active-link-menu-list {
  color: $primary !important;
}
.footer-All {
  display: flex;
  gap: 3rem;
}
.footer-quick-links {
  display: flex;
  justify-content: space-evenly;
}
.ul-items {
  list-style: none;
  padding-left: 0;
  li {
    // width: auto;
    font-size: 1.5rem;
  }
  li a {
    color: #ccccccd6;
  }
}

.ul-items li a:hover {
  color: white;
  cursor: pointer;
}
.secondColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.copy-right {
  text-align: center;
  font-size: 1.5rem;
}
.firstColumn {
  margin-top: 3rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 16px;
}

@media screen and (max-width: 928px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 720px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .bottom-copy {
    display: block !important;
  }
  .bottom-items {
    display: block !important;
  }
  .firstColumn {
    display: none !important;
  }
}

@media screen and (max-width: 550px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .bottom-copy {
    display: block !important;
  }
  .bottom-items {
    display: block !important;
  }
  .firstColumn {
    display: none !important;
  }
}

@media screen and (max-width: 450px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .bottom-copy {
    display: block !important;
  }
  .bottom-items {
    display: block !important;
  }
  .firstColumn {
    display: none !important;
  }
  .bottom-items-logo {
    width: 80px;
    height: auto;
  }
  .bottom-items-div {
    gap: 2rem !important;
  }
}
.bottom-items-logo-desktop {
}

.bottom-app {
  margin: 0 auto;
  width: 50%;
}
.bottom-copy {
  display: none;
}
.bottom-items {
  display: none;
}
.new-footer li {
  @media screen and (min-width: 499px) {
    margin-bottom: 8px;
  }
}
