.news-title {
  @extend .d-flex, .justify-content-between;
  h1 {
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
  h3 {
    max-width: 66.8rem;
    width: 100%;
  }
  .options {
    display: none;

    .btns {
      margin-right: 3rem;
      color: $primary;
      font-size: 2.4rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      @include media-breakpoint-down(md) {
        margin-right: 1rem;
      }
    }
    @include media-breakpoint-down(md) {
      margin: 0 1rem;
      padding-top: 0.5rem;
      display: flex;
      align-items: flex-start;
    }
  }
  a {
    color: $black;

    i {
      margin-right: -1rem;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 0 0 0;
  }
  .option-dropdown {
    margin-right: -1.1rem;
  }
  & + .content {
    font-size: 2rem;
    line-height: 3rem;
    //text-align: justify;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
}

.news-title-small {
  @extend .d-flex, .justify-content-between;
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    //text-align: justify;
  }

  h3 {
    max-width: 66.8rem;
    width: 100%;
  }
  .options {
    display: none;

    .btns {
      margin-right: 3rem;
      color: $primary;
      font-size: 2.4rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      @include media-breakpoint-down(md) {
        margin-right: 1rem;
      }
    }
    @include media-breakpoint-down(md) {
      margin: 0 1rem;
      padding-top: 0.5rem;
      display: flex;
      align-items: flex-start;
    }
  }
  a {
    color: $black;

    i {
      margin-right: -1rem;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 0 0 0;
  }
  .option-dropdown {
    margin-right: -1.1rem;
  }
  & + .content {
    font-size: 18px;
    line-height: 3rem;
    //text-align: justify;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }
}

.news-title-large {
  @extend .d-flex, .justify-content-between;
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  h3 {
    max-width: 66.8rem;
    width: 100%;
  }
  .options {
    display: none;

    .btns {
      margin-right: 3rem;
      color: $primary;
      font-size: 2.4rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      @include media-breakpoint-down(md) {
        margin-right: 1rem;
      }
    }
    @include media-breakpoint-down(md) {
      margin: 0 1rem;
      padding-top: 0.5rem;
      display: flex;
      align-items: flex-start;
    }
  }
  a {
    color: $black;

    i {
      margin-right: -1rem;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 0 0 0;
  }
  .option-dropdown {
    margin-right: -1.1rem;
  }
  & + .content {
    font-size: 22px;
    line-height: 3rem;
    //text-align: justify;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 3.5rem;
      font-weight: 400;
    }
  }
}

.news-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 1rem;
  border-top: 1px solid #80808063;
    border-bottom: 1px solid #80808063;
    padding: 10px 0px;
  @include media-breakpoint-down(md) {
    // display: block;
  }
  .user {
    @extend .d-flex, .flex-fill, .align-items-center;
    margin-right: 1.5rem;

    .image {
      flex: 0 0 6rem;
      margin-right: 1.5rem;

      .image-thumb {
        border-radius: 50%;
        @include img-box-react(100%);
      }
    }
    .user-detail {
      @extend .col;
      flex: none;

      .name {
        font-size: 16px;
        font-weight: 400;
        margin: 0 0 0.5rem;
        white-space: nowrap;
        line-height: 2.5rem;
        max-height: 5rem;
      }
    }
    .input-by {
      line-height: 2.2rem;
      margin-left: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
      word-break: break-word;
      font-size: 16px;
      font-weight: 400;
      margin: 0 0 0.5rem;
      white-space: nowrap;
      color: gray;
    }
  }
  .date {
    font-size: 16px;
    color: $dark-gray-300;
    font-weight: 300;
    white-space: normal;
    span {
      font-size: 16px;
    }
    @media screen and (max-width:650px) {
      margin-top: 10px;
      width: 100% !important;
      // margin-left: 16px;
      line-height: normal;
    }

    // .span {
    //   &:not(:last-child) {
    //     &::after {
    //       content: "";
    //       position: relative;
    //       background-color: $dark-gray-300;
    //       width: 0.5rem;
    //       height: 0.5rem;
    //       border-radius: 50%;
    //       display: inline-block;
    //       margin-left: 1rem;
    //       margin-right: 0.5rem;
    //     }
    //   }
    // }
    .date-mobile {
      display: none;
    }
    @include media-breakpoint-down(sm) {
      .date-desktop {
        // display: none;
        font-size: 16px;
      }
      .name {
        font-size: 16px;
      }
      .date-mobile {
        display: block;
        white-space: nowrap;
        font-size: 16px;
        span {
          font-size: 16px;
        }
      }
    }
  }
  .options {
    @extend .d-flex;

    .btns {
      margin-right: 3rem;
      color: $primary;
      font-size: 2rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      @include media-breakpoint-down(md) {
        margin-right: 3rem;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 1.6rem;
      }
    }
    @include media-breakpoint-down(md) {
      padding-top: 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 1.4rem 0 0;
    // display: none;
  }
}

.news-options-small {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 1rem;
  @include media-breakpoint-down(md) {
    // display: block;
  }
  .user {
    @extend .d-flex, .flex-fill, .align-items-center;
    margin-right: 1.5rem;

    .image {
      flex: 0 0 6rem;
      margin-right: 2rem;

      .image-thumb {
        border-radius: 50%;
        @include img-box-react(100%);
      }
    }
    .user-detail {
      @extend .col;
      flex: none;

      .name {
        font-size: 16px;
        font-weight: 400;
        margin: 0 0 0.5rem;
        white-space: nowrap;
        line-height: 2.2rem;
        max-height: 4.4rem;
      }
    }
    .input-by {
      line-height: 2.2rem;
      margin-left: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
      word-break: break-word;
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 0.5rem;
      white-space: nowrap;
      color: gray;
    }
  }
  .date {
    font-size: 14px;
    color: $dark-gray-300;
    font-weight: 300;
    white-space: normal;
    span {
      font-size: 14px;
    }

    @media screen and (max-width:650px) {
      margin-top: 10px;
      width: 100% !important;
      // margin-left: 16px;
      line-height: normal;
    }

    // .span {
    //   &:not(:last-child) {
    //     &::after {
    //       content: "";
    //       position: relative;
    //       background-color: $dark-gray-300;
    //       width: 0.5rem;
    //       height: 0.5rem;
    //       border-radius: 50%;
    //       display: inline-block;
    //       margin-left: 1rem;
    //       margin-right: 0.5rem;
    //     }
    //   }
    // }
    .date-mobile {
      display: none;
    }
    @include media-breakpoint-down(sm) {
      .date-desktop {
        // display: none;
        font-size: 16px;
      }
      .name {
        font-size: 16px;
      }
      .date-mobile {
        display: block;

        font-size: 14px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .options {
    @extend .d-flex;

    .btns {
      margin-right: 3rem;
      color: $primary;
      font-size: 1.8rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      @include media-breakpoint-down(md) {
        margin-right: 3rem;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 1.6rem;
      }
    }
    @include media-breakpoint-down(md) {
      padding-top: 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 1.4rem 0 0;
    // display: none;
  }
}

.news-options-large {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 1rem;
  @include media-breakpoint-down(md) {
    // display: block;
  }
  .user {
    @extend .d-flex, .flex-fill, .align-items-center;
    margin-right: 1.5rem;

    .image {
      flex: 0 0 6rem;
      margin-right: 2rem;

      .image-thumb {
        border-radius: 50%;
        @include img-box-react(100%);
      }
    }
    .user-detail {
      @extend .col;
      flex: none;
      .name {
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 0.5rem;
        white-space: nowrap;
        line-height: 2.8rem;
        max-height: 5.6rem;
      }
    }
    .input-by {
      line-height: 2.2rem;
      margin-left: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
      word-break: break-word;
      font-size: 18px;
      font-weight: 400;
      margin: 0 0 0.5rem;
      white-space: nowrap;
      color: gray;
    }
  }
  .date {
    font-size: 18px;
    color: $dark-gray-300;
    font-weight: 300;
    white-space: normal;
    span {
      font-size: 18px;
    }
    @media screen and (max-width:650px) {
      margin-top: 10px;
      width: 100% !important;
      // margin-left: 16px;
      line-height: normal;
    }
    // .span {
    //   &:not(:last-child) {
    //     &::after {
    //       content: "";
    //       position: relative;
    //       background-color: $dark-gray-300;
    //       width: 0.5rem;
    //       height: 0.5rem;
    //       border-radius: 50%;
    //       display: inline-block;
    //       margin-left: 1rem;
    //       margin-right: 0.5rem;
    //     }
    //   }
    // }
    .date-mobile {
      display: none;
    }
    @include media-breakpoint-down(sm) {
      .date-desktop {
        // display: none;
        font-size: 16px;
      }
      .name {
        font-size: 20px;
      }
      .date-mobile {
        display: block;

        font-size: 16px;
        span {
          font-size: 18px;
        }
      }
    }
  }
  .options {
    @extend .d-flex;

    .btns {
      margin-right: 3rem;
      color: $primary;
      font-size: 2.2rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      @include media-breakpoint-down(md) {
        margin-right: 3rem;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 1.6rem;
      }
    }
    @include media-breakpoint-down(md) {
      padding-top: 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 1.4rem 0 0;
    // display: none;
  }
}

.news-slider {
  border-radius: 1.2rem;
  overflow: hidden;
  margin: 0 0 2.5rem;

  .image {
    .image-thumb {
      @include img-box-react(56.25%);
    }
  }
  .slider-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(28, 28, 28, 0.3);
    min-height: 7rem;
    backdrop-filter: blur(2px);
    padding: 0 2.8rem;
    @extend .d-flex, .align-items-center;

    .carousel-caption {
      position: static;
      color: #ffffff;
      font-size: 2rem;
      font-weight: 300;
      text-align: left;
      @extend .d-flex, .align-items-center, .flex-fill;

      .text {
        @extend .flex-fill;
      }
    }
  }
}
.img-post {
  margin-bottom: 0.5rem;
  text-align: center;
  & + .post-img-caption {
    font-size: 1.6rem;
    color: $dark-gray-600;
    margin-bottom: 1.5rem;
    text-align: center;
    font-style: italic;
  }
}
.news-content {
  font-size: 2.2rem;
  font-weight: 300;
  margin: 0 0 2.5rem;
}

.more-news {
  background-color: $primary;
  background-color: #d9368b;
  padding: 2.64rem 3.6rem;
  margin-left: -3.6rem;
  width: calc(100% + 7.2rem);
  // margin-bottom: 4rem;
  .headline-box-sm {
    padding: 1.28rem 1.28rem 1rem;
    border-radius: 1.2rem;
    margin-bottom: 1.074rem;
  }

  @include media-breakpoint-down(md) {
    background-color: white;
    padding: 0;
    margin-left: 0;
    width: 100%;
    margin-bottom: 0;
    .title {
      color: #d9368b !important;
      margin-bottom: 0 !important;
    }
    .headline-box-sm {
      padding: 0;
      margin-bottom: 0rem;
    }
  }
}

.news-comments {
  .comment-header {
    @extend .d-flex, .justify-content-between, .align-items-center;
    // margin: 0 0 2.96rem;

    .comment-title {
      @extend .d-flex, .align-items-center;
      justify-content: space-around;
      padding: 1.021rem 1.56rem;
      background-color: $primary;
      color: $white;
      border-radius: 1.2rem;
      font-size: 2.4rem;
      max-width: 23.04rem;
      i {
        font-size: 2rem;
        margin-top: 0.8rem;
      }
      & > div {
        // margin-left: 1.5rem;
      }
    }
  }
  .comment-body {
    .comments {
      @extend .d-flex, .align-items-start;
      padding: 0 0 1.68rem;
      margin: 0 0 1.68rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .image {
        flex: 0 0 6.4rem;
        margin-right: 1rem;

        .image-thumb {
          border-radius: 50%;
          @include img-box-react(100%);
        }
      }
      .comments-detail {
        @extend .col;
        .reply-text {
          margin-top: 5px;
          i {
            margin-right: 5px;
          }
        }
        .user {
          font-size: 1.76rem;
          font-weight: 500;
          margin: 0 0 0.3rem;
          text-transform: capitalize;
          span {
            color: $dark-gray-300;
            @extend .fw-light;

            &::before {
              content: "";
              position: relative;
              background-color: $dark-gray-300;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              display: inline-block;
              margin-left: 1.13rem;
              margin-right: 1.4rem;
            }
          }
        }
        .add-comment {
          margin-top: 0.5rem;
        }
        .comments {
          margin-top: 1.5rem;
        }
      }
    }
    .add-comment {
      width: 100%;
      // padding: 0.8rem 1.2rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      // margin-top: 2.4rem;
      @extend .d-flex, .align-items-center;

      .image {
        flex: 0 0 4.3rem;
        margin-right: 0.5rem;

        .image-thumb {
          @include img-box-react(100%);
        }
      }
      textarea {
        padding-top: 1rem;
        font-size: 1.8rem;
      }
      .btn-info {
        display: flex;
        height: 30px;
        width: 30px;
        align-items: center;
        justify-self: center;
        margin-right: 6px;
        justify-content: center;
      }
    }
  }
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.new-details {
  .min-width-ads {
    min-width: 300px;
  }
  .liveVideos > iframe {
    min-height: 230px;
    width: 100%;
    height: 400px;
    @media screen and (max-width: 1000px) {
      height: 350px;
    }
    @media screen and (max-width: 820px) {
      height: 350px;
    }
    @media screen and (max-width: 620px) {
      height: 300px;
    }
    @media screen and (max-width: 500px) {
      height: 230px !important;
    }
  }
  // .card-right {
  //   flex: 0 0 37.4rem !important;
  // }
  .card-left {
    .card-body {
      h2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
      }
      margin-bottom: 2.4rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
      .react-player__preview {
        @supports not (-webkit-touch-callout: none) {
          /* CSS for other than iOS devices */
          height: 100%;
        }
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS/safari devices */
          @media (max-width: 768px) {
            max-height: 200px;
          }
          /* CSS specific to mac safari devices */
          @media (min-width: 769px) {
            max-height: 360px;
          }
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            /* CSS specific to mac safari devices */
            @media (min-width: 769px) {
              max-height: 360px;
            }
          }
        }
      }

      .detail-video-sec {
        width: 100% !important;
        @supports not (-webkit-touch-callout: none) {
          /* CSS for other than iOS devices */
          height: -webkit-fill-available !important;
        }
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS/safari devices */
          @media (max-width: 768px) {
            video {
              max-height: 200px;
            }
          }
          /* CSS specific to mac safari devices */
          @media (min-width: 769px) {
            video {
              max-height: 360px;
            }
          }
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            /* CSS specific to mac safari devices */
            @media (min-width: 769px) {
              video {
                max-height: 360px;
              }
            }
          }
        }
      }
      a {
        img {
          height: 100%;
        }
      }
      &.post-body {
        padding-bottom: 0;
        font-size: 2rem;
        line-height: 3rem;
        @include media-breakpoint-down(xl) {
          font-size: 1.8rem !important;
          line-height: 3rem !important;
        }
        @include media-breakpoint-down(lg) {
          font-size: 1.8rem !important;
        }
        @include media-breakpoint-down(md) {
          font-size: 1.8rem !important;
          // padding: 0 1.4rem;
        }
        figure {
          margin: 0 auto 1rem;
          max-width: 100%;
          text-align: center;
          img {
            margin-bottom: 0.5rem;
          }
          figcaption {
            text-align: center;
            font-size: 1.6rem !important;
            color: $dark-gray-600;
            font-style: italic;
          }
          &.table {
            width: 100% !important;
            height: 100% !important;
          }
        }
        blockquote {
          padding: 1rem 2rem;
          margin: 0 0 2rem;
          border-left: 0.5rem solid #eee;
          p {
            margin-bottom: 0;
          }
          strong {
            font-size: 18px !important;
            font-weight: 600 !important;
            line-height: 24px;
          }
        }
        table {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    // h1 {
    //   @include media-breakpoint-down(sm) {
    //     font-size: 22px !important;
    //   }
    // }
    // h2 {
    //   font-size: 2.4rem !important;
    //   text-align: justify;
    //   span {
    //     font-size: 2.4rem !important;
    //   }
    //   @include media-breakpoint-down(xxl) {
    //     font-size: 2.4rem !important;
    //     span {
    //       font-size: 2.4rem !important;
    //     }
    //   }
    //   @include media-breakpoint-down(sm) {
    //     font-size: 20px !important;
    //     span {
    //       font-size: 20px !important;
    //     }
    //   }
    //   &:empty {
    //     display: none;
    //   }
    // }
    // span {
    //   text-align: justify;
    //   font-size: 18px !important;
    //   @include media-breakpoint-down(sm) {
    //     font-size: 18px !important;
    //   }
    // }
    h3 {
      font-size: 2.4rem !important;
      text-align: justify;
      @include media-breakpoint-down(xxl) {
        font-size: 2.2rem !important;
      }
      @include media-breakpoint-down(sm) {
        font-size: 18px !important;
      }
    }
    p,
    li {
      font-family: $font-family-sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      // text-align: justify;
      word-break: break-word;

      // i {
      //   font-size: 1.6rem;
      //   text-align: center;
      //   margin: 0 auto;
      //   display: block;
      //   @include media-breakpoint-down(sm) {
      //     font-size: 18px !important;
      //   }
      // }
      & p * {
        font-family: $font-family-sans-serif;
      }
      span {
        font-family: $font-family-sans-serif;
        font-family: inherit !important;
      }
    }
    strong {
      font-size: 22px !important;
      font-weight: 500 !important;
      line-height: 30px;
    }
    strong[classname="h2strong"] {
      font-size: 24px !important;
      font-weight: 600 !important;
      line-height: 32px;
    }
    blockquote {
      // text-align: justify;
      font-size: 18px !important;
      @include media-breakpoint-down(sm) {
        font-size: 18px !important;
      }
    }
    .media {
      .iframely-embed {
        .iframely-responsive {
          position: relative;
          width: 100%;
          --bs-aspect-ratio: calc(9 / 16 * 100%);
          &:before {
            display: block;
            padding-top: var(--bs-aspect-ratio);
            content: "";
          }
          & > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.new-details-small {
  .card-left {
    .card-body {
      // h2 {
      //   font-size: 18px !important;
      //   line-height: 3rem;
      //   //text-align: justify;
      //   @include media-breakpoint-down(sm) {
      //     font-size: 16px !important;
      //   }
      // }
      margin-bottom: 2.4rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
      .react-player__preview {
        @supports not (-webkit-touch-callout: none) {
          /* CSS for other than iOS devices */
          height: 100%;
        }
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS/safari devices */
          @media (max-width: 768px) {
            max-height: 200px;
          }
          /* CSS specific to mac safari devices */
          @media (min-width: 769px) {
            max-height: 360px;
          }
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            /* CSS specific to mac safari devices */
            @media (min-width: 769px) {
              max-height: 360px;
            }
          }
        }
      }

      .detail-video-sec {
        width: 100% !important;
        @supports not (-webkit-touch-callout: none) {
          /* CSS for other than iOS devices */
          height: -webkit-fill-available !important;
        }
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS/safari devices */
          @media (max-width: 768px) {
            video {
              max-height: 200px;
            }
          }
          /* CSS specific to mac safari devices */
          @media (min-width: 769px) {
            video {
              max-height: 360px;
            }
          }
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            /* CSS specific to mac safari devices */
            @media (min-width: 769px) {
              video {
                max-height: 360px;
              }
            }
          }
        }
      }
      a {
        img {
          height: 100%;
        }
      }
      &.post-body {
        padding-bottom: 0;
        font-size: 2rem !important;
        line-height: 3rem !important;
        @include media-breakpoint-down(xl) {
          font-size: 1.8rem !important;
          line-height: 3rem !important;
        }
        @include media-breakpoint-down(lg) {
          font-size: 1.8rem !important;
        }
        @include media-breakpoint-down(md) {
          font-size: 1.8rem !important;
          // padding: 0 1.4rem;
        }
        h2 {
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
        }
        figure {
          margin: 0 auto 1rem;
          max-width: 100%;
          text-align: center;
          img {
            margin-bottom: 0.5rem;
          }
          figcaption {
            text-align: center;
            font-size: 1.6rem !important;
            color: $dark-gray-600;
            font-style: italic;
          }
        }
        blockquote {
          padding: 1rem 2rem;
          margin: 0 0 2rem;
          border-left: 0.5rem solid #eee;
          p {
            margin-bottom: 0;
          }
          strong {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
          }
        }
      }
    }

    // h1 {
    //   font-size: 24px !important;
    //   @include media-breakpoint-down(sm) {
    //     font-size: 20px !important;
    //   }
    // }
    // h2 {
    //   font-size: 22px !important;
    //   text-align: justify;
    //   span {
    //     font-size: 22px !important;
    //   }
    //   @include media-breakpoint-down(xxl) {
    //     font-size: 22px !important;
    //     span {
    //       font-size: 22px !important;
    //     }
    //   }
    //   @include media-breakpoint-down(sm) {
    //     font-size: 18px !important;
    //     span {
    //       font-size: 18px !important;
    //     }
    //   }
    //   &:empty {
    //     display: none;
    //   }
    // }
    // span {
    //   text-align: justify;
    //   font-size: 16px !important;
    //   @include media-breakpoint-down(sm) {
    //     font-size: 16px !important;
    //   }
    // }
    h3 {
      font-size: 20px !important;
      text-align: justify;
      @include media-breakpoint-down(xxl) {
        font-size: 20px !important;
      }
      @include media-breakpoint-down(sm) {
        font-size: 18px !important;
      }
    }
    p,
    li {
      font-family: $font-family-sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      // text-align: justify;
      word-break: break-word;
      // @include media-breakpoint-down(xxl) {
      //   font-size: 2.4rem;
      // }

      // i {
      //   font-size: 1.6rem !important;
      //   text-align: center;
      //   margin: 0 auto;
      //   display: block;
      //   @include media-breakpoint-down(sm) {
      //     font-size: 16px !important;
      //   }
      // }
      & p * {
        font-family: $font-family-sans-serif;
      }
      span {
        font-family: $font-family-sans-serif;
        font-family: inherit !important;
      }
    }
    strong {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    strong[classname="h2strong"] {
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
    }

    blockquote {
      // text-align: justify;
      font-size: 16px !important;
      @include media-breakpoint-down(sm) {
        font-size: 16px !important;
      }
    }
    .media {
      .iframely-embed {
        .iframely-responsive {
          position: relative;
          width: 100%;
          --bs-aspect-ratio: calc(9 / 16 * 100%);
          &:before {
            display: block;
            padding-top: var(--bs-aspect-ratio);
            content: "";
          }
          & > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.new-details-large {
  .card-left {
    .card-body {
      h2 {
        font-size: 22px;
        font-weight: 400;
        line-height: 32px;
      }
      margin-bottom: 2.4rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
      .react-player__preview {
        @supports not (-webkit-touch-callout: none) {
          /* CSS for other than iOS devices */
          height: 100%;
        }
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS/safari devices */
          @media (max-width: 768px) {
            max-height: 200px;
          }
          /* CSS specific to mac safari devices */
          @media (min-width: 769px) {
            max-height: 360px;
          }
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            /* CSS specific to mac safari devices */
            @media (min-width: 769px) {
              max-height: 360px;
            }
          }
        }
      }

      .detail-video-sec {
        width: 100% !important;
        @supports not (-webkit-touch-callout: none) {
          /* CSS for other than iOS devices */
          height: -webkit-fill-available !important;
        }
        @supports (-webkit-touch-callout: none) {
          /* CSS specific to iOS/safari devices */
          @media (max-width: 768px) {
            video {
              max-height: 200px;
            }
          }
          /* CSS specific to mac safari devices */
          @media (min-width: 769px) {
            video {
              max-height: 360px;
            }
          }
        }
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            /* CSS specific to mac safari devices */
            @media (min-width: 769px) {
              video {
                max-height: 360px;
              }
            }
          }
        }
      }
      a {
        img {
          height: 100%;
        }
      }
      &.post-body {
        padding-bottom: 0;
        font-size: 2rem !important;
        line-height: 3rem !important;
        @include media-breakpoint-down(xl) {
          font-size: 1.8rem !important;
          line-height: 3rem;
        }
        @include media-breakpoint-down(lg) {
          font-size: 1.8rem !important;
        }
        @include media-breakpoint-down(md) {
          font-size: 1.8rem !important;
          // padding: 0 1.4rem;
        }
        figure {
          margin: 0 auto 1rem;
          max-width: 100%;
          text-align: center;
          img {
            margin-bottom: 0.5rem;
          }
          figcaption {
            text-align: center;
            font-size: 1.6rem !important;
            color: $dark-gray-600;
            font-style: italic;
          }
        }
        blockquote {
          padding: 1rem 2rem;
          margin: 0 0 2rem;
          border-left: 0.5rem solid #eee;
          p {
            margin-bottom: 0;
            word-wrap: break-word;
          }
          strong {
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }

    // h1 {
    //   font-size: 28px !important;
    //   @include media-breakpoint-down(sm) {
    //     font-size: 26px !important;
    //     font-weight: 600 !important;
    //     line-height: 4.3rem;
    //   }
    // }
    // h2 {
    //   font-size: 26px !important;
    //   text-align: justify;
    //   span {
    //     font-size: 26px !important;
    //   }
    //   @include media-breakpoint-down(xxl) {
    //     font-size: 26px !important;
    //     span {
    //       font-size: 26px !important;
    //     }
    //   }
    //   @include media-breakpoint-down(sm) {
    //     font-size: 22px !important;
    //     span {
    //       font-size: 22px !important;
    //     }
    //   }
    //   &:empty {
    //     display: none;
    //   }
    // }
    span {
      text-align: justify;
      // font-size: 20px !important;
      // @include media-breakpoint-down(sm) {
      //   font-size: 20px !important;
      // }
      // font-size: 1.4rem !important;
    }
    .sabhi-dekhe {
      font-size: 1.8rem !important;
    }
    h3 {
      font-size: 24px !important;
      text-align: justify;
      @include media-breakpoint-down(xxl) {
        font-size: 24px !important;
      }
      @include media-breakpoint-down(sm) {
        font-size: 20px !important;
      }
    }
    p,
    li {
      font-family: $font-family-sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 32px;
      // text-align: justify;
      word-break: break-word;

      // > i {
      //   font-size: 1.6rem;
      //   text-align: justify;
      //   margin: 0 auto;
      //   @include media-breakpoint-down(sm) {
      //     font-size: 18px;
      //   }
      // }
      & p * {
        font-family: $font-family-sans-serif;
      }
      span {
        font-family: $font-family-sans-serif;
        font-size: inherit !important;
      }
    }
    strong {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
    strong[classname="h2strong"] {
      font-size: 26px;
      font-weight: 600;
      line-height: 34px;
    }
    blockquote {
      // text-align: justify;
      font-size: 20px !important;
      font-weight: 700 !important;
      @include media-breakpoint-down(sm) {
        // font-size: 18px !important;
      }
    }
    .media {
      .iframely-embed {
        .iframely-responsive {
          position: relative;
          width: 100%;
          --bs-aspect-ratio: calc(9 / 16 * 100%);
          &:before {
            display: block;
            padding-top: var(--bs-aspect-ratio);
            content: "";
          }
          & > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
#target_comment {
  margin-top: 20px;
}

.news-hashtag {
  margin-top: 20px;
  .title {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
  .hashtags-list {
    li {
      margin-right: 1rem;
    }
    @include media-breakpoint-down(md) {
      padding: 0 1.4rem 1.4rem 0;
      li {
        padding: 1.2rem;
        border: 1px solid #eb0e8c;
        border-radius: 10px;
        margin-bottom: 1rem;
        margin-right: 1rem;
        a {
          color: #eb0e8c;
          font-size: 20px;
        }
      }
    }
  }
}

.stickyadcode {
  // position: fixed;
  z-index: 99;
  // transform: translate(-50%,0);
  // display: none;
  // margin-left: auto;
  // margin-right: auto;
  height: 100px;
  align-items: center;
  //color: yellow;
  background-color: rgba(255, 255, 255, 0);
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.stickyadcode1 {
  z-index: 99;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
}

.stickyadcode {
  bottom: 3px !important;
}

.stickyadcode1 {
  bottom: 3px !important;
}

@media screen and (max-width: 767px) {
  .stickyadcode {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .stickyadcode {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .stickyadcode1 {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .stickyadcode {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .stickyadcode1 {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .stickyadcode1 {
    max-width: 375px !important;
  }
}

// .raw-html-embed {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

@media screen and (max-width: 600px) {
  .raw-html-embed iframe {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .hide-desktop-ads {
    display: none;
  }
  // div[id^="google_ads_iframe_"] {
  //   max-width: 300px;
  // }
  // div[id^="google_ads_iframe_"] iframe {
  //   max-width: 300px;
  // }
}
.date-fix-overflow {
  overflow: visible !important;
  // margin-left: 0.5rem;

  margin-top: 1.5rem;
  @media screen and (max-width:768px) {
    width: 60vw !important;
  }
}

.date-updated {
  font-size: 14px !important;
}
.display-block {
  display: block !important;
  .mobile-options {
    justify-content: space-between;
    margin-top: 20px;
    margin-left: auto;
    // padding: 3px 7%;
    // border-top: #e6e6e6 2px solid;
    // border-bottom: #e6e6e6 2px solid;
    // div{
    //   padding: 3px 0;
    //   flex: 1 1 auto;
    //   position: relative;
    //   text-align: center;
    // }
    // div+div{
    //   border-left: solid 1px black;
    // }
    // div:first-child { text-align: left; }
    // div:last-child { text-align: right; }
  }
  @include media-breakpoint-down(md) {
    .mobile-date {
      // display: none;
    }
  }
}
.pre-span {
  white-space: pre;
  font-size: 1.4rem !important;
}

.comment-arrow {
  padding-top: 0.6rem;
  // margin-left: 1rem;
}
.redirect-app-body {
  margin: 20px 0;
}
.redirect-app-close {
  position: absolute;
  right: 30px;
}

.headline-footer {
  width: 100% !important;
  justify-content: space-around !important;
}

.like-item {
  div {
    i {
      padding-right: 0 !important;
      margin-right: 0.9rem !important;
    }

    .icon-heart,
    .icon-heart-fill {
      margin-right: 0 !important;
    }
  }
}

.tbl-read-more-box {
  width: 100% !important;

  @media (min-width: 1200px) {
    width: 67.4% !important;
  }

  @media (min-width: 1800px) {
    width: 67.2% !important;
  }
  .tbl-read-more-btn {
    background: #d9368b !important;
    border-radius: 10px !important;
    width: 130px !important;
    height: 30px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-align: center !important;
    color: white !important;
  }
}

.comment-opt {
  padding: 0.4rem 0.8rem !important;
  color: #0000008c !important;

  i {
    margin: 0.6rem 0.8rem 0 0.6rem !important;
    color: #d9368b !important;
  }
}

.display-block {
  .d-visible-icons {
    @media (max-width: 1199px) {
      display: none !important;
    }
  }

  .m-visible-icons {
    margin-top: 1rem;
    padding: 3px 7%;
    border-top: #e6e6e6 2px solid;
    border-bottom: #e6e6e6 2px solid;
    @media (min-width: 1200px) {
      display: none !important;
    }
  }
}
.news-top-bar-audio {
  display: flex;

  flex-direction: row;

  justify-content: space-between;

  padding: 1.5rem 1.5rem;

  .news-title-left-audio {
    display: flex;

    img {
      height: 2.5rem;

      margin: auto 0.8rem auto 0;
    }

    .news-title-audio {
      margin: auto 0;

      span {
        font-family: "Mukta", system-ui, -apple-system, BlinkMacSystemFont;

        font-style: normal;

        font-weight: 600;

        font-size: 20px;

        color: #fcc623 !important;
      }
    }
  }

  .news-title-right-audio {
    display: flex;

    align-items: center;

    cursor: pointer;

    span {
      font-size: 16px;

      font-weight: 500;

      margin-right: 1rem;
    }

    .right-icon {
      border: 1px solid gray;

      border-radius: 50%;

      height: 2.2rem;

      width: 2.2rem;

      display: flex;

      align-items: center;

      justify-content: center;

      i {
        height: 1.2rem !important;

        padding: 0 !important;

        font-size: 1.4rem !important;

        margin: auto;
      }
    }
  }

  a {
    display: flex;

    align-items: center;
  }
}
// .vdo_video_unit{
// bottom: 72px !important;
// }
.advertisement {
  font-size: 11px !important;
}
#ads {
  font-size: 11px !important;
}
.custom-dropdown-news .dropdown-menu {
  border: none !important;
  overflow-y: visible !important;
  margin-top: -5px;
}
.custom-dropdown-news .dropdown-menu::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0; /* Safari and Chrome */
}
.custom-dropdown-news .dropdown-menu .option-dropdown {
  overflow-y: visible !important;
}
.custom-dropdown-news .dropdown-menu .option-dropdown::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0; /* Safari and Chrome */
}
.google_news img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.google_news h6 {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.user-section {
  max-width: 50%;
}
.second-author-name {
  white-space: break-spaces !important;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  // line-height: 2.8rem;
  // max-height: 5.6rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
blockquote > p {
  font-weight: 700 !important;
}
.card-body {
  blockquote > p {
    font-weight: 600 !important;
  }
}
figure[class="table"] > table {
  width: inherit;
}
table {
  border: 1px double #b3b3b3;
  td {
    border: 1px solid #bfbfbf;
  }
}

@media screen and (max-width: 600px) {
  .image_resized {
    width: 100% !important;
  }
}
blockquote p:not(:last-child) {
  margin-bottom: 12px !important;
  /* Your styling properties here */
}
.add-comment-cont {
  display: flex;
  align-items: center;
  gap: 12px;
}
.remove-border {
  border: none !important;
}
.comments-header {
  display: flex;
  justify-content: space-between;
  .sorting-tabs {
    display: flex;
    gap: 12px;
    .sorting-tab-item {
      padding: 2px 6px;
      border-radius: 4px;
      border: 1px solid #d6d8e8;
    }
  }
}
