header {
  &.site-header {
    background-color: $white;
  }
  .top-strip-fixed {
    @include media-breakpoint-down(md) {
      position: fixed;
      z-index: 99;
      width: 100%;
    }
  }
  .top-strip {
    display: flex;
    @include media-breakpoint-down(xl) {
      background-color: $primary;
      padding: 0.7rem 0;
    }

    .head-section {
      // width: 16rem;
      display: flex;
      .logo {
        padding: 0.8rem 3.8rem 0 0.8rem;
        @include media-breakpoint-down(xxl) {
          padding: 0.8rem 2rem 0 0.8rem;
        }
        @include media-breakpoint-down(xl) {
          padding: 0;
          text-align: left;
        }
        .lallan-logo {
          img {
            vertical-align: top;
          }
        }
      }
    }
    .mr-head {
      width: calc(100% - 4rem);
    }
    .logo-section {
      width: 21.6rem;
    }
    .mr-head {
      margin-top: 1.2rem;
      @include media-breakpoint-down(xl) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
        margin-top: 0;
      }
      & > .row + .row {
        @include media-breakpoint-down(xl) {
          width: 100%;
        }
      }
      .mt-2 {
        @include media-breakpoint-down(xl) {
          margin-top: 0 !important;
          margin-left: 0.5rem;
        }
      }
    }
  }
  .bottom-strip {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
      padding-top: 50px;
    }
  }

  .logo {
    @include media-breakpoint-down(xl) {
      text-align: center;
    }
    .m-logo {
      display: none;
      @include media-breakpoint-down(xl) {
        display: block;
      }
      // img {
      //   height: 5rem;
      // }
    }
    .lallan-logo {
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }
    // img {
    //   @include media-breakpoint-down(xl) {
    //     height: 4.2rem;
    //   }
    // }
  }
  // .topcom_nav {
  //   list-style-type: none;
  //   padding: 0;
  //   margin: 0 0 0.5rem;
  //   overflow-x: auto;
  //   @extend .d-flex, .align-items-center;
  //   .topcom_nav_li {
  //     padding: 0.5rem 0;
  //     margin-right: 2.5rem;
  //     border: 0;
  //     font-size: 1.2rem;
  //     font-weight: 400;
  //     a {
  //       color: $dark-gray-200;
  //       text-decoration: none;
  //       white-space: nowrap;
  //     }
  //     &.active {
  //       background-color: transparent;
  //       a {
  //         font-weight: 700;
  //         color: $black;
  //       }
  //     }
  //   }
  // }
  .top-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .arena-btn {
      display: none;
      margin-right: 1.5rem;
      @include media-breakpoint-down(xl) {
        display: block;
        width: 8.5rem;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 13%;
      }
      img {
        width: 8.5rem;
      }
    }
    .more-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      @include media-breakpoint-down(xl) {
        display: none;
      }
      span {
        font-size: 1.4rem;
        font-weight: 600;
        color: $black;
        margin-right: 1rem;
      }
    }
    .option-dropdown {
      cursor: pointer;
      margin-left: 0.5rem;
      @include media-breakpoint-down(xl) {
        margin-right: 3rem;
      }
      .dropdown-list {
        top: 2.5rem;
        right: 0;
        box-shadow: 0 0 1rem $dark-gray-400;
        z-index: 9999;
        li {
          cursor: text !important;
          .user-name {
            font-size: 1.4rem;
            font-weight: 500;
            color: $gray;
            padding: 1.2rem;
          }
          a {
            color: $gray;
            cursor: pointer;
            span {
              font-weight: 500;
              color: $gray !important;
              @include media-breakpoint-down(xl) {
                display: inline-block;
              }
            }
            &:hover {
              background-color: $gray-light-200;
            }
          }
        }
      }
    }
  }
  .suggest-option-dropdown {
    position: absolute;
    cursor: pointer;
    z-index: 9999;
    width: 400px;
    top: 12rem;
    right: 0;
    background-color: white;
    @include media-breakpoint-down(xl) {
      width: 100%;
      max-height: 50vh;
      top: 15rem;
      left: 0;
      right: auto;
    }
    .dropdown-list {
      top: 2.5rem;
      right: 0;
      max-height: 50vh;
      overflow-y: scroll;
      box-shadow: 0 0 1rem $dark-gray-400;
      z-index: 9999;
      padding: 10px 20px;
      li {
        cursor: pointer !important;
        border-bottom: solid 1px $gray-light-100;
        padding: 8px 0;
        a {
          // color: $gray;
          cursor: pointer;
          span {
            font-weight: 500;
            color: $gray !important;
            @include media-breakpoint-down(xl) {
              display: inline-block;
            }
          }
        }
        &:hover {
          background-color: $gray-light-200;
        }
      }
    }
  }
  .login {
    color: $primary;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include media-breakpoint-down(xl) {
      margin: 0 0.5rem 0;
      color: $white;
      font-size: 2.5rem;
    }
    span {
      text-transform: uppercase;
      font-weight: 600;
      margin-right: 1rem;
      margin-top: 0.3rem;
      white-space: nowrap;
      @include media-breakpoint-down(xl) {
        display: none;
        margin: 0;
      }
      i {
        font-size: 1.6rem;
      }
    }
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
  .flashnews-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .breakingNews {
      width: calc(100% - 13.3rem);
    }
    .login {
      width: 10.1rem;
    }
    .more-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      @include media-breakpoint-down(xl) {
        display: none;
      }
      span {
        font-size: 1.4rem;
        font-weight: 600;
        color: $black;
        margin-right: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0.5rem;
      }
    }
    .option-dropdown {
      cursor: pointer;
      margin-left: 0.5rem;
      @include media-breakpoint-down(xl) {
        margin-right: 3rem;
      }
      .dropdown-list {
        top: 2.5rem;
        right: 0;
        box-shadow: 0 0 1rem $dark-gray-400;
        z-index: 9999;
        li {
          cursor: text !important;
          .user-name {
            font-size: 1.4rem;
            font-weight: 500;
            color: $gray;
            word-break: break-word;
            padding: 1.2rem;
          }
          a {
            color: $gray;
            cursor: pointer;
            span {
              font-weight: 500;
              color: $gray !important;
              @include media-breakpoint-down(xl) {
                display: inline-block;
              }
            }
            &:hover {
              background-color: $gray-light-200;
            }
          }
        }
      }
    }
  }
  // .breakingNews {
  //   background-color: #fff;
  //   border-radius: 1rem;
  //   overflow: hidden;
  //   border: 0.4px solid #c9c9c9;
  //   padding-right: 0.5rem;
  //   @extend .d-flex, .align-items-center, .justify-content-between;
  //   .breaking-newstxt {
  //     margin: 0;
  //     padding: 0.45rem 1.3rem 0.45rem 1.5rem;
  //     background-color: #6c757d;
  //     font-size: 1.8rem;
  //     width: 14.2rem;
  //     text-align: center;
  //     a {
  //       color: $white;
  //       text-decoration: none;
  //       &:hover {
  //         text-decoration: underline;
  //       }
  //       @include breakpoint(laptop) {
  //         font-size: 1.8rem;
  //       }
  //     }
  //   }
  //   .flashnews {
  //     overflow: hidden;
  //     width: calc(100% - 14.6rem);
  //     .overlay {
  //       display: none;
  //     }
  //     .news-marquee {
  //       .marquee {
  //         overflow-y: hidden;
  //         min-width: auto;
  //         --duration: 40s !important;
  //         a {
  //           font-size: 1.8rem;
  //           // color: $white;
  //           font-weight: 400;
  //           padding: 0.542rem 0;
  //           color: #0000008c;
  //           text-decoration: none;
  //         }
  //         .breaking_divder {
  //           padding: 0 1.5rem;
  //         }
  //       }
  //     }
  //   }
  //   .breakingsocial {
  //     background-color: $choko-dark;
  //     ul {
  //       padding: 0;
  //       margin: 0;
  //       li {
  //         background-color: transparent;
  //         border: 0;
  //         padding: 0.6rem 1.2rem 0.6rem;
  //         a {
  //           display: block;
  //           line-height: 0.8rem;
  //         }
  //       }
  //     }
  //   }
  // }
  .cate-search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include media-breakpoint-down(xl) {
      margin-right: 0;
    }
    .header-category {
      width: 105.5rem;
      margin-bottom: 2rem;
      @media (max-width: 1600px) {
        width: 95.5rem;
      }
      @media (max-width: 1350px) {
        width: 85.25rem;
      }
      @media (max-width: 1300px) {
        width: 81.25rem;
      }
      @media (max-width: 1250px) {
        width: 79.25rem;
      }
      @media (max-width: 1225px) {
        width: 74.25rem;
      }
      @media (max-width: 1175px) {
        width: 69.25rem;
      }
      @media (max-width: 1100px) {
        width: 64.25rem;
      }
      @media (max-width: 1050px) {
        width: 59.25rem;
      }
      @include media-breakpoint-down(xl) {
        display: none;
      }
      .category-menu {
        .list-group-item {
          a {
            i {
              margin-right: 1.2rem;
              height: 2rem;
            }
            & > span {
              font-size: 1.6rem;
              display: block;
            }
          }
        }
      }
    }
    .header-serch {
      // width: calc(100% - 107rem);
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      @media (max-width: 1800px) {
        // width: calc(100% - 84rem);
      }
      @media (max-width: 1439px) {
        // width: calc(100% - 75rem);
      }
      @include media-breakpoint-down(xl) {
        // width: calc(100% - 52.7rem);
      }
      @include media-breakpoint-down(xl) {
        // width: calc(100% - 40.9rem);
      }
      @include media-breakpoint-down(xl) {
        display: flex;
        align-items: center;
        width: 100%;
      }
      button {
        height: 4.4rem;
      }
      .search-box-container {
        width: 100%;
        @include media-breakpoint-down(xl) {
          width: 0;
        }
      }

      .arena-btn {
        display: none;
        @include media-breakpoint-down(xl) {
          display: flex;
          align-items: center;
        }
        span {
          color: $white;
          font-size: 1.6rem;
          margin-left: 0.3rem;
          line-height: 1.6rem;
          font-weight: 600;
        }
      }
    }
  }
  .category-menu {
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
    border: 0.1rem solid $gray-light-100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.9rem;
    @include media-breakpoint-down(xl) {
      display: none;
    }
    .list-group-item {
      position: relative;
      border-left: 0;
      border-right: 0;
      padding: 0;
      width: 100%;
      border: 0;
      @include media-breakpoint-down(xl) {
        background-color: transparent;
      }
      &:first-child {
        border-top-left-radius: 0.9rem;
        border-bottom-left-radius: 0.9rem;
      }
      &:last-child {
        border-top-right-radius: 0.9rem;
        border-bottom-right-radius: 0.9rem;
        &::after {
          display: none;
        }
        a {
          .icon-cat-video {
            margin-top: -0.4rem;
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        color: $gray;
        padding: 1rem 1.6rem;
        justify-content: center;
        @include media-breakpoint-down(xl) {
          padding: 1rem 1.6rem;
        }
        @include media-breakpoint-down(xl) {
          padding: 1rem 0.8rem;
        }
        &:hover {
          color: $white;
        }
        & > i {
          margin-right: 1.6rem;
          @include media-breakpoint-down(xl) {
            margin-right: 0;
          }
          @include media-breakpoint-down(xl) {
            color: $white;
            font-size: 2rem;
          }
        }
        & > span {
          font-size: 1.8rem !important;
          @include media-breakpoint-down(xl) {
            display: none;
          }
        }
      }
      &.active {
        background-color: $primary;
        &:first-child {
          a {
            border-top-left-radius: 0.9rem;
            border-bottom-left-radius: 0.9rem;
          }
        }
        a {
          color: $white;
        }
        &:last-child {
          a {
            border-top-right-radius: 0.9rem;
            border-bottom-right-radius: 0.9rem;
          }
        }
        &::after {
          display: none;
        }
      }
      &:hover {
        background-color: $primary;
        &::after {
          display: none;
        }
      }
      &::after {
        content: "";
        width: 0.1rem;
        height: calc(100% - 1rem);
        position: absolute;
        top: 0.5rem;
        right: 0;
        background-color: $gray-light-300;
        @include media-breakpoint-down(xl) {
          display: none;
        }
      }
    }
  }
  // .m-notificatio {
  //   display: none;
  //   @include media-breakpoint-down(xl) {
  //     display: flex;
  //   }
  //   .noti-btn {
  //     cursor: pointer;
  //     margin-left: auto;
  //     color: #ffffff;
  //     font-size: 2.2rem;
  //     margin-top: 0.2rem;
  //   }
  // }
  .search-action {
    display: flex;
    text-align: center;
    @include media-breakpoint-down(xl) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 1rem;
    }
    @include media-breakpoint-down(sm) {
      margin-right: 1.3rem;
    }
    button {
      border: 0;
      background-color: transparent;
      padding: 0 0.6rem;
      i {
        font-size: 1.8rem;
        color: $skyblue-light-100;
        @include media-breakpoint-down(xl) {
          color: $white;
        }
      }
      .icon-search {
        font-size: 2.5rem;
      }
    }
  }
  .sarch-box {
    @include media-breakpoint-down(xl) {
      position: absolute;
      top: 6.3rem;
      width: 100%;
      left: 0;
      z-index: 1001;
    }
    .input-group {
      @include media-breakpoint-down(xl) {
        margin-bottom: 0 !important;
      }
      .form-control {
        height: 4.4rem;
        @include media-breakpoint-down(xl) {
          height: 6.4rem;
          border-radius: 0;
        }
        &:focus + button {
          border-color: $primary;
        }
        &:focus + a {
          border-color: $primary;
        }
      }
      button {
        border-color: $gray-light-100;
        border-left-width: 0;
        min-width: 5rem;
        padding: 0rem 0rem;
        background-color: transparent;
        &:focus {
          box-shadow: none;
        }
        .icon-search {
          color: $skyblue-light-100;
        }
      }
      a {
        border-color: $gray-light-100;
        border-left-width: 0;
        min-width: 5rem;
        padding: 0.8rem 1rem;
        background-color: $white;
        @include media-breakpoint-down(xl) {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0;
          padding: 0.7rem 2rem;
        }
        &:focus {
          box-shadow: none;
        }
        .icon-search {
          color: $skyblue-light-100;
          font-size: 2.5rem;
          @include media-breakpoint-down(xl) {
            font-size: 3rem;
          }
        }
      }
    }
  }
  .left-nav {
    display: flex;
    align-items: center;
  }
  .navbar {
    border-top: 1px solid $gray-light-100;
    border-bottom: 1px solid $gray-light-100;
    padding: 0 0 0 3rem;
    background-color: $white;
    @media (min-width: 1000px) {
      padding: 0 0 0 5.3rem;
    }
    .slidenav-btn {
      // margin-right: 2.3rem;
      margin-right: 1.5rem;
      @include media-breakpoint-down(xl) {
        margin-right: 1.5rem;
      }
    }
    .notificaton-btn {
      margin-right: 1rem;
      position: relative;
      cursor: pointer;
      @include media-breakpoint-down(xl) {
        display: none;
      }
      .icon-notification {
        color: $skyblue-light-100;
        vertical-align: middle;
        font-size: 2.5rem;
      }
      &:after {
        content: "";
        width: 0.8rem;
        height: 0.8rem;
        background-color: $light-red;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 4px;
      }
    }
    .arena-btn {
      margin-right: 1.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 1.26rem;
      // width: 8rem;
      span {
        color: $skyblue-light-100;
        vertical-align: middle;
        font-size: 1.4rem;
        font-weight: 600;
      }
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }
    .navbar-nav {
      text-transform: capitalize;
      // display: flex;
      align-items: center;

      // overflow-x: scroll;
      // width: calc(100% - 16.3rem);
      // width: calc(100% - 39rem);
      // height: 4.6rem;
      @include media-breakpoint-down(xl) {
        width: calc(100% - 10.3rem);
        // width: calc(100% - 39rem);
      }
      @include media-breakpoint-down(xl) {
        width: calc(100% - 6.3rem);
      }
      .cat-scroll {
        overflow: auto;
        .os-scrollbar-horizontal {
          opacity: 0;
        }
        &:hover,
        &:focus {
          .os-scrollbar-horizontal {
            opacity: 1;
          }
        }
        .os-host {
          width: 100%;
        }
        .os-theme-dark {
          & > .os-scrollbar {
            & > .os-scrollbar-track {
              & > .os-scrollbar-handle {
                background-color: rgba(0, 0, 0, 0.3);
                @include media-breakpoint-down(xl) {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
      .default-overlay {
        overflow-x: scroll !important;
        scrollbar-color: $white;
        height: 100%;

        @include media-breakpoint-up(xl) {
          overflow-x: hidden !important;
        }
      }
      .default-overlay::-webkit-scrollbar {
        // -webkit-appearance: none;
        // width: 3px;
        // height: 7px;
        // border-radius: 15px;
        // cursor: pointer;
        // margin-top: -5px;
        // background-color: $white;
        // scrollbar-color: $white;
        display: none;
      }

      /* Track */
      .default-overlay::-webkit-scrollbar-track {
        border-radius: 50%;
        background-color: $white;
        display: none;
        background-color: $white;
        scrollbar-color: $white;
        // -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
      }

      /* Handle */
      .default-overlay::-webkit-scrollbar-thumb {
        background: $dark-gray-700;
        display: block;
      }

      /* Handle on hover */
      .default-overlay::-webkit-scrollbar-thumb:hover {
        background: $dark-gray-300;
        display: block;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 30px;
        // padding-top: 8px;
        align-items: center;
        // padding-right: 3rem;

        // @media screen and (max-width: 768px) {
        //   height: 350px !important;
        // }

        & > li {
          position: relative;
          &:hover {
            a {
              font-weight: 600;
              color: $primary;
            }
            .sub-categories {
              display: block;
              @include media-breakpoint-down(xl) {
                display: none;
              }
            }
          }
          &:last-child {
            // margin-right: 10rem;
            .sub-categories {
              left: -20rem;
            }
          }
          &:nth-last-child(2) {
            .sub-categories {
              left: -20rem;
            }
          }
          .sub-categories {
            position: absolute;
            top: 100%;
            left: -7rem;
            background-color: $white;
            border-radius: 1rem;
            box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.1);
            display: none;
            .dropdown {
              display: flex;
              flex-direction: row;
              li {
                position: relative;
                a {
                  font-size: 2rem;
                  font-weight: 400;
                  padding: 1.4rem 1.5rem;
                  white-space: nowrap;
                  display: block;
                  color: $dark-gray-300;
                  &:hover {
                    color: $primary;
                  }
                }
                &::before {
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 1.7rem;
                  width: 0.1rem;
                  height: 2rem;
                  background-color: $dark-gray-300;
                }
                &:last-child {
                  &::before {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      span {
        // padding: 0.3rem 0.5rem;
        position: relative;
        margin: 0 1.25rem;
        font-size: 16px;
        white-space: pre;
        color: #333;
        &:hover {
          font-weight: 600;
          color: $primary;
        }
        // @include media-breakpoint-down(xl) {
        //   padding: 1.74rem 0.5rem;
        // }
      }
      span.active {
        color: $pink;
        font-weight: bold;
        &::before {
          content: "";
          background-color: $primary;
          position: absolute;
          bottom: 0rem;
          width: 100%;
          height: 0.3rem;
          left: 0;
          border-radius: 0.8rem;
        }
      }
      .more-cate {
        .cate-btn {
          width: 3rem;
          height: 3rem;
          background-color: $gray-light-800;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 1rem;
            color: $dark-gray-800;
          }
        }
        @include media-breakpoint-down(xl) {
          display: none;
        }
      }
    }
  }
}
.top-banner {
  margin-bottom: 3rem;
}
.head-fixed {
  z-index: 999;
  padding: 0;
  & > div {
    transform: none !important;
    @include media-breakpoint-down(xl) {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }
  }
  .navbar {
    @include media-breakpoint-down(xl) {
      border: 0;
      height: 30px;
    }
    .cat-logo {
      width: 3.2rem;
      margin-right: 2rem;
      display: none;
      @include media-breakpoint-down(xl) {
        margin-right: 2rem;
      }
      @include media-breakpoint-down(xl) {
        margin-right: 1.5rem;
      }
      a {
        display: block;
        width: 3.3rem;
        img {
          width: 3.3rem;
        }
      }
    }
  }
  .sticky {
    .navbar {
      .cat-logo {
        display: block;
      }
    }
  }
  @media (max-width: 900px) {
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
  }
}
header {
  .sticky {
    .navbar-nav {
      // width: calc(100% - 22.3rem);
      @include media-breakpoint-down(xl) {
        width: calc(100% - 11rem);
      }
    }
  }
}

.download_img {
  margin-left: 20px;
  margin-top: -0.8rem;

  @media (max-width: 991px) {
    margin-right: -6rem !important;
  }
}

.download_img img {
  @media (max-width: 1250px) {
    width: 275px;
  }
  @media (max-width: 1125px) {
    width: 250px;
  }
  @media (max-width: 1050px) {
    width: 225px;
  }
  @media (max-width: 925px) {
    width: 200px;
  }
}

.d-visible {
  @include media-breakpoint-down(xl) {
    display: none !important;
  }
}

.m-visible {
  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}

.qr-image-container-header {
  width: 100%;
  text-align: center;
  .qr-image-header {
    width: 200px;
    height: 200px;
    @include media-breakpoint-down(xl) {
      width: 150px;
      height: 150px;
    }
  }
}

.mr-head {
  @include media-breakpoint-up(xl) {
    width: 100% !important;
  }
}

.header-category {
  margin-top: 0.6rem;
}

.navbar-nav {
  @include media-breakpoint-up(xl) {
    margin-right: 1.5rem;
  }
}

.lang {
  color: #d9368b;
  font-size: 1.8rem;
  margin-right: 0.5rem;
  margin-top: 0.4rem;
  cursor: pointer;
  display: block;
  @include media-breakpoint-up(xl) {
    // width: 8rem;
  }

  @media (min-width: 1300px) {
    // margin-right: 1.5rem;
  }
}

.lang-dropdown {
  top: 3.3rem !important;
}

.lang-dropdown li {
  padding: 1.2rem 1rem;
}

.user-dropdown {
  top: 3.5rem !important;
}

.user-dropdown li {
  padding: 0 !important;
}

.arrow-down {
  margin-top: 0.2rem;
}

.icon-globe {
  font-size: 2.4rem;
}

.user-nav {
  font-size: 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  // position: fixed;
  @include media-breakpoint-up(xl) {
    // min-width: 12.3rem;
    max-width: 11.5rem;
    // margin-right: 2rem;
  }
}

.option-dropdown {
  font-size: 14px;
  color: #5f5f5f !important;
}

.username {
  margin-top: 0.2rem;
}

.user-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .login {
    max-width: 10.1rem;
  }
  .more-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @include media-breakpoint-down(xl) {
      display: none;
    }
    .username {
      font-size: 1.8rem;
      // font-weight: 600;
      color: $black;
      margin-right: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.5rem;
      color: #0000008c;

      max-width: 8rem;
      @media (min-width: 1300px) {
        max-width: 10rem;
      }
    }
  }
  .option-dropdown {
    cursor: pointer;
    margin-left: 0.5rem;
    @include media-breakpoint-down(xl) {
      margin-right: 3rem;
    }
    .dropdown-list {
      top: 2.5rem;
      right: 0;
      box-shadow: 0 0 1rem $dark-gray-400;
      z-index: 9999;
      li {
        cursor: text !important;
        .user-name {
          font-size: 1.4rem;
          font-weight: 500;
          color: $gray;
          word-break: break-word;
          padding: 1.2rem;
        }
        a {
          color: $gray;
          cursor: pointer;
          span {
            font-weight: 500;
            color: $gray !important;
            @include media-breakpoint-down(xl) {
              display: inline-block;
            }
          }
          &:hover {
            background-color: $gray-light-200;
          }
        }
      }
    }
  }
}

.category-search {
  display: flex;
  // width: 100%;
  justify-content: space-between;

  .header-serch {
    // width: calc(100% - 107rem);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @media (max-width: 1800px) {
      // width: calc(100% - 84rem);
    }
    @media (max-width: 1439px) {
      // width: calc(100% - 75rem);
    }
    @include media-breakpoint-down(xl) {
      // width: calc(100% - 52.7rem);
    }
    @include media-breakpoint-down(xl) {
      // width: calc(100% - 40.9rem);
    }
    @include media-breakpoint-down(xl) {
      display: flex;
      align-items: center;
      width: 100%;
    }
    button {
      height: 4.4rem;
    }
    .search-box-container {
      width: 100%;
      @include media-breakpoint-down(xl) {
        width: 0;
      }
    }

    .arena-btn {
      display: none;
      @include media-breakpoint-down(xl) {
        display: flex;
        align-items: center;
      }
      span {
        color: $white;
        font-size: 1.6rem;
        margin-left: 0.3rem;
        line-height: 1.6rem;
        font-weight: 600;
      }
    }
  }
}

.search-box-container {
  .sarch-box {
    .input-group {
      @include media-breakpoint-up(xl) {
        margin-bottom: 0 !important;
      }
    }
  }
}

.suggest-option-dropdown {
  @media (min-width: 768px) {
    top: 6rem !important;
    width: 350px !important;
    right: 4rem !important;
  }

  @media (min-width: 992px) {
    width: 400px !important;
  }
}

.icon-search {
  @include media-breakpoint-up(xl) {
    color: #d9368b !important;
  }

  // @media (min-width: 1300px) {
  // margin-right: 1rem;
  // }
}

.icon-close {
  @include media-breakpoint-up(xl) {
    color: #d9368b !important;
  }

  @media (min-width: 1300px) {
    margin-right: 1rem;
  }
}

.srch-icon {
  font-size: 2.2rem !important;
}

.download-app-mobile {
  margin-left: 1.5rem;

  img {
    @include media-breakpoint-up(md) {
      width: 35px;
      height: 35px;
    }
  }
}

.logo-download-app {
  width: 100px;
  height: 100px;
  padding: 1rem;
  border-radius: 25px 25px 25px 25px;
  -moz-border-radius: 25px 25px 25px 25px;
  -webkit-border-radius: 25px 25px 25px 25px;
  border: 0px solid #fff;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(159, 159, 159, 1);
  -moz-box-shadow: 0px 5px 10px 0px rgba(159, 159, 159, 1);
  box-shadow: 0px 5px 10px 0px rgba(159, 159, 159, 1);
  margin-bottom: 1.5rem;
}

.mobile_app_download_text {
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 1.5rem;
}

.mobile_app_download_btn {
  a {
    all: revert;
    color: white !important;
    text-decoration: none;
  }

  margin: 1.5rem 0 0 0;
  padding: 1rem;
  background-color: #d9368b;
  width: 14rem;
  border: 1px solid #d9368b;
  border-radius: var(--bs-modal-border-radius);
  font-size: 1.6rem;
}

.mob-nav {
  display: flex;
  flex-direction: row;
}

.dots {
  margin-top: 0.8rem;
  margin-right: 1.5rem;
}

.srch-lang {
  display: flex;
  flex-direction: row;
  right: 0;
  position: absolute;
  margin-right: 1rem;
  align-items: center;
}

.icon-user {
  font-size: 2.4rem;
}

.more-cat-dropdown {
  .dropdown-list {
    margin-left: 3rem;
    margin-top: 3.5rem;
  }

  li {
    padding: 0;
  }
}

.option-dropdown li {
  // padding: 1.5rem;
  font-size: 1.8rem !important;
  font-weight: 400 !important;
  color: #0000008c !important;
}

.more-cat-dropdown li a {
  padding: 0 !important;
}

.more-cat-dropdown li {
  padding: 1.2rem 1rem !important;
}

.user-dropdown li a span {
  font-size: 1.8rem !important;
  font-weight: 400 !important;
  color: #0000008c !important;
}

.user-dropdown li a {
  padding: 0 !important;
}

.user-dropdown li {
  padding: 1.2rem !important;
}

.username,
.signin-username {
  display: block;
}

.sticky {
  .navbar {
    .username,
    .signin-username {
      display: none;
    }
  }
}

.arena-img {
  img {
    @include media-breakpoint-up(md) {
      width: 35px;
      height: 35px;
    }
  }
}

.m-logo {
  img {
    @include media-breakpoint-up(md) {
      width: 45px;
      height: 45px;
    }
  }
}
