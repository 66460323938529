.backlink-bar{
  &.profile-title{
    @include media-breakpoint-down(md) {
      padding-left: 2.6rem;
    }
  }
}
.profile-card {
  padding: 3.44rem 4rem;
  background-color: $white;
  border-radius: 1.5rem;
  @extend .flex-fill;

  @include media-breakpoint-down(sm) {
    padding: 2rem;
  }
}
.phone-group {
  @extend .d-flex;

  > .form-control {
    width: 6.5rem;
    background-color: $white;
    margin-right: 1.2rem;
  }
  .phone-text {
    flex: 1;
  }
}
.pic-wrap {
  flex: 0 0 11rem;
  max-width: 11rem;
  height: 11rem;
  @extend .position-relative;

  .pic-thumb {
    @include img-box(100%);
    height: 100%;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }
  }
  .custom-file-input {
    width: 0;
    height: 0;
    opacity: 0;
    @extend .p-0,.m-0,.overflow-hidden;
  }
  label {
    position: absolute;
    width: 3.2rem;
    height: 3.2rem;
    right: .4rem;
    bottom: 0;
    background-color: $primary;
    border-radius: 50%;
    color: $white;
    font-size: 1.4rem;
    cursor: pointer;
    @extend .d-flex, .align-items-center,.justify-content-center;
  }
}