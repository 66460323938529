.gallery-section {
  background-color: $pink-light;
  padding: 2.9rem 0 5.4rem;
  margin-bottom: 3rem;
  .slick-slider {
    overflow: hidden;
    .slick-list {
      margin: 0 -2.5rem !important;
      .slick-slide > div {
        padding: 0 2.5rem !important;
      }
    }
  }
  .gallery-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    .gallery-head-left {
      font-size: 2.7rem;
      @extend .d-flex, .align-items-center;
      color: $white;
      i {
        padding-right: 1.6rem;
      }
      span {
        font-weight: 500;
      }
    }
    .gallery-head-right {
      .gallery-link {
        font-size: 1.9rem;
        color: $white;
        i {
          font-size: 1.6rem;
          padding-left: 1.2rem;
          margin-bottom: 0.6rem;
        }
      }
    }
  }
  .gallery-card {
    border-radius: 1.5rem;
    overflow: hidden;
    .gallery-top {
      position: relative;
      a {
        .gallery-image-wrap {
          @include img-box(56.25%);
        }
        img {
          height: 100%;
          width: 100%;
        }
      }
      .images-info {
        display: flex;
        align-items: center;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.65) 100%
        );
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 2.8rem 1.2rem;
        .gallery-info-icon {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
          border: 0.1rem solid $white;
          margin-right: 1rem;
          @extend .d-flex, .align-items-center, .justify-content-center;
          span {
            color: $white;
          }
        }
        p {
          color: $white;
          margin-bottom: 0;
        }
      }
    }
    .gallery-bot {
      background-color: $white;
      border-radius: 0 0 1.5rem 1.5rem;
      padding: 1.4rem 2rem 1.8rem;
      min-height: 14.2rem;
      .gallery-comment-views {
        margin-bottom: 1.4rem;
        @extend .d-flex, .align-items-center;
        .gallery-counts {
          margin-right: 1.5rem;
          i {
            color: $dark-gray-400;
            margin-right: 0.5rem;
          }
          span {
            color: $dark-gray-400;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .gallery-options {
          margin-right: -0.5rem;
          .option-dropdown {
            & > i {
              cursor: pointer;
              font-size: 1.8rem;
              color: $black;
            }
          }
        }
      }
      .gallery-text {
        font-size: 1.6rem;
        a {
          &:hover {
            p {
              color: $primary;
            }
          }
          p {
            color: $black;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.Column-section {
    background-color: $green;
  }
}
