.main-blog {
  min-height: 90vh;
  background-color: white;
  display: none;
}
.events-mobile {
  display: none;
}
.events-desktop {
  display: block;
}

@media screen and (max-width: 768px) {
  .main-blog {
    display: block;
  }
  .events-mobile {
    display: block;
  }
  .events-desktop {
    display: none;
  }
  .event-right-content figure {
    overflow: scroll !important;
  }
  .event-right-content figure::-webkit-scrollbar {
    display: none !important;
  }
  .main-blog-desktop-top {
    display: none;
  }
}
.event-right-content figure {
  overflow: scroll !important;
}
.event-right-content figure::-webkit-scrollbar {
  display: none !important;
}
.clicked-category {
  color: #ce0808;
  line-height: 5px;
}
.unclicked {
  color: #868686;
  font-weight: 400 !important;
}
.category-header {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 12px;
  height: 25px;
}
.categories-box {
  display: flex;
  justify-content: space-around;
  height: 50px;
  align-items: center;
  margin-top: 10px;
}
.blog-title {
  font-family: "Mukta";
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  padding: 0px 10px;
  padding-top: 12px;
}
.blog-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  margin-top: 10px;
  padding: 0px 10px;
  overflow-wrap: break-word;
}
.blog-author-div {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d1cccc57;
  margin-top: 15px;
  margin-bottom: 15px;
}
.blog-author-img {
  border-radius: 50%;
}
.author-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}
.pink-small-dot {
  align-self: center;
  width: 3px;
  height: 3px;
  background-color: #d9368b;
  border-radius: 100%;
  margin-left: 5px;
  margin-right: 5px;
}
.blog-back-btn {
  top: 12rem;
  left: 9px;
  background-color: #0000007a;
  padding: 10px;
  border-radius: 100%;
}
.event-left {
  width: 5%;
}
.event-right {
  width: 85%;
}
.event-share {
  width: 5%;
  margin-right: 10px;
}
.show-more-btn {
  border: 1px solid #d9368b;
  border-radius: 5px;
  width: 136px;
  height: 36px;
  color: #d9368b;
  background-color: white;
}
.share-div {
  width: 87px;
  height: 20px;
  color: #868686;
  border-right: 1px solid #868686;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-self: center;
  font-size: 18px;
}
.share-div-desktop {
  width: 90px;
  height: 20px;
  color: #868686;
  border-right: 2px solid #868686a3;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-self: center;
  font-size: 20px;
}
.share-div:hover {
  color: #d9368b;
}
.end-div {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  border: 1px solid #cccbcb70;
  margin-top: 15px;
}
.main-blog-desktop {
  display: block;
  min-height: 90vh;
  justify-content: center;
  margin: auto;
  width: 92%;
  //   background-color: white;
  //   width: 80vw;
  // margin-top: 5rem;

  display: flex;
  gap: 1rem;
}
.title-desktop {
  color: #000;
  font-family: Mukta;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
}
.title-desktop h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  display: inline;
}
.blog-author-div-desktop {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.end-div-desktop {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 14px;
  padding-top: 10px;
  border-bottom: 1px solid #cccbcb;
}
.bottom-border {
  height: 3px;
  background-color: #ce0808;
  width: 70%;
}
@media screen and (min-width: 601px) {
  .share-div {
    width: 82px;
    height: 23px;
    color: #868686;
    border-right: 1px solid #868686;
    font-size: 18px;
  }
  .event-right {
    width: 100%;
    padding-right: 15px;
  }
  .events-desktop {
    display: block;
  }
}
.event-right-title {
  font-size: 22px;
  font-weight: 600;
  margin: 10px 0px;
  overflow-wrap: break-word;
}
.blog-title-desktop {
  margin-left: 25px;
  margin-right: 25px;
}
.blog-desc {
  margin-top: 15px;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .main-blog-desktop {
    display: none;
  }
}
.right-desktop-blog {
  // background-color: white;
  // min-height: 90vh;
  display: block;
  width: 30%;
  // width: 32rem;
}
@media screen and (max-width: 1000px) {
  .right-desktop-blog {
    display: none;
  }
}
.left-desktop-blog {
  background-color: white;
  padding-bottom: 5rem;
  border-radius: 1.5rem;
  border: 1px solid #dfdfdf;
  padding-top: 3rem;
  width: 75%;
}

.blog-list-box {
  max-height: 200px;
  height: 200px;
  overflow-y: scroll;
}
.blog-list-item {
  display: flex;
  gap: 1rem;
  margin-left: 1rem;
  border-top: 1px solid #1111;
}
.blog-list-header {
  display: flex !important;
}
.blog-list-element {
  flex: 0 0 65%;
  background-color: #ffffff;
  border-radius: 15px;
  padding-top: 10px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.48rem 0.48rem 1.2rem 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.no-value {
  // background-color: white;
  display: none;
}

.list-author-box {
  padding: 0px 10px;
}
.blogs-list-main::-webkit-scrollbar {
  display: none;
}
.no-value-desktop {
  display: block;
  padding-left: 7rem;
  padding-right: 8.3rem;
  padding-top: 10px;
  background-color: white;
  margin-top: 12px;
  min-height: 378px;
}
.blog-list-element-desktop {
  flex: 0 0 20%;
  background-color: #ffffff;
  border-radius: 15px;
  padding-top: 10px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.48rem 0.48rem 1.2rem 1.2rem;
  cursor: pointer;
}
.liveblog-breadcrumb {
  list-style: none;
  padding: 1rem 0 0 1rem;
  margin: 0 0 2rem;
  display: flex;
  align-items: center;
  margin-left: 2rem;
  margin-top: 1rem;
}
.liveblog-breadcrumb-mobile {
  display: flex;
  list-style: none;
  gap: 1.5rem;
  padding-top: 2rem;
}
.liveblog-breadcrumb-mobile li {
  border-right: 1px solid #0000008f;
  padding-right: 12px;
}
.liveblog-breadcrumb li {
  color: #999;
  position: relative;
  padding: 0 2rem;
}
.liveblog-breadcrumb li:after {
  content: "|";
  position: absolute;
  right: 0;
  top: 55%;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .no-value {
    display: block;
    padding-top: 5px;
    min-height: 325px;
    background-color: white;
  }
  .no-value-desktop {
    display: none;
  }
  // .liveblog-breadcrumb{
  //   display: none;
  // }
}
.main-blog-desktop-top {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1200px) and (min-width: 600px) {
  .left-desktop-blog {
    width: 90%;
  }
}

.blog-small {
  font-size: 16px !important;
}
.blog-normal h2 {
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}
.blog-description h2 {
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}
.blog-large {
  font-size: 24px !important;
}
.title-small {
  font-size: 16px;
}
.title-normal {
  font-size: 22px;
}
.title-large {
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .blog-small {
    font-size: 14px !important;
  }
  .blog-normal {
    font-size: 18px !important;
  }
  .blog-large {
    font-size: 22px !important;
  }
  .blog-small-title {
    font-size: 16px !important;
  }
  .blog-normal-title {
    font-size: 20px !important;
  }
  .blog-large-title {
    font-size: 24px !important;
  }
  .title-small {
    font-size: 16px;
  }
  .title-normal h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  .title-large {
    font-size: 24px;
  }
}
.event-right-content {
  overflow-wrap: break-word;
  font-size: 18px;
}
.event-right-content blockquote {
  margin: 0 0 1rem;
  border-left: 6px solid #d9368b;
  padding-left: 10px;
  font-family: fangsong;
  // font-size: 18px;
}

.post-img-caption-lb {
  text-align: center;
  color: #606060;
  font-style: italic;
  margin-top: 4px;
}
@media screen and (min-width: 1400px) {
  .right-desktop-blog {
    width: 42rem;
  }
}
