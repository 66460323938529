.main-quiz-div {
  width: 60vw;
  margin: auto;
  background-color: white;
  min-height: 70vh;
}
.quiz-error-div{
  width: 49vw;
  margin: auto;
  height: 80vh;
  background-color: white;
}

.quizError{
  text-align: center;  
  margin-top: 20%;
  margin-left: 2%;
  margin-right: 2%;
  color: #eb0e8c;
}
@media screen and (max-width:650px) {
  .quiz-error-div{
    width: 100vw;
    margin: auto;
    height: 80vh;
    background-color: white;
  }
  .quizError{
    text-align: center;  
    margin-top: 0%;
    padding-top: 20%;
    margin-left: 2%;
    margin-right: 2%;
    color: #eb0e8c;
  }
}
@media screen and (min-width:1200px) and (max-width: 1450px) {
  .main-quiz-div {
    width:70vw;
  }
}
@media screen and (min-width:800px) and (max-width: 1200px) {
  .main-quiz-div {
    width:75vw;
  }
}
@media screen and (min-width:1000px) and (max-width: 1450px) {
  .quizPage-desktop {
    width:85vw !important;
  }
}
@media screen and (min-width:800px) and (max-width: 1000px) {
  .quizPage-desktop {
    width:80vw;
  }
}
@media screen and (max-width: 570px) {
  .main-quiz-div {
    margin-bottom: 5%;
    width: 100%;
  }
}
.banner-things {
  display: flex;
  position: absolute;
  color: white;
  // left: 2rem;
  // padding: 15px;
  margin-top: 2rem;
}

.banner-buttons {
  // position: absolute;
  // right: 0;
  right: 25%;
  display: flex;
  gap: 2rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 3%;
}
.banner-things h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 3.6rem;
  line-height: 3rem;
}
@media screen and (max-width: 650px) {
  .banner-things {
    left: 1rem;
  }
  .banner-buttons {
    right: 2%;
  }
}
.quiz-header-first {
  display: flex;
  justify-content: space-between;
  // gap: 2rem;
  // margin-top: 2%;
}
.quiz-header-first-left {
  display: flex;
  gap: 1rem;
}
.quiz-header-first-right {
  display: flex;
  gap: 1rem;
}
.quiz-header-first-left img {
  width: 35px;
  height: 25px;
}
.quiz-header-first-right img {
  width: 120px;
  height: 32px;
}
// .flex2 {
//   margin-left: 4rem !important;
// }
.button1 {
  border-radius: 10px;
  border: 1px #eb0e8c solid;
  width: 22rem;
  height: 50px;
  background-color: white;
  color: #eb0e8c;
}
.button2 {
  border: 1px solid rgba(49, 49, 49, 0.28);
  border-radius: 10px;
  width: 22rem;
  height: 50px;
  background-color: white;
  color: black;
}
.quiz-header-second {
  display: flex;
  justify-content: space-evenly;
  gap: 0.8rem;
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.quiz-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  gap: 2rem;
}
@media screen and (max-width: 570px) {
  .quiz-header {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }
  .button1 {
    border-radius: 10px;
    border: 1px #eb0e8c solid;
    width: 16rem;
    height: 50px;
    background-color: white;
    color: #eb0e8c;
  }
  .button2 {
    border-radius: 10px;
    border: 1px solid rgba(49, 49, 49, 0.28);
    width: 16rem;
    height: 50px;
    background-color: white;
    color: black;
  }
  .quiz-header-first-left img {
    width: 30px;
    height: 25px;
  }
  .quiz-header-first-right img {
    width: 27vw;
    height: 28px;
  }
  .quiz-header-first {
    justify-content: space-evenly;
  }
}

.quizCard {
  width: 70%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2.07519px 4.15038px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

@media screen and (max-width: 570px) {
  .quizCard {
    width: 95vw;
    height: auto;
  }
}
@media screen and (min-width: 571px) and (max-width: 1100px) {
  .quizCard {
    width: 93%;
    height: 39rem;
  }
}
.quizImageCard {
  position: relative;
  min-height: 200px;
  background: linear-gradient(360deg, transparent, #000) !important;
  border-radius: 10px;
}
.quizImageCard img {
  background: linear-gradient(360deg, transparent, #000) !important;
  border-radius: 5px;
}
.lastImage img {
  z-index: -1;
}
.lastImage {
  width: 100%;
  height: 13%;
  z-index: 9999;
  padding: 0 1.6rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}
.quizCardUpper {
  position: relative;
}
.quizCardUpperText {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  color: white;
  width: 100%;
  padding-bottom: 10px;
  background: linear-gradient(180deg, transparent, #070707f9) !important;
  text-align: center;
  // display: flex;
}
.childFlex {
  display: inline-block;
  width: calc(100% / 3);
  padding-top: 1.5rem;
}
.quizHeading {
  width: 100px;
  height: auto;
  left: 13.66px;
  top: 231.07px;
  font-family: "Mukta",system-ui, -apple-system, BlinkMacSystemFont;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
}
@media screen {
  .quizHeading {
    width: 100%;
  }
}
.quizCardLower {
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
}
.quizKhele {
  width: auto;
  height: 5rem;
  background: #eb0e8c;
  border-radius: 5.18797px;
  color: white;
  border: none;
  font-weight: 600;
}
.quizKheleDone {
  width: 145.97px;
  height: 43.59px;
  background: white;
  border: 1px solid #eb0e8c !important;
  border-radius: 5.18797px;
  color: #eb0e8c;
  border: none;
}
.quizDone {
  background: white;
  border: 1px solid #eb0e8c !important;
  border-radius: 5.18797px;
  color: #eb0e8c;
}
.quizCardTime {
  font-size: 14px;
  line-height: 10px;
  margin-top: 2%;
  font-weight: 500;
  text-align: end;
}
.startedTime {
  font-style: normal;
  font-weight: 300;
  font-size: 12.4511px;
  line-height: 145.2%;
  /* or 18px */

  text-align: right;

  color: #000000;

  opacity: 0.7;
}
.top50 {
  font-style: normal;
  font-weight: 300;
  font-size: 12.4511px;
  line-height: 145.2%;
  /* or 18px */
  padding-right: 15px;
  padding-left: 3px;
  color: #d9368b;
}
.ListOfCards {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: -4rem;
  background-color: white;
  align-items: center;
}
@media screen and (max-width: 570px) {
  .quiz-header-second {
    width: 98%;
  }
  .ListOfCards {
    margin-top: 1rem;
  }
}
.quiz-text {
  width: 50px !important;
}
// Quiz Page

.quizPage {
  // height: 100vh;
  width: 50vw;
  min-height: 120vh;
  justify-content: center;
  margin: auto;
  padding-bottom: 5%;
  background-color: white;
}
.quizPage-desktop{
  width: 85vw;
  margin: auto;
  padding-bottom: 5%;
  min-height: 82vh;
  padding-left: 1%;
  padding-top: 1%;
  padding-bottom: 14%;
  display: flex;
  background-color: white;
}
@media screen and (min-width:1000px) and (max-width: 1450px) {
  .quizPage-desktop {
    width:95vw !important;
  }
}
@media screen and (min-width:800px) and (max-width: 1000px) {
  .quizPage-desktop {
    width:85vw;
  }
}
.quizPageQuestionsHeader {
  height: 6rem;
}
.quizPage-h1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 111.2%;
  color: #999999;
  display: flex;
  height: 1.9rem;
  justify-content: space-between;
}
.quizPage-h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 111.2%;
  color: #000000;
  display: flex;
  justify-content: space-between;
}
.quizPageSecond {
  margin: 2%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.quizPageSecond-desktop{
  display: flex;
  margin-left: 2%;
  margin-right: 2%;
  flex-direction: column;
  gap: 2rem;
}
.questionCircle {
  color: #eb0e8c;
  display: inline-block;
  width: 3.5rem;
  height: 30px;
  text-align: center;
  margin-right: 2rem;
  font-size: 2rem;
  border-radius: 100%;
  border: 1px solid #eb0e8c;
}
.filledQuestion {
  background-color: #d9368b;
  color: white;
}
.questionsDiv {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: space-evenly;
}
.questionsDiv::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 800px) {
  .questionsDiv {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    padding: 1px;
    display: block;
    // justify-content: space-evenly;
  }
}
.quizOptions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quizOptions-desktop{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.Option {
  background: #ffffff;
  border: 0.5px solid #5ba9bb;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145.2%;
  color: #000000;
  height: 40px;
  padding: 10px 10px;
  align-content: center;
  cursor: pointer;
}
.Option-desktop{
  width: 47%;
}
.Option-green{
  background: #61C777  ;
  // border: 0.5px solid #5ba9bb;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145.2%;
  color: white;
  height: 50px;
  padding: 10px 10px;
  align-content: center;
  cursor: pointer;
}
.Option-Red{
  background: #EF4848  ;
  // border: 0.5px solid #5ba9bb;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145.2%;
  color: white;
  height: 50px;
  padding: 10px 10px;
  align-content: center;
  cursor: pointer;
}
.Option2 {
  background-color: #d7f0f2;
  border: 0.5px solid #5ba9bb;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145.2%;
  color: #000000;
  cursor: pointer;
  height: 50px;
  padding: 10px 10px;
  align-content: center;
}
@media screen and (max-width: 570px) {
  .quizPage {
    width: 100vw;
    min-height: 100vh;
  }
  .questionCircle {
    padding-top: 1%;
    width: 4rem;
    height: 4rem;
  }
}

//leaderboard

.leaderboard-main {
  width: 50vw;
  background-color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  padding-bottom: 5%;
  min-height: 85vh;

}
.leaderboard-menu {
  // display: flex;
  // justify-content: center;
  text-align: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.leaderboard-list {
  width: 95%;
  // margin-top: 2rem !important;
  margin-left: auto;
  margin-right: auto;
  // overflow-y: auto;
  // height: 51vh;
}
.leaderboard-box {
  width: 10rem;
  border: 0.376923px solid #c4c4c4;
  height: 5rem;
  float: left;
  width: 50%;
  padding-top: 1%;
  font-size: 1.7rem;
}
.leaderboard-box-choose {
  width: 10rem;
  background: #6fcbd0;
  color: white;
  float: left;
  width: 50%;
  border: 0.376923px solid #c4c4c4;
  height: 5rem;
  padding-top: 1%;
  font-size: 1.7rem;
}
.border-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.border-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.leaderboard-user {
  height: 70px;
  border: 0.389463px solid #c4c4c4;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 3%;
  padding-right: 3%;
}
.leaderboard-user h6 {
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 145.2%;
  height: 15px;
}
.dot {
  width: 3px;
  height: 3px;
  left: 156px;
  text-align: center;
  margin-top: 12px;
  // top: 43.5px;
  background: #868686;
  border-radius: 1px;
}

.username {
  margin-top: auto;
  margin-bottom: auto;
}
.leaderboard-user-leftside {
  display: flex;
  gap: 2rem;
  margin-top: auto;
  margin-bottom: auto;
}
.leaderboard-user-leftside p {
  margin-top: auto;
  margin-bottom: auto;
}
.trophy {
  margin-top: auto;
  margin-bottom: auto;
}
.btl {
  border-top-left-radius: 10px;
}
.btr {
  border-top-right-radius: 10px;
}
.bbl {
  border-bottom-left-radius: 10px;
}
.bbr {
  border-bottom-right-radius: 10px;
}
.current-user {
  background-color: #016369;
  color: white;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.first {
  background: rgba(243, 205, 78, 0.5);
}
.second {
  background: rgba(243, 205, 78, 0.25);
}
.third {
  background: rgba(243, 205, 78, 0.1);
}
.dot-white {
  background: #f5f3f3 !important;
  width: 3px;
  height: 3px;
  left: 156px;
  text-align: center;
  margin-top: 12px;
  // top: 43.5px;
  border-radius: 1px;
}
.claim button {
  width: 78px;
  height: 25px;
  color: white;
  background: #f58324;
  border-radius: 2px;
  margin-top: 25%;
  border: none;
}

@media screen and (max-width: 570px) {
  .leaderboard-main {
    width: 98vw;
    padding-top: 2rem;
    min-height: 85vh !important;
  }
  .leaderboard-list{
    height: 100% !important;
    margin-bottom: 20%;
  }
}

//rewards

.rewards-main {
  width: 55vw;
  background-color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 5%;
  height: auto;
}
.rewards-card {
  width: 60%;
}
.rewards-card-header {
  width: 100%;
  height: 66px;
  left: 16.5px;
  top: 15px;
  background: #016369;
  padding-left: 4%;
  margin: auto;
  padding-top: 1%;
}
.rewards-card-header {
  display: flex;
  gap: 1rem;
}
.reward-card-pts {
  display: flex;
  color: white;
  font-size: 16px;
  line-height: 26px;
  gap: 1rem;
  font-weight: 400;
}
.reward-username {
  color: white;
  font-weight: 600;
  font-size: 22px;
  line-height: 145.2%;
}
.reward-box2 {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 100px) {
  .rewards-card-bottom {
    background: #d7f0f2;
  }
  .reward-amount {
    color: black;
    border-bottom: 1px dashed rgba(1, 99, 105, 0.4);
    padding-left: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .rewards-card {
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
  }
  .winning-amount {
    display: flex;
    gap: 1rem;
  }
  .amount {
    font-weight: 600;
    font-size: 36px;
    line-height: 25px;
  }
  .withdrawn-amount {
    font-weight: 400;
    font-size: 16px;
    line-height: 145.2%;
    margin-top: 2%;
  }
  .claim-reward button {
    width: 78px;
    height: 25px;
    color: white;
    background: #f58324;
    border-radius: 2px;
    border: none;
  }
  .reward-claim-section {
    display: flex;
    padding: 5%;
    justify-content: space-between;
  }
  .netbalance {
    font-weight: 600;
    font-size: 16px;
    line-height: 145.2%;
    align-items: center;
    color: #61c777;
  }
  .your-transactions {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #d9368b;
    margin-top: 3%;
    margin-bottom: 3%;
    padding-left: 1%;
  }
  .status {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    color: #000000;
  }
  .transaction-date {
    display: flex;
    gap: 0.7rem;
  }
  .credited-amount {
    font-weight: 600;
    font-size: 22px;
    line-height: 145.2%;
    align-items: center;
    text-align: right;
    color: #61c777;
    height: 3rem;
  }
  .debited-amount{
    font-weight: 600;
    font-size: 22px;
    line-height: 145.2%;
    align-items: center;
    text-align: right;
    color: red;
    height: 3rem;
  }
  .current-status {
    font-weight: 500;
    font-size: 14px;
    line-height: 145.2%;
    color: #000000;
    opacity: 0.7;
  }
  .transaction {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.05);
    padding-top: 2%;
    padding-bottom: 4%;
    padding-left: 1%;
    padding-right: 1%;
  }
  .modal-claim-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 145.2%;
    color: #000000;
    opacity: 0.7;
  }
  .modal-claim-header {
    display: flex;
    justify-content: space-between;
    padding: 4% 4% 0% 4%;
  }
  .modal-confirm-text {
    font-weight: 300;
    font-size: 18px;
    line-height: 145.2%;
    /* or 26px */

    color: #000000;

    opacity: 0.7;
  }
  .modal-mobile-input {
    width: auto;
    height: 61px;
    left: 25px;
    top: 174px;
    font-weight: 500;
    font-size: 20px;
    line-height: 145.2%;
    opacity: 0.7;
    background: #ffffff;
    border: 1.5px solid #eb0e8c;
    border-radius: 10px;
  }
  .modal-mobile-submit {
    width: auto;
    height: 45px;
    left: 25.06px;
    top: 247px;
    color: white;
    background: #eb0e8c;
    border-radius: 10px;
    border: none;
  }
  .modal-mobile-submit-off {
    width: auto;
    height: 45px;
    left: 25.06px;
    top: 247px;
    color: white;
    background:#868686;
    border-radius: 10px;
    border: none;
  }
  .modal-use-email {
    font-weight: 500;
    font-size: 20px;
    line-height: 145.2%;
    /* identical to box height, or 29px */

    text-align: center;

    color: #eb0e8c;
  }
  .modal-congrats {
    font-weight: 600;
    font-size: 22px;
    line-height: 145.2%;
    align-items: center;

    color: #eb0e8c;
  }
}
@media screen and (max-width: 600px) {
  .rewards-main {
    width: 100vw;
    padding: 1rem 1rem 2rem 1rem;
    min-height: 70vh;
  }
}
// .video-js .vjs-tech{
//  width: auto !important;
//  height: auto !important;
// }
// #vjs_video_3_html5_api {
//   width: 100% !important;
//   height: auto !important;
// }
// .vjs_video_3-dimensions {
//   width: 100% !important;
//   height: 100px !important;
// }
.quizPageBelow {
  // margin-top: 42%;
}
.quizPageBelow-desktop{
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}
.quizPageSpeaker {
  right: 10px;
  bottom: 5px;
}
.quizPageBack {
  left: 5px;
  top: 10px;
  padding-top: 3px;
}
// @media screen and (min-width:570px) and (max-width: 1200px) {
//   .quizPageSpeaker{
//     bottom:-22vh
//   }
// }
// @media screen and (max-width: 570px) {
//   .quizPageBelow{
//     margin-top: 33%;
//   }
// }
// .video-js {
//   height: 50% !important;
//   background-color: white !important;
// }
.loading-leaderboad {
  height: auto;
}
.loading-leaderboad {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
}
.loading-leaderboard-image{
 width: 100% !important;
}
@media screen and (max-width: 650px) {
  .loading-leaderboad {
    height: auto;
  }
  .loading-leaderboad {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 10%;
    width: auto;
  }
}

// #vjs_video_3_html5_api {
//   height: auto !important;
// }
.slick-slide {
  margin: 10px;
}
.left-div-quiz {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.quiz-desktop-banner img {
  height: 100%;
  width: 100%;
}
.left-div-buttons {
  width: 19rem;
  border-radius: 10px;
  border: 1px #080808 solid;
  height: 40px;
  background-color: white;
  color: #eb0e8c;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 100.9%;
  /* or 16px */
  text-transform: uppercase;
  color: #000000;
  text-align: left;
}
.left-div-buttons-active {
  border: 2px #eb0e8c solid;
  color:#eb0e8c;
}
.right-div-quiz {
  display: flex;
  flex-direction: column;
}
.desktop-quiz-page {
  display: flex;
  // justify-content: space-evenly;
  gap: 2rem;
  margin-top: 0.5rem;
  padding: 10px;
  width: 100%;
}
.quizCard-desktop {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2.07519px 4.15038px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  height: auto;
  // padding-left: 10px;
  // padding-top: 10px;
}
.quizCardUpperText-desktop {
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%
}
.childFlex-desktop {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 145.2%;
  /* identical to box height, or 21px */
  color: #eb0e8c;
}
.childFlex-desktop img{
  padding-bottom: 2px;
}
.quizHeading-desktop {
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  color: #000000;
  margin-bottom: 1rem;
}
.quizCardTime-desktop {
  font-size: 1.2rem;
  line-height: 10px;
  margin-top: 15%;
  text-align: end;
  height: 5px;
}
.startedTime-desktop {
  font-style: normal;
  font-weight: 300;
  font-size: 12.4511px;
  line-height: 145.2%;
  /* or 18px */

  text-align: right;

  color: #000000;

  opacity: 0.7;
}
.quizKhele-desktop {
  width: 100px;
  height: 43.59px;
  background: #eb0e8c;
  border-radius: 5.18797px;
  color: white;
  border: none;
}

.quizCardLower-desktop {
  // margin-left: 6%;
  width: 96%;
  padding-left: 3%;
}
.right-div-quiz-desktop {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  padding: 12px;
}
.desktop-quizCard-image {
  width: 100% !important;
  aspect-ratio: 16/9 !important;
  border-radius: 10px;
}
.desktop-quizCard-img-div {
  width: 68%;
}
.rightQuizCard-desktop {
  width: 72%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quizPagerightQuizCard-desktop{
  margin-top: 28%;
  padding-top: 2%;
  position: absolute;
  height: 7rem;
  display: block;
  background: #F1F2FA;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  bottom: -7rem;
  width: 100%;
}
@media screen and (min-width: 1000px) and (max-width: 1232px) {
  .childFlex-desktop {
    font-size: 1.3rem;
  }
  .desktop-quizCard-img-div {
    width: 50%;
  }
  .desktop-quizCard-image {
    height: 98%;
  }
  .quizHeading-desktop {
    font-size: 1.4rem;
  }
  .quizKhele-desktop {
    width: 8rem;
    height: 4rem;
    font-size: 1.2rem;
  }
  .quizKheleDone {
    width: 8rem;
    height: 4rem;
    font-size: 1.2rem;
    background: white;
    border: 1px solid #eb0e8c !important;
    border-radius: 5.18797px;
    color: #eb0e8c;
    border: none;
  }
  .quizCardTime-desktop {
    font-size: 1rem;
  }
  .startedTime-desktop {
    font-size: 1rem;
  }
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
  .desktop-quizCard-img-div {
    width: 50%;
  }
  .rightQuizCard-desktop {
    width: 72%;
  }
  .quizCardUpperText-desktop {
    padding-right: 4px;
  }
  .childFlex-desktop {
    font-size: 1.3rem;
  }
  .quizHeading-desktop {
    font-size: 1.3rem;
  }
  .quizKhele-desktop {
    width: 7rem;
    height: 4rem;
    font-size: 1rem;
  }
  .quizKheleDone {
    width: 7rem;
    height: 4rem;
    font-size: 1rem;
  }
  .quizCardTime-desktop {
    font-size: 1rem;
    line-height: 4px;
  }
  .startedTime-desktop {
    font-size: 1.2rem;
  }
  .top50 {
    font-size: 1rem;
    line-height: 0;
  }
  .left-div-buttons {
    width: 15rem;
    font-size: 0.9rem;
  }
  .main-quiz-div {
    width: 87vw;
  }
}
@media screen and (min-width: 650px) and (max-width: 800px) {
  .desktop-quizCard-img-div {
    width: 80%;
  }
  .rightQuizCard-desktop {
    width: 72%;
  }
  .quizCardUpperText-desktop {
    padding-right: 4px;
  }
  .childFlex-desktop {
    font-size: 1.1rem;
  }
  .quizHeading-desktop {
    font-size: 1.3rem;
  }
  .quizKhele-desktop {
    width: 7rem;
    height: 4rem;
    font-size: 1rem;
  }
  .quizKheleDone {
    width: 7rem;
    height: 4rem;
    font-size: 1rem;
  }
  .quizCardTime-desktop {
    font-size: 1rem;
    line-height: 4px;
  }
  .startedTime-desktop {
    font-size: 1.2rem;
  }
  .top50 {
    font-size: 1rem;
    line-height: 0;
  }
  .left-div-buttons {
    width: 15rem;
    font-size: 0.9rem;
  }
  .main-quiz-div {
    width: 90vw;
  }
}

@media screen and (min-width: 1000px) {
  .questionCircle {
    height: 36px;
    padding-top: 3px;
  }
}
.video-js.vjs-playing .vjs-tech {
  pointer-events: none;
}
.previousQuizLeaderboard {
  font-weight: 700;
  font-size: 12.4511px;
  line-height: 145.2%;
  /* identical to box height, or 18px */
  text-align: right;
  color: #3aaab1;
  cursor: pointer;
}
@media screen and (max-width:600px) {
  .previousQuizLeaderboard{
    margin-bottom: 5px;
  }
}

//quiz header
.quiz-header-fixed{
  width: 100vw;
  height: 8vh;
  display: flex;
  background-color: #d9368b;
}
.quiz-head-section{
  display: flex;
  gap: 2rem;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 4%;
}
.pageName-header{
  font-style: normal;
font-weight: 600;
font-size: 2.7rem;
line-height: 32px;

color: #FFFFFF;
}
.quiz-header-logo{
  // padding-top: 2px;
}

//View quiz page
.viewQuizPage {
  width: 95vw;
  background-color: white;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 5%;
  height: auto;
}
@media screen and (min-width:650px) {
  .viewQuizPage{
    width: 50vw;
    margin: auto;
  }
}
.viewQuizImageCard{
  width: 100%;
}
.viewPageHeading{
  font-family: 'Hind';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 130%;
color: #000000;
padding-left: 2%;
}
.viewPageSummary{
  font-family: 'Hind';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 145.2%;
/* or 20px */
color: #464444;
padding-left: 2%;
opacity: 0.7;
padding-right: 2%;
}
.viewPageQuestionBox{
  background: #FFFFFF;
border: 0.3px solid #C4C4C4;
border-radius: 6px;
border-bottom: 5px solid #EB0E8C;
margin-left: 2%;
margin-right: 2%;
}
.viewPageQuestionBoxChild{
  display: flex;
  justify-content: space-between;
  padding: 7px;
}
.viewPageQuestionsHeading{
  font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 32px;
padding-left: 2%;
color: #000000;
}
.viewPageAnswerBox{
background: #FFFFFF;
border: 0.3px solid #C4C4C4;
border-radius: 6px;
height: 10rem;
padding-left: 10px;
padding-top: 20px;
margin-top: 15px;
margin-bottom: 20px;
display: flex;
flex-direction: column;
gap: 1rem;
}
.viewPageAnswersBox{
  padding-left: 2%;
  padding-right: 2%;
  }
.faq-video{
  padding-top: 13px;
  padding-bottom: 13px; 
  padding-left: 8px;
  padding-right: 8px; 
}
.modal-thanking{
  padding-left: 4%;
  padding-bottom: 4%;
}
.quizCardTimeBox{
  padding: 6px;
}
.coming-soon-button {
  background-color: #9b999964 !important;
  border-color: #9b999964 !important;
  color: #3f413fb0;
  // text-align: center;
}
.coming-soon-button-mobile {
  background-color: #9b999964 !important;
  border-color: #9b999964 !important;
  color: #3f413fb0;
  // text-align: center;
}
.coming-soon-button-mobile:hover {

}
.coming-soon-button #coming-soon{
  display: none;
}
.coming-soon-button:hover{
  background-color: white !important;
  color: #000;
}
.coming-soon-button:hover #live-quiz{
  display: none;
}
.coming-soon-button:hover #coming-soon{
  display: inline;
}
.head-section{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2%;
}
.content-wrapper-faq{
  width: 65vw;
  background-color: white;
  margin: auto;
}
@media screen and (max-width: 650px) {
  .content-wrapper-faq{
    width: 100vw;
  }
}
.mobile-quiz-banner span{
  width: 100vw !important;
  // height: 100% !important;
  aspect-ratio: 16/9 !important;
  position: relative !important;
}
.videojs .vjs-tech {
 position:  relative !important;
}
.quizPageModal{
  width: 70%;
  margin: auto !important;
}