.column-card {
  background-color: $white;
  border-radius: 1.5rem;
  padding: 1.8rem;
  position: relative;
  .column-top {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 1.8rem;
    @extend .d-flex, .align-items-center, .justify-content-between;
    .separate-phrases {
      color: $skyblue-light-100;
    }
    .column-options {
      font-size: 1.8rem;
      margin-right: -0.5rem;
      .more-col {
        .option-dropdown {
          i {
            cursor: pointer;
          }
        }
      }
    }
  }
  .column-body {
    text-align: center;
    margin-bottom: 1.6rem;
    .card-image-box {
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      border: 0.2rem solid $skyblue-light-100;
      padding: 0.6rem;
      margin: 0 auto 1.2rem;
      .card-image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        @include img-box(56.25%);
      }
    }
    .column-card-content {
      margin: 0 11%;
      .column-title {
        color: $skyblue-light-100;
        font-weight: 600;
        margin-bottom: 0.8rem;
        font-size: 1.6rem;
        a {
          color: $skyblue-light-100;
          &:hover {
            color: $primary;
          }
        }
      }
      .column-text {
        font-size: 1.4rem;
        color: $black;
        line-height: 2.4rem;
        min-height: 7.3rem;
      }
    }
  }
  .column-bottom {
    .column-comment-views {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $dark-gray-400;
      font-size: 1.4rem;
      .comments-counts {
        display: flex;
        align-items: center;
        i {
          font-size: 2rem;
          margin-right: 0.6rem;
        }
      }
      .column-options {
        p {
          margin: 0;
        }
      }
    }
  }
}