.dropbtn {
  font-size: 20px;
  border: none;
  color: #d9368b;
  cursor: pointer;
  margin-right: 3rem;
}

@media screen and (max-width: 768px) {
  .dropbtn {
    margin-right: 1.6rem !important;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  font-size: 12px;
  position: absolute;
  background-color: white;
  //min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 2px 8px;
  font-size: 14px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  border: none;
}
