.card-wrapper {
  .card-left {
    .columnist-detail-head {
      padding: 2rem 3.6rem;
      margin: 0rem 1.5rem;
      @include media-breakpoint-down(md) {
        padding: 2rem 1.6rem;
        margin-top: 2rem;
      }
    }
  }
}
.columnist-wrap {
  display: flex;
  // margin-bottom: 3rem;
  @include media-breakpoint-down(md) {
    // padding: 3rem;
    flex-wrap: wrap;
  }
  .columnist-left {
    min-width: 15%;
  }
  .colunist-right {
    margin-top: 12px;
  }
  .columnist-image-wrap {
    padding: 4px;
    margin: 0 auto;
    border: 1px solid #d9368b;
    max-width: 114px;
    border-radius: 50%;
    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
    .columnist-image {
      border-radius: 50%;
      @include img-box(100%);
    }
  }
  .columnist-info {
    // margin-left: 2.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    @include media-breakpoint-down(md) {
      width: 100%;
      text-align: center;
      margin-top: 1.5rem;
      margin-left: 0;
    }
    .columnist-name {
      @include media-breakpoint-down(md) {
        // flex-direction: column;
        // align-items: center;
        justify-content: center;
      }

      h3 {
        margin: 0;
        font-size: 24px;
        line-height: 20px !important;
        color: black;
        @include media-breakpoint-down(md) {
          order: 2;
        }
      }
    }
    .social-link {
      @include media-breakpoint-down(md) {
        order: 1;
        margin-bottom: 1.5rem;
      }
    }
    p {
      font-size: 14px;
      line-height: 3rem;
      color: $white;
      margin-bottom: 0;
      color: black;
    }
  }
}
.detail-box {
  background: #ffffff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 1.5rem;
  // padding: 3rem;
  margin: 0 0 3rem;

  .detail-box-title {
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 2.6rem;
    color: #f58324;
    margin: 0 0 02rem;
  }
}
.author-share-icon {
  height: 24px;
  width: 24px;
}
.share-button-panel {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
@media screen and (max-width: 499px) {
  .columnist-left {
    margin-left: auto;
    margin-right: auto;
  }
  .share-button-panel {
    margin-top: 5px;
  }
}

.electionYatra-outer {
  height: auto;
  background-color: #016369;
  padding: 15px 25px;
}
.chunav-yatra-small-title {
  font-size: 18px;
  line-height: 25px;
}

.electionYatra-outer-home {
  height: auto;
  background-color: #016369;
  padding: 15px 15px;
  // width: 90vw;
  margin: 15px auto;
  border-radius: 15px;
  max-width: 1112px;
  @media (min-width: 1400px) {
    width: 88%;
    max-width: 1270px;
  }

  @media (max-width: 700px) {
    width: 88%;
  }
  @media (max-width: 1025px) and (min-width: 768px) {
    // margin: 0 10px !important;
  }
}

.electionYatra-header {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
  width: 100%;
}
.electionYatra-columns {
  display: flex;
  gap: 3rem;
  width: 100%;
  justify-content: space-between;
}
.electionYatra-first {
  // flex:  0 0 40%;
  margin-bottom: 15px;
}
.electionYatra-second {
  flex: 0 0 70%;
  // padding-left: 20px;
}
.electionYatra-first-div {
  display: flex;
}
.electionYatra-first-div-right {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding-left: 10px;
}
.electionYatra-second-bottom {
  color: #ffffff;
}
.election-desktop {
  display: block;
}
.election-mobile {
  display: none;
}
.election-aurDekhe {
  cursor: pointer;
}
.election-aurDekhe span {
  font-size: 20px;
  margin-right: 0;
}

//article Page one

.electionYatra-outer-article {
  height: auto;
  background-color: #016369;
  padding: 15px 25px;
}
.chunav-yatra-small-title-article {
  font-size: 20px;
  line-height: 25px;
}

.electionYatra-outer-home-article {
  height: auto;
  background-color: #016369;
  padding: 15px 25px;
  width: 88%;
  margin: 15px auto;
  border-radius: 15px;
}
.electionYatra-header-article {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
}
.electionYatra-columns-article {
  display: flex;
}
.electionYatra-first-article {
  // flex:  0 0 40%;
  margin-bottom: 15px;
}
.electionYatra-second-article {
  flex: 0 0 70%;
  padding-left: 20px;
}
.electionYatra-first-div-article {
  display: flex;
}
.electionYatra-first-div-right-article {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding-left: 10px;
}
.electionYatra-second-bottom-article {
  color: #ffffff;
}
.election-desktop-article {
  display: block;
}
.election-mobile-article {
  display: flex;
}
.election-aurDekhe-article {
  cursor: pointer;
}

.electionYatra-outer-article {
  display: block;
  padding: 10px 10px;
}
.electionYatra-outer-home-article {
  display: block;
  padding: 10px 10px;
  margin: 5px auto;
  width: 100%;
}
.chunav-yatra-small-title-article {
  font-size: 17px;
  line-height: 25px;
}
.electionYatra-columns-article {
  display: block;
}
.election-aurDekhe-article {
  display: none;
  cursor: pointer;
}
.election-mobile-article {
  display: block;
}
.electionYatra-second-article {
  padding: 0;
}
.election-desktop-article {
  display: none;
}
.electionYatra-second-bottom-article h2 {
  font-size: 18px !important;
}

.electionYatra-second-bottom h2 {
  font-size: 22px !important;
  margin-top: 10px;
  line-height: 32px;
}

@media screen and (max-width: 600px) {
  .electionYatra-outer {
    display: block;
    padding: 10px 10px;
  }
  .electionYatra-outer-home {
    display: block;
    padding: 10px 10px;
    margin: 5px auto;
    width: 95%;
  }
  .chunav-yatra-small-title {
    font-size: 17px;
    line-height: 25px;
  }
  .electionYatra-columns {
    display: block;
  }
  .election-aurDekhe {
    // display: none;
    cursor: pointer;
  }
  .election-mobile {
    display: block;
  }
  .electionYatra-second-top img {
    width: 100%;
    aspect-ratio: 16/9;
  }
  .electionYatra-second {
    padding: 0;
  }
  .election-desktop {
    display: none;
  }
  .electionYatra-second-bottom h2 {
    font-size: 18px !important;
    line-height: 28px;
  }
}
