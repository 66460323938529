.Tabs{
    background-color: white;
    padding: 1rem 1rem;
    @media (max-width: 769px) {
     padding: 1rem 0;
    }
    border-radius: 1.5rem;
    border: 1px solid rgb(223, 223, 223);
    @media (max-width: 768px){
        border: 0;
    }
}

ul.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
ul.nav li {
    width: 50%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    color:grey
  }

ul.nav li.active {
    color: #D9368B;
    font-weight: 600;
  }




.firstTab{
    .firstTabIndicator{
        width: 50%;
        height: 0.6rem;
        border-radius: 0.3rem;
        background-color: #D9368B;
        margin-bottom: 1.5rem;
    }

    iframe{
        min-height: 250px;
        width: 100%;
        height: 400px;
        @media screen and (max-width: 1000px) {
        height: 350px;
        }
        @media screen and (max-width: 820px) {
        height: 350px;
        }
        @media screen and (max-width: 620px) {
        height: 300px;
        }
        @media screen and (max-width: 500px) {
        height: 250px !important;
        }
    }
}

.secondTab{
    .secondTabIndicator{
        width: 50%;
        height: 0.6rem;
        border-radius: 0.3rem;
        background-color: #D9368B;
        margin-bottom: 1.5rem;
        margin-left: auto;
    }

    iframe{
        min-height: 250px;
        width: 100%;
        height: 400px;
        @media screen and (max-width: 1000px) {
        height: 350px;
        }
        @media screen and (max-width: 820px) {
        height: 350px;
        }
        @media screen and (max-width: 620px) {
        height: 300px;
        }
        @media screen and (max-width: 500px) {
        height: 250px !important;
        }
    }
}
