@font-face {
  font-family: 'icomoon';
  src:  url('../../../public/fonts/icomoon.eot?3gaag1');
  src:  url('../../../public/fonts/icomoon.eot?3gaag1#iefix') format('embedded-opentype'),
    url('../../../public/fonts/icomoon.ttf?3gaag1') format('truetype'),
    url('../../../public/fonts/icomoon.woff?3gaag1') format('woff');
    // url('../fonts/icomoon.svg?3gaag1#icomoon') format('svg');
  // font-weight: normal;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  // font-weight: normal;
  font-weight: 300;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-display: swap;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons:(
  rewards-new:"\e972",
  prev-quiz:"\e971",
  book:"\e96a",
  search-check:"\e96b",
  gender-female:"\e96c",
  smile-face:"\e96d",
  hexagone:"\e96e",
  fire:"\e96f",
  storeis:"\e970",
  vidos:"\e963",
  b-read:"\e964",
  shows:"\e965",
  email-fill:"\e966",
  shows-fill:"\e967",
  b-read-fill:"\e968",
  videos-fill:"\e969",
  quiz-fill:"\e962",
  home-fill:"\e95b",
  call-circle:"\e95d",
  twitter-circle:"\e95e",
  facebook-circle:"\e95f",
  insta-circle:"\e960",
  email-circle:"\e961",
  crown:"\e959",
  close-fill:"\e95a",
  trophy:"\e958",
  play-loop:"\e949",
  pol:"\e94a",
  stories:"\e94b",
  quiz:"\e94c",
  series:"\e94d",
  playlist:"\e94e",
  check-cricle:"\e94f",
  alarm-clock:"\e950",
  download:"\e951",
  calendar:"\e952",
  leaderboard:"\e953",
  sound-off:"\e954",
  sound-on:"\e955",
  bookmark-fill:"\e956",
  trash:"\e957",
  backward-speed:"\e92d",
  forward-speed:"\e93a",
  user:"\e93b",
  audio-prev:"\e93c",
  audio-next:"\e93d",
  pause:"\e93e",
  play:"\e93f",
  check-fill:"\e940",
  question:"\e941",
  arrow-next:"\e942",
  arrow-prev:"\e943",
  rewards:"\e944",
  twitter-fill:"\e945",
  whatsapp-fill:"\e946",
  facebook-fill:"\e947",
  arrow-left:"\e948",
  share-one: "\e934",
  bookmark: "\e935",
  close: "\e936",
  comment: "\e937",
  heart-fill: "\e938",
  heart: "\e939",
  briefcase: "\e900",
  football: "\e901",
  globe: "\e902",
  like: "\e903",
  medicine-box: "\e904",
  phone: "\e905",
  plane: "\e906",
  smiley: "\e907",
  stage: "\e908",
  dubble-dots:"\e92a",
  play-circle:"\e915",
  time:"\e928",
  cinema:"\e929",
  envelope:"\e916",
  location:"\e917",
  contact:"\e918",
  info:"\e919",
  feedback:"\e91a",
  share:"\e91b",
  line-star:"\e91c",
  clear-cache:"\e91d",
  prefrences:"\e91e",
  travel:"\e91f",
  gallery-cam:"\e920",
  column:"\e921",
  cat-listen:"\e922",
  cat-video:"\e923",
  cat-read:"\e924",
  cat-home:"\e925",
  gallery:"\e926",
  back-arrow:"\e927",
  chat-dots:"\e910",
  eye:"\e911",
  file:"\e912",
  mic:"\e913",
  three-dots:"\e914",
  news:"\e90f",
  clock:"\e90c",
  nav-btn:"\e90d",
  notification:"\e90e",
  arrow-right:"\e90a",
  search:"\e90b",
  character: "\e909",
  camera: "\e92b",
  listen: "\e92c",
  read: "\e92e",
  home: "\e92f",
  message: "\e930",
  instagram: "\e931",
  twitter: "\e932",
  facebook: "\e933",
);
@each $name, $icon in $icons{
  .icon-#{$name}:before{
    content: $icon;
  }
}
.icon-eye:before {
  padding-right: 0.5rem;
}
.icon-cat-video:before {
  font-size: 60%;

  @include media-breakpoint-down(md) {
    font-size: 80%;
  } 
}
