.adda-page-schedule {
  background-color: white;
  max-width: 1280px;
  margin: 0 auto;
  border-bottom: 5px solid #c84589;
  .header_menu {
    background-color: #d9368b;
    overflow: hidden;
    .container {
      max-width: 1280px;
      width: 100%;
      padding: 0px 15px;
      margin: 0 auto;
      ul {
        margin: 0;
        padding: 10px 0px;
        li {
          list-style: none;
          display: inline-block;
          margin-left: 8px;
          a {
            color: white;
            border-radius: 4px;
            font-size: 22px;
            font-weight: 600;
            padding: 5px 10px 2px;
            line-height: 26px;
            display: inline-block;
          }
          .active {
            background-color: #52af72;
          }
        }
      }
    }
  }

  .heading {
    font-weight: 700;
    font-size: 30px;
    color: #c80a11;
    margin-left: 8px;
    @media (max-width: 499px) {
      font-size: 22px;
    }
  }
  .header {
    margin-left: 8px;
    margin-top: 8px;
    margin-right: 8px;

    .tabs {
      display: flex;
      gap: 15px;
      border-bottom: 5px solid #c84589;

      div {
        border-top: 1px solid black; /* Top border */
        border-left: 1px solid black; /* Left border */
        border-right: 1px solid black; /* Right border */
        border-bottom: none;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        padding: 6px 24px 6px 24px;
        font-weight: 600;
        cursor: pointer;
      }

      .selected {
        background-color: #d9368b;
        color: white;
      }
    }
  }
  .tab-content {
    background-color: white;
    max-width: 500px;
    margin: 0 auto;
    .date-section {
      margin: 0 0 14px;
      padding: 28px 17px 28px;
      background: #f2f2f2;
      span {
        font-size: 26px;
        @media (max-width: 499px) {
          font-size: 17px;
        }
      }
      @media (max-width: 499px) {
        padding: 16px 17px 16px;
      }
    }
    .workcell-card {
      background: #f3f3f3;
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      -o-border-radius: 5px;
      margin: 0 0 10px;
      @media (max-width: 499px) {
        margin-left: 8px;
        margin-right: 8px;
      }
      .workcell-card-upper {
        padding: 17px 8px 11px;
        border-bottom: 1px solid #c80a11;
        .timing {
          font-size: 16px;
          font-weight: 700;
          margin: 0 0 8px;
        }
        h3 {
          font-size: 20px;
          font-weight: 700;
          color: #d9368b;
          margin-bottom: 0 !important;
        }
      }
      .workcell-card-middle {
        padding: 17px 8px 11px;
        border-bottom: 1px solid #d1d1d1;
        span {
          font-size: 16px;
          line-height: 1.5;
          margin: 0 0 15px;
        }
        h4 {
          font-weight: 600;
          margin-bottom: 0 !important;
          font-size: 18px !important;
        }
      }
    }
  }
  .contact-container {
    margin-left: 8px;
    margin-right: 8px;

    background-color: #dbd8d8;
    padding: 20px;
    text-align: center;
    .contact-info {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
    .contact-details {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 20px;
      .contact-item {
        display: flex;
        align-items: center;
        svg {
          width: 20px;
          height: auto;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }
}
