.newsCard {
  // position: absolute;
  left: 0%;
  right: 2.69%;
  top: 0%;
  bottom: 51.27%;
  padding-left: 1rem;
  background: #ffffff;
  box-shadow: 0px 2.07519px 4.15038px rgba(0, 0, 0, 0.1);
  border-radius: 5.18797px;
  margin-bottom: 2rem;
}

.pollQuestion {
  // position: absolute;
  left: 2.92%;
  right: 25.11%;
  top: 5.02%;
  bottom: 82.09%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Mukta",system-ui, -apple-system, BlinkMacSystemFont;
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 130%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
  /* or 23px */

  color: #000000;
}

.optionA {
  width: 320px;
  height: 40px;
  margin-top: 10px;
  margin-left: 8px;

  // position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 55.31%;
  padding: 0px !important;
  // padding-top: 8px;
  // padding-left: 13px;
  border: 0.61974px solid #59b2b8;
  border-radius: 3.71844px;
  font-weight: 500;
}

.progress {
  width: 320px;
  height: 40px;
  // margin-top: 10px;
  // margin-left: 8px;

  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 55.31%;
  padding-top: 8px;
  padding-left: 13px;
  border: 0.61974px solid #59b2b8;
  border-radius: 3.71844px;
  font-weight: 500;
  background-color: #bde0e3e5;
  transition: 10s ease;
  transition-delay: 0.5s;
}
