.fullDiv{
    width: 100%;
    // align-items: center;
    max-height: 100px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.oneThirdDiv{
    width: 32%;

    img{
        border-radius: 5px;
        flex-shrink: 0;
        min-width: 100%;
        max-height: 80%;
        padding-bottom: 4px;
        padding-right: 10px;
        border-top-right-radius: 5% 10%;
        border-bottom-right-radius: 5% 15%;
        border-bottom-left-radius: 2% 10%;

        @media screen and (max-width:1100px) and (max-height: 700px) {
            padding-bottom: 14px;
        }

        @media screen and (max-width:1200px) and (max-height: 700px) {
            // padding-bottom: 4px;
        }

        @media screen and (max-width:1300px) {
            // padding-bottom: 14px;
        }
        // height: 100%,
    }
}