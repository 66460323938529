.modal-body {
  // padding: 0;
}
.ftafat-modal {
  .modal-content {
    background-color: transparent;
    .ftafat-action {
      position: absolute;
      top: 2rem;
      width: 100%;
      z-index: 1;
      @extend .d-flex, .align-items-center, .justify-content-between;
      border: 0;
      .back-btn {
        @include media-breakpoint-down(md) {
          margin-left: 3rem;
        }
        .btn-link {
          min-width: 5rem;
          span {
            font-size: 2rem;
            color: $white;
          }
        }
        .close-btn {
          margin-right: 3rem;
          @include media-breakpoint-down(md) {
            margin-right: 1rem;
          }
          .btn-close {
            opacity: 1;
            color: $white;
          }
        }
      }
      .modal-body {
        padding: 0;
        overflow: hidden;
      }
    }
  }
  .ftafat-slider {
    width: 41.6rem;
    margin: 0 auto;
    position: relative;
    height: 100vh;
    @extend .d-flex, .align-items-center, .justify-content-center;
    @include media-breakpoint-down(xxl) {
      width: 38.5rem;
    }
    @include media-breakpoint-down(md) {
      width: 80%;
    }
    @include media-breakpoint-down(sm) {
      width: 90%;
    }

    .slick-slider {
      width: 100%;
      border-radius: 1.5rem;
      overflow: hidden;
      // height: calc(100vh - 2rem);
      .slick-list {
        .slick-track {
          display: flex;
          align-items: center;
          .slick-slide {
            & > div {
              height: auto;
              & > div {
                display: block !important;
              }
            }
          }
        }
      }
    }
    .ftafat-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      background-color: $white;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;
      border: 0;
      color: $primary;
      @extend .d-flex, .align-items-center, .justify-content-center;
      &.ftafat-prev {
        left: -5rem;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
          bottom: 5rem;
          top: auto;
          left: 2rem;
          transform: translateY(-50%);
          background-color: $primary;
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 50%;
          border: 0;
          color: $white;
        }
      }
      &.ftafat-next {
        right: -5rem;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
          bottom: 5rem;
          top: auto;
          right: 2rem;
          transform: translateY(-50%);
          background-color: $primary;
          width: 4.5rem;
          height: 4.5rem;
          border-radius: 50%;
          border: 0;
          color: $white;
        }
      }
    }
    .ftafat-arrow-m {
      z-index: 1;
      width: 5.5rem;
      height: 5.5rem;
      border-radius: 50%;
      border: 0;
      display: none !important;
      color: $primary;
      @extend .d-flex, .align-items-center, .justify-content-center;
      @include media-breakpoint-down(sm) {
        display: inline-block !important;
      }
      margin-right: 10px !important;
      .icon-arrow-left {
        line-height: 2.5 !important;
      }
      .icon-arrow-right {
        line-height: 2.5 !important;
      }
    }
    .ftafat-card {
      border-radius: 1.5rem;
      overflow: hidden;
      background-color: $white;
      height: calc(100vh - 2rem);
      // padding-bottom: 2rem;
      // height: calc(100vh - 3rem);
      @include media-breakpoint-down(md) {
        height: 50.4rem;
      }
      @include media-breakpoint-down(md) {
        height: calc(100vh - 2rem);
      }
      .ftafat-image {
        // @include img-box-react(56.25%);
        img {
          // height: auto;
        }
      }
      .share-btn {
        position: absolute;
        left: 92%;
        top: 88%;
        height: fit-content;
        width: auto;
        z-index: 1;
        cursor: pointer;
      }
      .news-type-box {
        left: 0;
        top: 87%;
        width: 100%;
        height: 13%;
        padding: 0 1.6rem;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.7) 100%
        );
      }
      .share-count {
        padding: 2.4rem 1.8rem 1.5rem;
        @extend .d-flex, .align-items-center, .justify-content-between;
        .counts-sec {
          display: flex;
          flex-direction: row;
          align-items: center;
          .count-box {
            background-color: $skyblue-light-400;
            color: $skyblue-light-100;
            padding: 0.5rem 1rem 0.4rem;
            border-radius: 0.5rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-self: center;
          }
          .fatafat-date {
            margin-left: 10px;
            color: $dark-gray-300;
          }
        }
        .share-btn {
          cursor: pointer;
          a {
            font-size: 2rem;
            color: $primary;
            span {
              display: flex;
            }
          }
        }
      }
      .fixed-fatafat-card {
        position: sticky;
        bottom: 0 !important;
      }
      .ftafat-news-box {
        padding: 1rem 1.8rem 1.5rem;
        // height: 25rem;
        // overflow-y: auto;
        // height: 25rem;
        display: flex;
        height: calc(100vh - 30.5rem);
        @include media-breakpoint-down(md) {
          height: 25rem;
        }
        @include media-breakpoint-down(sm) {
          height: 50%;
        }
        .ftafat-scrollbar {
          div:first-child {
            margin-right: 0 !important;
            margin-bottom: 0 !important;
          }
          ::-webkit-scrollbar {
            display: none;
          }
        }
        .simplebar-track {
          width: 0.8rem;
        }
        h3 {
          font-size: 2rem;
          margin-bottom: 1rem;
          @include media-breakpoint-down(md) {
            font-size: 2rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 2.4rem;
          }
        }
        .ftafat-content {
          font-size: 1.6rem;
          @include media-breakpoint-down(md) {
            padding-bottom: 2rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 2.1rem;
          }
          p {
            @include media-breakpoint-down(md) {
              font-size: 1.4rem;
            }
            @include media-breakpoint-down(sm) {
              font-size: 2.1rem;
            }
          }
        }
      }
      .sticky-read-more {
        position: fixed;
        bottom: 20px;
        text-align: right;
        width: 9%;
        @media screen and (max-width: 400px) and (max-height: 700px) {
          bottom: 40px;
        }
      }
      .sticky-read-more-new {
        position: absolute;
        bottom: 20px;
        text-align: right;
        width: 100%;
        @media screen and (max-width: 400px) and (max-height: 700px) {
          bottom: 40px;
        }
      }
      .ftafat-ads {
        // text-align: center;
        // min-height: auto;
        padding: 0.8rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .sponser-ads {
          text-align: center;
          display: inline-block;
          margin: 0 auto;
          img {
            max-height: 100%;
            margin: 0 auto;
            @include media-breakpoint-down(md) {
              max-width: 95%;
            }
          }
        }
      }
    }
    .ftafat-link {
      color: #d9368b;
      font-weight: bold;
      text-align: right;
      margin-top: 45px;
      margin-bottom: 0;
      padding: 2px 3px;
    }
  }
  .mobile-footer {
    display: flex;
  }
  .line-1 {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  .line-2 {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    height: 30px;
    margin-left: 15rem;
  }
  .share-btn-mob {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    padding: 1rem 0rem;
    cursor: pointer;
  }
  .read-more-mob {
    flex-basis: 50%;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    padding: 0.8rem 0rem;
  }
  .ftafat-link {
    color: #d9368b;
    font-weight: bold;
    text-align: right;
    font-size: 1.6rem;
    margin-right: 1rem;
  }
  .icon-share {
    color: #d9368b;
    font-weight: bold;
    text-align: right;
    font-size: 1.8rem;
  }
  .footer-items {
    display: flex;
    gap: 1rem;
  }
}
.modal-body {
  scroll-snap-type: y mandatory;
  // overflow-y: scroll !important;
  padding: 0;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0; /* Safari and Chrome */
  }
  .fatafat-container {
    height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;

    padding-top: 1.5rem;
    position: relative;
    @media screen and (max-width: 600px) {
      padding-top: 0rem;
    }
    .fatafat-body {
      background-color: white;
      min-height: 100vh;
      height: -webkit-fill-available;
      position: relative;
      // scroll-snap-align: start;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none;
      margin-bottom: 2rem;
      @media screen and (min-width: 600px) {
        width: 31%;
        min-height: 95vh;
        border-radius: 1.5rem;
        margin: 0 auto 1.5rem;
      }
      .ftafat-image-container {
        position: relative;
        .ftafat-image {
          img {
            aspect-ratio: 16/9;
            @media screen and (min-width: 600px) {
              border-top-left-radius: 1.5rem;
              border-top-right-radius: 1.5rem;
            }
          }
        }
        .ftafat-close-button {
          background-color: #0000006b;
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          margin: 1rem 0 0 1rem;
          @media screen and (min-width: 600px) {
            display: none;
          }
        }
      }
      .ftafat-content-container {
        padding: 1rem 1.8rem 0 1.8rem;

        .ftafat-heading {
          h3 {
            font-size: 2rem;
            margin-bottom: 1rem;
            @include media-breakpoint-down(md) {
              font-size: 2rem;
            }
            @include media-breakpoint-down(sm) {
              font-size: 2.4rem;
            }
          }
        }
        .ftafat-body {
          font-size: 1.6rem;
          // padding: 0 1rem;
          @include media-breakpoint-down(md) {
            padding-bottom: 2rem;
          }
          @include media-breakpoint-down(sm) {
            font-size: 2.1rem;
          }
          p {
            @include media-breakpoint-down(md) {
              font-size: 1.4rem;
            }
            @include media-breakpoint-down(sm) {
              font-size: 2.1rem;
            }
          }
        }
      }
      .footer-mobile {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100vw;
        margin-bottom: 1.5rem;
        @media screen and (min-width: 600px) {
          width: 100%;
          margin-bottom: 0rem;
        }
        @media screen and (max-width: 499px) {
          bottom: 16%;
        }
        .slide-up {
          display: flex;
          justify-content: center;
          margin-top: 1rem;
        }
      }
    }
    .modal-desktop-buttons {
      position: fixed;
      right: 0;
      top: 0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 2rem;
      margin-top: 1rem;
      padding-bottom: 2rem;
      @media screen and (max-width: 600px) {
        display: none;
      }
      .close-btn {
        .btn-close {
          opacity: 1;
          color: $white;
        }
      }
    }
  }
}
.swipe-icon {
  background-color: white;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
}
.swipe-icon-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
