.stories-card-box {
  // display: flex;
  // flex-direction: column;
  // height: fit-content;
  // margin-bottom: 10px;
  // width: 88%;
  // margin: auto;
  // padding-bottom: 15px;
  // padding-top: 18px;
  // margin-top: 14px;
  // background-color: white;
  // @media (max-width: 480px) {
  //   padding-right: 1rem;
  //   padding-left: 1rem;
  //   width: 100%;
  //   padding-top: 0px !important;
  // }
  // @media (max-width: 1200px) and (min-width: 501px) {
  //   width: 75%;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
  margin: 0 auto;
  width: 76%;
  padding-bottom: 2rem;
  @media (min-width: 2241px) {
    width: 66%;
  }
  @media (max-width: 2240px) {
    width: 88%;
  }

  @media (max-width: 1440px) {
    width: 88%;
  }
  @media (max-width: 1240px) {
    width: 76%;
  }

  @media (max-width: 991px) {
    width: 76%;
  }
  @media (max-width: 700px) {
    width: 88%;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 1rem;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
  .stories-top-bar {
    background-color: white;
    text-transform: capitalize;
    padding: 1.6rem 0rem;
    color: #d9368b;
    border-radius: 1.5rem 1.5rem 0px 0px;
    @extend .d-flex, .justify-content-between, .align-items-center;
    @include media-breakpoint-down(lg) {
      padding: 1.2rem 0rem;
    }
    @include media-breakpoint-down(md) {
      border-radius: 1rem 1rem 0px 0px;
    }
    .stories-title-left {
      font-size: 2.7rem;
      color: #d9368b;
      @extend .d-flex,
        .justify-content-between,
        .align-items-center,
        .fw-medium;
      @include media-breakpoint-down(md) {
        font-size: 2.2rem;
      }
      h2 {
        font-size: 2.7rem !important;
        color: #d9368b !important;
        margin-bottom: 0rem !important;
      }
      i {
        padding-right: 1.6rem;
        @include media-breakpoint-down(md) {
          font-size: 2.2rem;
        }
      }
    }
    .stories-link {
      font-size: 1.9rem;
      color: #d9368b;
      @extend .d-flex,
        .justify-content-between,
        .align-items-center,
        .text-white;
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
      i {
        font-size: 1.2rem;
        padding-left: 1.2rem;
        @include media-breakpoint-down(md) {
          font-size: 1.2rem;
        }
        margin-top: 1.8rem;
      }
    }
  }
  .stories-top-bar + .stories-card-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .stories-card-body {
    padding: 3.7rem 3.4rem 4.2rem 3.4rem;
    border-radius: 1.5rem;
    overflow: hidden;
    display: -moz-box;
    display: flex;
    background-color: $white;
    @media (max-width: 1440px) {
      padding: 2.2rem 1.7rem;
    }
    @media (max-width: 991px) {
      padding: 1.5rem 1.2rem;
    }
    .swiper {
      padding: 0 14rem;
      width: 100%;
      @media (max-width: 1800px) {
        padding: 0 11rem;
      }
      @media (max-width: 1440px) {
        padding: 0 10rem;
      }
      @media (max-width: 1199px) {
        padding: 0 9rem;
      }
      @media (max-width: 767px) {
        padding: 0;
      }
      &::after,
      &::before {
        content: "";
        height: 34rem;
        width: 14rem;
        background-color: $white;
        display: block;
        position: absolute;
        top: 0;
        z-index: 1;
        @media (max-width: 1800px) {
          width: 12.5rem;
          height: 31rem;
          top: -0.3rem;
        }
        @media (max-width: 1440px) {
          width: 11rem;
          height: 31rem;
        }
        @media (max-width: 1199px) {
          width: 9.8rem;
          height: 22rem;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      &::before {
        left: 0;
        @media (max-width: 1800px) {
          left: -0.2rem;
        }
      }
      &::after {
        right: 0;
        @media (max-width: 1800px) {
          right: -0.2rem;
        }
      }
      .swiper-wrapper {
        position: static;
        .swiper-slide {
          .stories-item {
            width: 20.7rem;
            height: 33.7rem;
            //border: 0.1rem solid $primary;
            //border-radius: 50%;
            //padding: 0.83rem;
            margin: 0 auto;
            cursor: pointer;
            .stories-box {
              overflow: hidden;
              //border-radius: 50%;
              @include img-box-react(100%);
              img {
                max-width: 100%;
              }
            }
            @media (max-width: 1800px) {
              width: 19.7rem;
              height: 30.7rem;
            }
            @media (max-width: 1440px) {
              width: 18.9rem;
              height: 30.7rem;
              //padding: 1.79rem;
              :first-child {
                margin: 0 auto;
              }
              margin: 0 8px;
            }
            @media (max-width: 1199px) {
              width: 13.7rem;
              height: 21.7rem;
              //padding: 1.49rem;
            }
            @media (max-width: 991px) {
              width: 11.7rem;
              height: 18.7rem;
              //padding: 1.29rem;
            }
            @media (max-width: 767px) {
              // width: 8.8rem;
              // height: 8.8rem;
              width: 21.9rem;
              height: 35.1rem;
              padding: 1rem;
            }

            @media (max-width: 500px) {
              // width: 8.8rem;
              // height: 8.8rem;
              width: 20.9rem;
              height: 32.1rem;
              padding: 1rem;
            }

            @media (max-width: 400px) {
              // width: 8.8rem;
              // height: 8.8rem;
              width: 18.9rem;
              height: 30.1rem;
              padding: 1rem;
            }
          }
        }
      }
      .slider-nav {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // overflow: hidden;
        // width: 100%;
        // position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        // z-index: 1;
        width: 100%;
        .prev {
          left: 0;
        }
        .next {
          right: 0;
        }
        .prev,
        .next {
          width: 13.6rem;
          height: 13.6rem;
          border: 0.1rem solid $primary;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          padding: 0.83rem;
          background-color: $white;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          .swiper-circle {
            border: 0.1rem solid $primary;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 2rem;
              color: $primary;
              @media (max-width: 1440px) {
                font-size: 1.9rem;
              }
              @media (max-width: 1199px) {
                font-size: 1.7rem;
              }
              @media (max-width: 991px) {
                font-size: 1.5rem;
              }
            }
          }
          &.swiper-button-disabled {
            border-color: $gray-light-300;
            .swiper-circle {
              border-color: $gray-light-300;
              i {
                color: $gray-light-300;
              }
            }
          }
          @media (max-width: 1800px) {
            width: 11.5rem;
            height: 11.5rem;
          }
          @media (max-width: 1440px) {
            width: 9.7rem;
            height: 9.7rem;
            padding: 0.59rem;
          }
          @media (max-width: 1199px) {
            width: 8.5rem;
            height: 8.5rem;
            padding: 0.514rem;
          }
          @media (max-width: 991px) {
            width: 7.35rem;
            height: 7.35rem;
            padding: 0.446rem;
          }
          &.swiper-button-lock {
            display: block;
          }
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}
