.slick-prev:before {
  background-color: black;
}
.slick-next:before {
  background-color: black;
}

.c-story-carousel {
  // margin-top: 3rem;
  margin-bottom: 3rem;

  .slick-list {
    margin: 0 12.5rem;
    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }
}

.c-story-box {
  width: 10.53rem;
  margin: 0 2rem;
  @extend .d-block;
  @include media-breakpoint-down(xxl) {
    margin: 1.9rem;
  }
  @include media-breakpoint-down(xl) {
    width: 10rem;
    margin: 1.9rem;
  }
  @include media-breakpoint-down(lg) {
    width: 10.8rem;
  }
  @include media-breakpoint-down(md) {
    width: 11rem;
    margin: 2rem;
  }

  .c-story-thumb {
    border-radius: 50%;
    border: solid 0.6rem transparent;
    box-shadow: 0 0 0 1px $primary;
    margin: 0.2rem;
    @include img-box-react(100%);
  }

  p {
    font-size: 1.7rem;
    margin-top: 0.8rem;
    @extend .fw-medium, .mb-0, .text-black, .text-center;
  }
}
.our-auther {
  .card-left {
    .card-body {
      padding: 3rem 2.8rem;
      @include media-breakpoint-down(sm) {
        padding: 3rem 1rem;
      }
    }
  }
}
.columinist-sec {
  ul {
    padding: 0;
    margin: 0;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    li {
      list-style: none;
      float: left;
      width: 23.8rem;
      text-align: center;
      background: #ffffff;
      box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      margin: 0 2.3rem 2.9rem 0;
      .c-story-box {
        margin: 0 auto;
        width: 100%;
        padding: 2.124rem 0;
        @media (max-width: 1799px) {
          padding: 1rem 0;
        }
        @include media-breakpoint-down(md) {
          padding: 2.124rem 0;
        }
        .c-story-thumb {
          width: 15rem;
          height: 15rem;
          margin: 0 auto;
          @media (max-width: 1439px) {
            // width: 15rem;
            // height: 15rem;
          }
          @include media-breakpoint-down(md) {
            // width: 11.154rem;
            // height: 11.154rem;
          }
        }
        p {
          font-size: 1.8rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 1439px) {
            font-size: 1.6rem;
          }
          @include media-breakpoint-down(md) {
            font-size: 1.8rem;
          }
        }
      }
      &:nth-child(4n) {
        margin-right: 0;
        @include media-breakpoint-down(lg) {
          margin-right: 1.15rem;
          margin-left: 0;
        }
      }
      &:nth-child(3n) {
        @include media-breakpoint-down(lg) {
          margin-right: 0;
        }
        @include media-breakpoint-down(md) {
          margin-right: 1.15rem;
        }
      }
      &:nth-child(2n) {
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }
      // &:first-child {
      //   margin-left: 0;
      // }
      // &:last-child {
      //   margin-right: 0;
      // }
      // &:nth-child(5n) {
      //   margin-left: 0;
      //   @include media-breakpoint-down(lg) {
      //     margin-left: 1.15rem;
      //   }
      //   @include media-breakpoint-down(md) {}
      // }
      //
      //   @include media-breakpoint-down(md) {
      //     margin-left: 1.15rem;
      //     margin-right: 0;
      //   }
      // }
      // &:nth-child(3n+1) {
      //   @include media-breakpoint-down(lg) {
      //     margin-right: 0;
      //   }
      //   @include media-breakpoint-down(md) {
      //     margin-left: 0;
      //     margin-right: 1.15rem;
      //   }
      // }
      // &:nth-child(2n) {
      //   @include media-breakpoint-down(md) {
      //     margin-right: 0;
      //   }
      // }
      &:hover {
        box-shadow: 0 0 1.4rem rgba(217, 54, 139, 0.1);
      }
      @media (max-width: 1799px) {
        width: 19rem;
      }
      @include media-breakpoint-down(xxl) {
        width: 16.2rem;
      }
      @media (max-width: 1439px) {
        width: 16.22rem;
      }
      @include media-breakpoint-down(lg) {
        width: 17.9rem;
        margin-right: 1.15rem;
        margin-bottom: 1.5rem;
      }
      @include media-breakpoint-down(md) {
        width: 30.25rem;
      }
      @include media-breakpoint-down(sm) {
        // margin: 0 auto 2rem !important;
        // max-width: 100%;
        width: 48%;
        // float: none;
      }
    }
  }
}
.full-columnist {
  .columinist-sec {
    ul {
      // li {
      //   margin-right: 2.5rem;
      //   @include media-breakpoint-down(xl) {
      //     margin-right: 2.4rem;
      //   }
      //   @include media-breakpoint-down(xl) {
      //     margin-right: 1.15rem;
      //   }
      //   @include media-breakpoint-down(md) {
      //     margin-right: 1.15rem;
      //     @include media-breakpoint-down(sm) {
            
      //     }
      //   }
      // }
    }
  }
}
