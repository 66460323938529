.audio-player-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $green;
  border-radius: 1.5rem 1.5rem 0 0;
  z-index: 9999;
  box-shadow: 0 0 10px rgba($black, 0.25);
  @include media-breakpoint-down(sm) {
    bottom: 50px;
  }

  .audio-player-inner {
    position: relative;
    padding: 0.8rem 0;
    @include media-breakpoint-down(lg) {
      padding: 1rem 0;
    }

    .player-close-btn {
      position: absolute;
      width: 3.8rem;
      height: 3.8rem;
      right: -1rem;
      top: -2rem;
      border-radius: 50%;
      border: 2px solid $white;
      background-color: $black;
      min-width: auto;
      font-size: 1.5rem;
      @extend .m-0,
        .p-0,
        .text-white,
        .d-flex,
        .justify-content-center,
        .align-items-center;
    }

    .player-info-left {
      @extend .d-flex;
      @include media-breakpoint-down(lg) {
        margin-bottom: 0.8rem;
      }

      .player-iconbox {
        width: 8.4rem;
        height: 8.4rem;
        border-radius: 1.2rem;
        background-color: $skyblue-light-200;
        font-size: 2.4rem;
        display: flex;
        @extend .justify-content-center,
          .align-items-center,
          .text-white,
          .me-3;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      .info-right-col {
        width: 100% !important;
        @extend .d-flex, .flex-column;

        p {
          font-size: 1.6rem;
          color: $yellow-light-100;
          @extend .fw-semibold, .my-0;
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        h4 {
          font-size: 1.75rem;
          color: $white;
          @extend .fw-medium, .mt-0;
        }
        a {
          color: $white;
          font-size: 1.5rem;
          @extend .mt-auto, .text-decoration-underline, .fw-normal;

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }
    }

    .player-link-right {
      @include media-breakpoint-up(lg) {
        min-width: 10rem;
      }
      font-size: 2rem;
      cursor: pointer;
      color: $white;
      @extend .d-flex, .justify-content-between, .align-items-center;

      .icon-play-loop {
        @include media-breakpoint-up(lg) {
          font-size: 2.4rem;
          color: $yellow-light-100;
        }
      }

      a {
        color: $white;
        @extend .px-2;
      }
    }
  }
}

.custom-audio-player {
  max-width: 48rem;
  margin: 0 auto;
  @extend .d-flex, .flex-column;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }

  .ca-player-controls {
    color: $white;
    font-size: 2rem;
    @extend .d-flex, .justify-content-between, .align-items-center;

    > * {
      cursor: pointer;
    }
  }

  .audio-speed-text {
    .speed-dropdown {
      @extend .d-flex, .align-items-end;
      font-size: 1.7rem;

      .Dropdown-control {
        background-color: transparent;
        color: $white;
        padding: 0 0.4rem 0;
        cursor: pointer;
        @extend .border-0;

        .Dropdown-placeholder {
          min-width: 27px;
        }

        .Dropdown-arrow-wrapper {
          display: none;
        }
      }

      .Dropdown-menu {
        width: 6rem;
        top: auto;
        bottom: 100%;

        .Dropdown-option {
          height: 3.5rem;
          padding: 6px 8px;
        }
      }
    }
    // @include media-breakpoint-down(lg) {
    //   display: none;
    // }
  }

  .volume-dropdown {
    @extend .d-flex, .align-items-end;
    font-size: 1.7rem;

    .Dropdown-control {
      background-color: transparent;
      color: $white;
      padding: 0 0.4rem 0;
      padding-bottom: 0.6rem;
      cursor: pointer;
      @extend .border-0;

      .Dropdown-placeholder {
        min-width: 27px;
      }

      .Dropdown-arrow-wrapper {
        display: none;
      }
    }

    .Dropdown-menu {
      width: 6rem;
      top: auto;
      bottom: 100%;
      // @media (min-width: 768px) {
      max-height: 250px !important;
      // }

      .Dropdown-option {
        height: 3.5rem;
        padding: 6px 8px;
      }
    }
  }

  .audio-prev-speed,
  .audio-next-speed {
    position: relative;
    font-size: 2.7rem;
    @extend .d-flex, .justify-content-center, .align-items-center;

    .num-text {
      position: absolute;
      font-size: 1.3rem;
      padding-top: 0.3rem;
    }
  }

  .play-pause-btn {
    padding: 1rem;
    background-color: $primary;
    border-radius: 0.2rem;
  }

  .playlist-btn {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .ca-progress-bar-wrap {
    margin-top: 1.4rem;
    @extend .d-flex, .align-items-center;
    @include media-breakpoint-down(lg) {
      order: -1;
      margin-top: 0;
      margin-bottom: 1.4rem;
    }

    > span {
      font-size: 1.5rem;
      color: rgba($white, 0.6);
    }

    .rc-slider {
      height: 16px;
      margin: 0 1.6rem;

      .rc-slider-rail {
        height: 6px;
        border-radius: 12px;
        background-color: rgba($white, 0.6);
      }

      .rc-slider-step {
        height: 6px;
      }

      .rc-slider-handle {
        width: 20px;
        height: 20px;
        border: 0;
        margin-top: -7px;
        cursor: pointer;
        background-color: $primary;
        @extend .d-flex, .justify-content-center, .align-items-center;

        &:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid $white;
        }
      }

      .rc-slider-track {
        height: 6px;
        border-radius: 12px;
        background-color: $white;
      }

      .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
      .rc-slider-handle:active {
        border: 0;
        box-shadow: none;
      }
    }
  }
}

.audio-player-marquee .overlay {
  --gradient-width: 0px !important;
}

.play-time,
.total-time {
  font-size: 1.7rem !important;
}

.close-player {
  color: white !important;
  margin: 0 !important;
}
