.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  .carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    .carousel-content-wrapper {
      overflow: hidden;
      width: 100%;
      height: 100%;
      .carousel-content {
        display: flex;
        transition: all 250ms linear;
        -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
        scrollbar-width: none; /* hide scrollbar in Firefox */
        .storie-item {
          flex-direction: column;
          @extend .d-flex,
            .align-items-center,
            .justify-content-center,
            .flex-wrap;
          .image-circle {
            width: 13.6rem;
            height: 13.6rem;
            border: 0.15rem solid $primary;
            border-radius: 50%;
            padding: 0.8rem;
            margin-bottom: 1.4rem;
            .storie-image {
              border-radius: 50%;
              overflow: hidden;
              width: 100%;
              height: 100%;
              a {
                height: 100%;
                @include img-box(56.25%);
                img {
                  max-width: 100%;
                }
              }
            }
          }
          & > a {
            font-size: 1.8rem;
            font-weight: 600;
            color: $gray;
          }
        }
      }
      .carousel-content > * {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
      }
      .carousel-content.show-2 > * {
        width: 50%;
      }
      .carousel-content.show-3 > * {
        width: calc(100% / 3);
      }
      .carousel-content.show-4 > * {
        width: calc(100% / 4);
      }
      .carousel-content.show-5 > * {
        width: calc(100% / 5);
      }
      .carousel-content.show-6 > * {
        width: calc(100% / 6);
      }
      .carousel-content.show-7 > * {
        width: calc(100% / 7);
      }
      .carousel-content.show-8 > * {
        width: calc(100% / 8);
        @include media-breakpoint-up(xxl) {
          width: calc(100% / 8);
        }
        @include media-breakpoint-down(xl) {
          width: calc(100% / 6);
        }
        @include media-breakpoint-down(lg) {
          width: calc(100% / 4);
        }
        @include media-breakpoint-down(md) {
          width: calc(100% / 3);
        }
        @include media-breakpoint-down(sm) {
          width: calc(100% / 2);
        }
      }
      .carousel-content.show-9 > * {
        width: calc(100% / 9);
      }
      .carousel-content.show-10 > * {
        width: calc(100% / 10);
      }
    }
  }
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}
.homepage-top-slider {
  .left-arrow,
  .right-arrow {
    position: absolute;
    z-index: 1;
    top: calc(41% + 1px);
    width: 10.53rem;
    height: 10.53rem;
    border-radius: 50%;
    padding: 0.35rem;
    background-color: transparent;
    border: 0.15rem solid $primary;
    transform: translateY(-50%);
    .white-circle {
      width: 100%;
      height: 100%;
      @extend .d-flex, .align-items-center, .justify-content-center;
      background-color: $white;
      border-radius: 50%;
      border: 0.15rem solid $primary;
      span {
        font-size: 2.8rem;
        color: $primary;
      }
    }
    .arrwo-text{
      position: absolute;
      top: calc(100% + 1rem);
      left: 50%;
      transform: translateX(-50%);
      min-width: 10.5rem;
    }
  }

  .left-arrow {
    left: 0.2rem;
  }

  @media (max-width:1441px) {
    .homepage-top-slider {
      .right-arrow {
        right: 2.6rem;
      }
    }
  }

  .right-arrow {
    right: 0.12rem;
    @include media-breakpoint-down(xxl) {
      right: 0.6rem;
    }
    @include media-breakpoint-down(xl) {
      right: 0.6rem;
    }
    @include media-breakpoint-down(lg) {
      right: 0.4rem;
    }
  }
}

@media (hover: none) and (pointer: coarse) {
  .left-arrow,
  .right-arrow {
    display: none;
  }
}
