.sponser-card {
  border-radius: 1.2rem;
  border: 1px solid rgba($black, .1);

  @include media-breakpoint-down(lg) {
    border: 0;
    border-radius: 0;
    // border-top: 1px solid rgba($black, .1);
  }

  &:not(.no-bg) {
    background-color: $white;
  }

  &.s-300x250 {
    padding: 7.95% 0;
    margin-bottom: 3.9rem;
    @extend .text-center;

    @media (max-width: 1366px){
      padding: 3.15% 0;
    }
    @include media-breakpoint-down(xl) { 
      margin-bottom: 1rem;
      padding: 1.4rem 0;
    }
    @include media-breakpoint-down(lg) {
      margin-bottom: 0;
    }
  }
  &.s-300x600 {
    padding: 9.59% 0;
    @extend .text-center;

    @media (max-width: 1366px){
      padding: 3.15% 0;
    }
    @include media-breakpoint-down(xl) { 
      padding: 1.4rem 0;
    }
  }
  &.s-970x90 {
    padding: 3.95%  0;
    @extend .text-center;
  }
  &.s-970x250 {
    padding: 2rem 0;
    margin-bottom: 3rem;
    @extend .text-center;
    @include media-breakpoint-down(md) {
      padding: 0;
      margin-bottom: 1.5rem;
    }
  }
}

.sponser-card-two {
  @extend .d-flex, .flex-wrap;

  .left-sponser-col {
    @extend .col;

    .left-sponser {
      padding: 2.4rem;
      border-radius: 1.5rem;
      border: 1px solid rgba($black, .1);
      @extend .text-center;
    }
  }
  .right-sponser-col {
    flex: 0 0 34.25%;
    min-width: 30rem;
    padding-left: 1rem;

    @include media-breakpoint-down(xl) { 
      flex: 0 0 100%;
    }

    .right-sponser {
        padding: 2.4rem;
        border-radius: 1.5rem;
        border: 1px solid rgba($black, .1);
       @extend .text-center; 
    }
  }
}

.add-card{
  border-radius: 1.2rem;
  border: 1px solid rgba($black, .1);
  &.s-300x250 {
    padding: 12.95% 0;
    margin-bottom: 3.9rem;
    @extend .text-center;

    @media (max-width: 1366px){
      padding: 19.15% 0;
    }
  }
}
.card-ad-body{
  text-align-last: center;
}