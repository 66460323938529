$font-family-sans-serif: "Mukta" ,system-ui, -apple-system, BlinkMacSystemFont !important;
$font-size-root: 62.5%;
$font-size-base: 1.6rem;
$line-height-base: 1.4;
$headings-line-height: 1.5;
$paragraph-margin-bottom: 1.6rem;
$enable-rfs: false;
$spacer: 1.6rem;
$grid-gutter-width: 2.4rem;
$link-decoration: none;
$h1-font-size: 3.6rem;
$h2-font-size: 3.2rem;
$h3-font-size: 2.8rem;
$h4-font-size: 2.4rem;
$h5-font-size: 2rem;
$h6-font-size: 1.6rem;
$white:#FFFFFF;
$black : #000000;
$dark-gray-100:#2a2a2a;
$dark-gray-200:#938e8e;
$dark-gray-300:#868686;
$dark-gray-400: #999999;
$dark-gray-500: #F1F1F1;
$dark-gray-600: #606060;
$dark-gray-700: #f2f2f2;
$dark-gray-800: #7f7f7f;
$skyblue-light-100:#59B2B8;
$skyblue-light-200:#6FCBD0;
$skyblue-light-300:#d7f0f2;
$skyblue-light-400:#D7F0F2;
$skyblue-light-500:#e6f3f4;
$skyblue-light-600:#def0f1;
$gray: #5F5F5F;
$gray-light-100:#c7c7c7;
$gray-light-200:#f4f4f4;
$gray-light-300:#C4C4C4;
$gray-light-400:#cecdcd;
$gray-light-500:#DADADA;
$gray-light-600:#A6A6A6;
$gray-light-700:#BBBBBB;
$gray-light-800: #f2f2f2;
$pink: #D9368B;
$pink-light:#EB0E8C;
$pink-bglight:#f9e1ee;
$light-pink:#fbebf3;
$light-pink-100:#FDF5DC;
$light-red:#F05050;
$choko-light:#736765;
$choko-medium:#514745;
$choko-dark:#2d1e19;
$orange :#F58324;
$green :#016369;
$green-medium:#61C777;
$green-light:#e7f7eb;
$teal: #147F8D;
$yellow-dark:#F3CD4E;
$yellow : #FFD133;
$yellow-light : #FCC623;
$yellow-light-100 : #F3B305;
$body-color: $black;
$primary : $pink;
$light-green: #3AAAB1;
$warning : $orange;
$info : #59b2b8;

// $nav-link-padding-y: 1.2rem;
// $nav-link-padding-x:  1.6rem;
// $navbar-nav-link-padding-x: 1.2rem;
// $navbar-light-color: $gray-900;
// $navbar-light-hover-color: $black;
// $navbar-light-active-color: $black;

$input-btn-padding-y: .6rem;
$input-btn-padding-x:  1.5rem;
$input-line-height : 1.5;
$input-color: $black;
$input-bg: $white;
$input-font-size: 1.6rem;
$input-border-color: $gray-light-100;
$input-border-radius: 0.6rem;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-font-weight: 400;
$input-focus-border-color: $primary;
$form-label-color: $black;
$form-label-font-weight: 400;
$form-select-focus-border-color:$primary;
$form-select-border-radius: 0.6rem;
$form-select-focus-box-shadow: none;
$form-label-margin-bottom: .8rem;

$btn-padding-y: .8rem;
$btn-padding-X: 1.5rem;
$btn-font-weight: 300;
$btn-border-radius: .5rem;
$btn-font-size: 1.5rem;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;

$modal-content-border-radius: 0.8rem;
$modal-content-inner-border-radius: 0.6rem;

$table-cell-padding-y: .8rem;
$table-cell-padding-x: .8rem;

$form-check-input-focus-box-shadow: none;
$form-check-input-width: 1.8em;
$form-check-min-height:  $font-size-base * $line-height-base !default;