.login-container,
.new-login-container {
  min-height: 100vh;
  background-color: $white;
  @extend .d-flex;
  .log_lhs {
    background-color: $primary;
    flex: 0 0 26.67%;
    padding: 2rem;
    position: relative;
    display: flex;
    @extend .align-items-center, .justify-content-center;
    .page_back {
      position: absolute;
      left: 2rem;
      top: 2rem;
      color: $white;
      padding: 0.5rem 1rem;
    }
    .welcontent {
      text-align: center;
      img {
        margin-bottom: 3.3rem;
        max-width: 20rem;
      }
      h2 {
        font-size: 2.4rem;
        color: $white;
        font-weight: 500;
      }
    }
    .copylallan {
      position: absolute;
      bottom: 2rem;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $white;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  .log_rhs,
  .new-login-container {
    flex: 1;
    padding: 2rem 17.7rem;
    @extend .d-flex, .align-items-center;
    flex-direction: column;
    position: relative;
    @media (max-width: 1199px) {
      padding: 2rem 10rem;
    }
    @media (max-width: 767px) {
      padding: 2rem 5rem;
    }
    @include media-breakpoint-down(md) {
      padding: 2rem 1.5rem;
    }
    .welcome_h1 {
      margin: 2rem auto 2.7rem;
      @extend .d-flex,
        .align-items-center,
        .justify-content-center,
        .flex-column;
      @media (max-width: 1199px) {
        margin: 2rem 1.5rem;
      }
      h1 {
        color: $primary;
        font-weight: 500;
        font-size: 5rem;
        margin: 0;
        @media (max-width: 1199px) {
          font-size: 4.5rem;
        }
      }
      p {
        color: $gray;
        font-size: 2.4rem;
        margin: 0;
      }
    }
    .social-login-box {
      width: 95%;
      margin-bottom: 2rem;
      @extend .d-flex, .align-items-center, .justify-content-center;
      .socialcomm {
        font-size: 1.4rem !important;
        line-height: 1.8rem;
        padding: 0.6rem 2rem !important;
        border-radius: 0.6rem !important;
        border: 1px solid $primary !important;
        box-shadow: none !important;
        color: #000 !important;
        cursor: pointer;
        justify-content: space-between;
        @extend .d-flex, .align-items-center;
        span {
          margin-left: 1.5rem;

          @include media-breakpoint-down(xl) {
            margin-left: 1rem;
          }
          @include media-breakpoint-down(md) {
            margin-left: 2rem;
          }
        }
        @include media-breakpoint-down(md) {
          width: 100%;
          justify-content: flex-start;
          margin-bottom: 1.2rem;
          font-size: 1.8rem !important;
          padding-left: 33% !important;
        }
        @include media-breakpoint-down(sm) {
          padding-left: 24% !important;
        }
        & + .socialcomm {
          margin-left: 2.7rem;
          @media (max-width: 767px) {
            margin-left: 0;
          }
        }
      }
      @include media-breakpoint-down(xl) {
        width: 100%;
      }
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
    }
    .divider {
      margin: 2rem auto;
      width: 85%;
      height: 1px;
      background-color: $dark-gray-300;
      @extend .d-flex, .align-items-center, .justify-content-center;
      .divi_text {
        font-size: 1.6rem;
        font-weight: 400;
        color: $gray;
        padding: 1rem 4rem;
        background-color: $white;
        line-height: 2.4rem;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          padding: 1rem;
        }
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    form {
      width: 100%;
      .formcommon {
        margin: 2rem auto;
        width: 85%;
        .formhead,.formcommon-otp {
          color: $primary;
          font-weight: 600;
          font-size: 2.2rem;
          margin: 0;
        }
        .otp-warp-head {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 0.6rem;
          .formhead {
            color: $primary;
            font-weight: 600;
            font-size: 2.2rem;
            margin: 0;
          }
          .otp-info-text {
            font-size: 1.6rem;
            font-weight: 300;
            margin-bottom: 0;
            color: #000000;
            display: none;
            @media (max-width: 450px) {
              display: block;
            }
          }
        }
        .formbox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .concode {
            font-size: 1.6rem;
            flex-basis: 14%;
            margin-right: 3%;
            padding: 0.8rem 1.5rem;
            text-align: center;
            &:disabled {
              background-color: $white;
              color: $black;
            }

            @include media-breakpoint-down(md) {
              flex-basis: 16%;
              padding: 0.8rem 1rem;
            }
          }
          .number-input {
            width: 83%;
            .phnnum {
              font-size: 1.6rem;
              padding: 0.8rem 1.5rem 0.8rem 3rem;
            }
          }
        }
        .otpcont {
          margin-bottom: 2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .otp-input-box {
            width: 55px;
            height: 55px;
            padding: 5px;
            @include media-breakpoint-down(sm) {
              width: 45px;
              height: 45px;
              padding: 3px;
            }
            @media (max-width: 450px) {
              width: 40px;
              height: 45px;
              padding: 3px;
            }
          }
          .formbox {
            flex-basis: 50%;
            input {
              font-size: 1.6rem;
              padding: 0.6rem 1.5rem;
              margin: 0 2rem 0 0;
              text-align: center;
            }
          }
          .otpbox {
            flex-basis: 50%;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media (max-width: 450px) {
              flex-basis: 30%;
            }
            .otpmsg {
              font-size: 1.6rem;
              font-weight: 300;
              margin-bottom: 0;
              color: $black;
              margin-right: 1.5rem;
              @media (max-width: 450px) {
                display: none;
              }
            }
            .otpcountdown {
              font-size: 1.6rem;
              font-weight: 400;
              background-color: $light-pink;
              color: $primary;
              line-height: 3.9rem;
              text-align: center;
              padding: 0.7rem 1.2rem;
              border-radius: 0.4rem;
            }
            .resendotp {
              font-size: 1.6rem;
              font-weight: 400;
              background-color: $light-pink;
              color: $primary;
              line-height: 3.9rem;
              text-align: center;
              padding: 0.5rem 0.8rem;
              border-radius: 0.4rem;
              border: none;
            }
          }
        }
        @include media-breakpoint-down(xl) {
          width: 100%;
        }
      }
      .actionbtn {
        text-align: center;
        margin-top: 5rem;
        @media (max-width: 1199px) {
          margin-top: 3rem;
        }
        button {
          font-size: 2rem;
          font-weight: 600;
          background-color: $dark-gray-300;
          color: $white;
          padding: 1rem 0.6rem 0.6rem;
          width: 15rem;
          border: none;
          &.active {
            background-color: $primary;
          }
        }
      }
    }
  }

  // @include media-breakpoint-down(md) {
  //   // min-height: auto;
  //   height: auto;
  // }
}
.login-head-m {
  background-color: $primary;
  height: 7.2rem;
  width: 100%;
  padding: 1.4rem 0.9rem;
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
  .cat-logo {
    a {
      img {
        height: 4.4rem;
      }
    }
  }
}

.termsCheckbox {
  display: flex !important;
  flex-direction: row;
  margin-bottom: -1.5rem;

  input {
    margin-right: 1.5rem;
    margin-bottom: 1.6rem;
  }

  input:checked:after {
    color: red;
    background-color: #000;
    background: greenyellow;
  }
}

.error-tc {
  font-size: 1.2rem;
  color: tomato;
}
.new-login-container{
  background-color: #EB0E8C!important;
  height: 100vh!important;
  min-height: 80vh!important;
  .page_back{
    color: white!important;
    position: absolute;
    top: 5%;
    left:10%;
    span{
      font-size: 20px;
    }
  }
  .welcome_h1 h1,.welcome_st{
    color: white!important;
  }
  .welcontent{
    margin: 5% 0 0 0!important;
  }
}

.new-login-container-lower {
  position: absolute;
  bottom: 0;
  height: 60vh;
  background-color: white;
  padding: 0 5%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  .btn-pink{
    background-color: #EB0E8C;
    color: white;
  }
  .mobile-success-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    h1{
      margin-top: 20px;
    }
    p{
      font-size: 18px;
    }
  }
  .edit-info{
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 25px;
    a{
      color: #1D4ED8!important;
      font-weight: 500!important;
    }
  }
  .otpmsg-link{
    color: #1D4ED8!important;
    text-decoration: underline;
  }
  .divider{
    margin: 3rem 0!important;
  }
  .social-login-box .socialcomm{
    margin-bottom:3rem!important;
  }
  .divi_text{
    font-size: 2rem!important;
  }
  .formhead {
    text-align: center;
    font-size: 24px !important;
    color: #000000 !important;
    font-weight: 300 !important;
    margin: 2rem 0!important;
  }
  .terms-text {
    font-weight: 300;
    color: #a1a1aa;
    font-size: 14px;
    text-align: center;
  }
  .disabled-text{
    color: #a1a1aa;
    margin: 0!important;
  }
  .formcommon{
    padding-bottom: 10%;
    .footer-section,.footer-section a{
      font-weight: 400;
      color: #a1a1aa !important;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .otpcont{
      flex-direction: column;
    }
    .footer-section {
      text-align: center;
      a {
        text-decoration: underline;
        margin: 0 5px;
      }
    }
  }
  .formcommon-otp {
    .otpcont{
      .otpbox{
        margin-top: 30px;
      }
      span{
        color: #a1a1aa;
        margin-left: 5px;
        margin-top: -1px;
      }
    }
  }
  .actionbtn {
    margin: 0 !important;
    button {
      margin-left: 10px;
      width: auto !important;
    }
  }
  .confirmbtn,.confirmactionbtn{
    text-align: center;
  }
  .confirmactionbtn{
    margin-bottom: 20%!important;
    .confirmbtn{
      border: none!important;
      height: 40px;
      font: 500;
      font-size: 20px;
      span{
        margin-left: 25px;
        height: 15px!important;
      }
    }
    .confirmbtn:disabled{
      background-color: #A1A1AA;
      color: white!important;
    }
  }
}
