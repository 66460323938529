.author-grid {
  width: 380px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .author-one {
    width: auto;
    height: auto;
    margin-right: 30px;
    .author-image-div {
      width: auto;
      height: auto;
      .author-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    .author-title {
      width: 100px;
      // height: 22px;
      font-family: "Mukta",system-ui, -apple-system, BlinkMacSystemFont;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.01em;
      // text-transform: capitalize;
      color: #000000;
    }
  }
}

.author-top-bar {
  width: 380px;
  height: auto;
  min-height: 30px;
  // padding-bottom: 0.8rem;
  font-family: "Mukta",system-ui, -apple-system, BlinkMacSystemFont;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  color: #eb0e8c;
}
