.btn {
  min-width: 10rem;

  &.btn-primary {
    color: $white;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.btn-yellow {
    background-color: $yellow-light;
    color: $white;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.btn-warning {
    color: $white;
  }
  &.btn-outline-primary , &.btn-outline-warning{
    &.active {
      color: $white;
    }
    &:hover {
      color: $white;
    }
  }
  &.btn-auto {
    min-width: auto;
  }
  &.btn-info {
    color: $white;
  }
}

.btn-group {
  &>.btn{
    &:not(:last-child){
      &:not(.dropdown-toggle) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:not(:first-child) {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.btn-orange {
  color: #fff;
  background-color: #F58324;
}
.btn-pink {
  color: #fff;
  background-color: #D9368B;
}
.btn-teal {
  color: #fff;
  background-color: #016369;
}
.btn-grey {
  background: #A1A1AA;
  color: white;
}
.btn-arrow {
  min-width: 14.4rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.6rem;
  text-align: left;
  padding: .9rem 3.8rem .9rem 1.8rem;
  border: none;
  border-radius: 1rem;
  position: relative;
  
  @include media-breakpoint-down(xxl) {
    min-width: 12rem;
    padding: 0.8rem 1.5rem;
  }

  &::after {
    content: '\e90a';
    font-family: 'icomoon';
    font-display: swap;
    font-weight: 400;
    position: absolute;
    top: 50%;
    right: 1.8rem;
    transform: translateY(-50%);
  }
}