@mixin img-box($padding) {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  &::after {
    content: "";
    width: 100%;
    display: block;
    padding-bottom: $padding;
  }
  img {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    min-height: 100%;
  }
}

@mixin img-box-react($padding) {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  &::after {
    content: "";
    width: 100%;
    display: block;
    padding-bottom: $padding;
  }
  > span {
    position: initial !important;
    @extend .d-block;
  }
  img {
    position: absolute;
    left: 0;
    top: 50% !important;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    min-height: 100%;
    @extend .m-0;
  }
}

@mixin link($link, $hover, $active) {
  color: $link;
  &:hover {
    color: $hover;
  }
  &:active {
    color: $active;
  }
}

/*********************
CUSTOM BREAKPOINTS
*********************/

@mixin breakpoint($point) {
  @if $point == minilaptop {
    @media (max-width: 128rem) {
      @content;
    }
  } @else if $point == laptop {
    @media (max-width: 136.6rem) {
      @content;
    }
  } @else if $point == desktop {
    @media (max-width: 160rem) {
      @content;
    }
  } @else if $point == largedesktop {
    @media (min-width: 192rem) {
      @content;
    }
  }
}
