@import "bootstrap/scss/functions";
@import "scss/custom-variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// $colors: (
//   "pink":       $pink,
//   "green":      $green,
//   "yellow":     $yellow,
//   "white":      $white,
//   "black":      $black,
//   "gray":       $gray,
//   "orange":     $orange,
//   "teal":       $teal,
// );
@import "bootstrap/scss/maps";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
/*! purgecss start ignore */
@import "bootstrap/scss/buttons";
// @import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/nav";

@import "bootstrap/scss/pagination";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";
// @import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";
@import "scss/custom-mixins";
@import "scss/typography";
@import "scss/icons";
@import "scss/buttons";
@import "scss/login";
@import "scss/modal";
@import "scss/menu";
@import "scss/header";
@import "bootstrap/scss/navbar";
@import "scss/footer";
@import "scss/common";
@import "scss/profile";
@import "scss/news-detail";
@import "scss/quiz";
@import "scss/stories";
@import "scss/storie-swiper";
@import "scss/storie-slider";
@import "scss/sponser-ad";
@import "scss/news-card";
@import "scss/gallery";
@import "scss/columnCard";
@import "scss/preferences";
@import "scss/live-card";
@import "scss/columnDetail";
@import "scss/poll";
@import "scss/polls";
@import "scss/column-story";
@import "scss/ftafat";
@import "scss/loading";
@import "scss/audio-player";
@import "scss/audio-show-details";
@import "scss/homepageBanner";
@import "scss/electionBlog";
@import "scss/liveStreamElectionTabs";
@import "scss/electionBanner";
@import "scss/electionHardcoreBanner";
@import "scss/newsCardBanner";
@import "scss/games";
@import "scss/authors-grid";
@import "scss/home-quiz.scss";
@import "scss/shorts-videos";
@import "scss/search";
@import "scss/cricket-card";
@import "scss/liveblog";
@import "scss/adda-schedule";
