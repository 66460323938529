body {
  &.bm-menu-open {
    overflow: hidden;
  }
}
.slidenav-btn {
  .bm-overlay {
    top: 0;
    left: 0;
    background-color: transparent !important;
    height: 100vh !important;
  }

  .menu-overlay {
    // display: none;
  }
  .bm-burger-button {
    width: 3rem;
    height: 1.5rem;
    position: relative;
    // margin-top: 0.5rem;
    & > button {
      width: 3rem !important;
      height: 2.5rem !important;
      z-index: 1;
    }
    & > span {
      height: 100%;
    }
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: $primary;
      width: 2.4rem;
      height: 0.356rem !important;
      display: block;
      border-radius: 22%;
      margin: 0;
      left: 0 !important;
      &:nth-child(2) {
        top: 0.8rem !important;
      }
      &:nth-child(3) {
        top: 1.6rem !important;
        width: 1.2rem;
      }
    }
    .bm-burger-bars-hover {
      opacity: 1;
    }
    &.open {
      span {
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        // font-weight: normal;
        font-weight: 300;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-display: swap;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        &::after {
          content: "\e936";
          color: $primary;
          font-size: 2rem;
        }
        & > span {
          display: none;
        }
      }
    }
  }
  .bm-menu-wrap {
    position: absolute !important;
    height: 100vh !important;
    // top: 5rem;
    // left: 0;
    box-shadow: 0.1rem 0.4rem 0.4rem rgba(0, 0, 0, 0.35);
    // max-height: calc(100vh - 20rem) !important;
    border-radius: 1.5rem;
    background-color: #eb0e8c;
    overflow: hidden;
    // padding-top: 2rem;
    overflow-y: scroll;
    @include media-breakpoint-down(md) {
      // max-width: 30rem;
      top: 5rem;
      // max-height: calc(100vh - 30rem) !important;
      left: 0;
      // padding-top: 0;
      overflow-y: scroll !important;
    }
    /* General sidebar styles */
    .bm-menu {
      // background: $dark-gray-500;
      padding: 1.5rem 0 0;
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
    .bm-cross-button {
      height: 2.4rem;
      width: 2.4rem;
      left: 1rem !important;
      display: none;
      @include media-breakpoint-down(md) {
        // display: block;
      }
      .bm-cross {
        width: 0.2rem !important;
        height: 1.8rem !important;
        background-color: $black;
      }
    }
  }
  .bm-item-list {
    padding: 0;
  }
  .menu-head {
    background-color: #eb0e8c;
    position: relative;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    @include media-breakpoint-down(md) {
      // padding: 0 2.6rem 3rem;
    }
    &::before {
      content: "";
      width: 100%;
      height: 3.5rem;
      background-color: $white;
      position: absolute;
      top: -3.5rem;
      left: 0;
      z-index: 0;
    }
    .close-button {
      display: flex;
      align-items: end;
      justify-content: end;
      i {
        color: white;
        margin: 20px 10px 0 0;
      }
    }
    .profile-box {
      align-items: center;
      justify-content: flex-end;
      padding: 0 2.3rem 3rem;
      width: 100%;
      display: none;
      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        padding: 0 3rem 1.6rem;
        margin-bottom: 1rem;
      }
      .user-pic {
        text-align: center;
        padding: 2px;
        background-color: white;
        border-radius: 50%;
      }
      .pro-text {
        text-align: center;
        // margin-right: 1.5rem;
        margin-top: 8px;
        .m-signin {
          a {
            font-size: 26px;
            font-weight: 600;
            color: white;
          }
        }
        .user-name {
          word-break: break-word;
          color: white;
          // font-family: Hind;
          font-size: 22px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: center;
        }
        .user-contact {
          word-break: break-word;
          color: #ffffff94;
          // font-family: Hind;
          font-size: 15px;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0.03em;
          text-align: center;
        }
      }
      .pic-wrap {
        width: 8.1rem;
        height: 8.1rem;
        padding: 0.4rem;
        flex: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1.8px solid white;
        .pic-thumb {
          width: 6.8rem;
          height: 6.8rem;
          border-radius: 50%;
          border: 1.5px solid white;
          overflow: hidden;
          img {
            width: 6.8rem;
          }
        }
      }
    }
    .head-top {
      padding: 0 2.3rem 3rem;
      justify-content: space-evenly;
      @include media-breakpoint-down(md) {
        padding: 0 2.6rem 2.3rem;
      }
      @extend .d-flex, .align-items-center;
      .menu-head-box {
        font-size: 1.6rem;
        @extend .d-flex, .justify-content-center;
        text-align: center;
        .menu-head-icon {
          font-size: 3.5rem;
          font-weight: 600;
          width: 5.7rem;
          height: 5.7rem;
          color: white;
          background: rgb(243 224 234 / 57%);
          border-radius: 50%;
          text-align: center;
          margin: 0 auto 0.9rem;
          @extend .d-flex, .align-items-center, .justify-content-center;

          span {
            color: white !important;
          }
        }
        .menu-head-text {
          color: $white;
          font-size: 14px;
        }
      }
    }
  }
  .menu-body {
    background-color: $white;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      // border-top: 0.1rem solid $gray-light-300;
      li {
        border-bottom: 0.1rem solid $gray-light-300;
        a {
          padding: 01.6rem 2.3rem;
          font-size: 16px;
          @extend .d-flex, .align-items-center;
          @include media-breakpoint-down(md) {
            padding: 01.6rem 2rem;
          }
        }
        i {
          color: $pink;
          margin-right: 1.125rem;
          width: 5rem;
          font-size: 2rem;
        }
        .custom-topic-icon {
          background-color: $light-pink;
          width: 5rem;
          height: 5rem;
          padding-top: 1.5rem;
          padding-left: 1rem;
          text-align: center;
          border-radius: 50%;
          align-self: center;
        }
        img {
          color: $yellow-light;
          margin-right: 1.125rem;
          width: 5rem;
          font-size: 2rem;
        }
        span {
          font-weight: 500;
          color: $black;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  .menu-foot {
    background-color: $dark-gray-500;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: $black;
        border-bottom: 0.03rem solid $gray-light-300;
        a,
        div {
          color: $dark-gray-600;
          padding: 01.6rem 2.3rem;
          font-size: 1.6rem;
          @extend .d-flex, .align-items-center, .justify-content-between;
          @include media-breakpoint-down(md) {
            padding: 01.6rem 2.6rem;
          }
        }
        i {
          font-size: 1.1rem;
        }
        &:last-child {
          border-bottom: 0;
          &.menu-logout {
            display: none;
            @include media-breakpoint-down(md) {
              display: block;
            }
          }
        }
        &.menu-foot {
          display: none;
          @include media-breakpoint-down(md) {
            display: block;
          }
        }
      }
    }
  }
}
.sticky {
  .slidenav-btn {
    .bm-menu-wrap {
      // height: calc(100vh - 5rem) !important;
      top: 5rem;
      @include media-breakpoint-down(md) {
        max-height: calc(100vh - 11.8rem) !important;
      }
    }
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
}
.bottom-menu-ad {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-top: -30px !important;
  z-index: 999;
  background-color: $white;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 400ms;
  @include media-breakpoint-down(xl) {
    display: block;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      text-align: center;
      width: 100%;
      a {
        text-align: center;
        padding: 1rem 0;
        color: $dark-gray-100;
        display: flex;
        flex-flow: column;
        span {
          margin: 0;
          font-weight: 500;
        }
      }
      &.active {
        a {
          color: $primary;
          .icon-home {
            &::before {
              content: "\e95b";
            }
          }
          .icon-vidos {
            &::before {
              content: "\e969";
            }
          }
          .icon-b-read {
            &::before {
              content: "\e968";
            }
          }
          .icon-shows {
            &::before {
              content: "\e967";
            }
          }
          .icon-quiz {
            &::before {
              content: "\e962";
            }
          }
        }
      }
    }
  }
}
.bottom-menu {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-top: -30px !important;
  z-index: 9999;
  background-color: $white;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 400ms;
  @media (min-width: 501px) {
    z-index: 999;
  }
  @include media-breakpoint-down(xl) {
    display: block;
    height: 60px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    li {
      text-align: center;
      width: 100%;
      a {
        text-align: center;
        padding: 1rem 0;
        color: $dark-gray-100;
        display: flex;
        // flex-flow: column;
        flex-direction: column;
        gap: 2px;
        span {
          margin: 0;
          font-weight: 500;
          height: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
        }
      }
      &.active {
        a {
          color: $primary;
          .icon-home {
            &::before {
              content: "\e95b";
            }
          }
          .icon-vidos {
            &::before {
              content: "\e969";
            }
          }
          .icon-b-read {
            &::before {
              content: "\e968";
            }
          }
          .icon-shows {
            &::before {
              content: "\e967";
            }
          }
          .icon-quiz {
            &::before {
              content: "\e962";
            }
          }
        }
      }
    }
  }
}
.FooterHide {
  bottom: -7rem;
}
.FooterAdHide {
  bottom: -9rem;
}
.icon-padding {
  padding-right: 0 !important;
  margin: 0 auto !important;
}
.menu-drawer {
  overflow-y: scroll;
  @media (max-width: 501px) {
    width: 100vw !important;
  }
}
.user-name {
  word-break: break-word;
  color: white;
  // font-family: Hind;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}
.user-contact {
  word-break: break-word;
  color: #ffffff94;
  // font-family: Hind;
  font-size: 15px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-align: center;
}
//fgdgdfhgdfh
.slidenav-btn-desktop {
  .bm-overlay {
    top: 0;
    left: 0;
    background-color: transparent !important;
    height: 100vh !important;
  }

  .menu-overlay {
    // display: none;
  }
  .bm-burger-button {
    width: 3rem;
    height: 2.5rem;
    position: relative;
    margin-top: 0.5rem;
    & > button {
      width: 3rem !important;
      height: 2.5rem !important;
      z-index: 1;
    }
    & > span {
      height: 100%;
    }
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: $primary;
      width: 2.4rem;
      height: 0.356rem !important;
      display: block;
      border-radius: 22%;
      margin: 0;
      left: 0 !important;
      &:nth-child(2) {
        top: 0.8rem !important;
      }
      &:nth-child(3) {
        top: 1.6rem !important;
        width: 1.2rem;
      }
    }
    .bm-burger-bars-hover {
      opacity: 1;
    }
    &.open {
      span {
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        // font-weight: normal;
        font-weight: 300;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-display: swap;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        &::after {
          content: "\e936";
          color: $primary;
          font-size: 2rem;
        }
        & > span {
          display: none;
        }
      }
    }
  }
  .bm-menu-wrap {
    position: absolute !important;
    height: auto !important;
    top: 5rem;
    // left: 0;
    box-shadow: 0.1rem 0.4rem 0.4rem rgba(0, 0, 0, 0.35);
    max-height: calc(100vh - 20rem) !important;
    border-radius: 1.5rem;
    background-color: #eb0e8c;
    overflow: hidden;
    // padding-top: 2rem;
    overflow-y: scroll;
    @media (min-width: 1000px) {
      left: 0;
    }
    @include media-breakpoint-down(md) {
      // max-width: 30rem;
      top: 5rem;
      max-height: calc(100vh - 30rem) !important;
      left: 0;
      // padding-top: 0;
      overflow-y: scroll !important;
    }
    /* General sidebar styles */
    .bm-menu {
      // background: $dark-gray-500;
      padding: 1.5rem 0 0;
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
    .bm-cross-button {
      height: 2.4rem;
      width: 2.4rem;
      left: 1rem !important;
      display: none;
      @include media-breakpoint-down(md) {
        // display: block;
      }
      .bm-cross {
        width: 0.2rem !important;
        height: 1.8rem !important;
        background-color: $black;
      }
    }
  }
  .bm-item-list {
    padding: 0;
    // margin-bottom: -3rem;
  }
  .menu-head {
    background-color: #eb0e8c;
    position: relative;

    @include media-breakpoint-down(md) {
      // padding: 0 2.6rem 3rem;
    }
    &::before {
      content: "";
      width: 100%;
      height: 3.5rem;
      background-color: #eb0e8c;
      position: absolute;
      top: -3.5rem;
      left: 0;
      z-index: 0;
    }
    .profile-box {
      align-items: center;
      justify-content: flex-end;
      padding: 0 2.3rem 1rem;
      width: 100%;
      background-color: #eb0e8c;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        display: flex;
        padding: 1.6rem 3rem;
        margin-bottom: 2rem;
      }
      .pro-text {
        // text-align: right;
        // margin-right: 1.5rem;
        margin-top: 5px;
        .m-signin {
          a {
            font-size: 2rem;
            font-weight: 600;
          }
        }
        .user-name {
          font-size: 1.8rem;
          font-weight: 600;
          word-break: break-word;
          color: $white;
        }
        .user-contact {
          font-size: 1.4rem;
          font-weight: 400;
          word-break: break-word;
          color: $white;
        }
      }
      .pic-wrap {
        width: 5.6rem;
        height: 5.6rem;
        padding: 0.4rem;
        flex: inherit;
        .pic-thumb {
          width: 4.8rem;
          height: 4.8rem;
          border-radius: 50%;
          border: 0.1rem solid $skyblue-light-100;
          overflow: hidden;
          img {
            width: 4.8rem;
          }
        }
      }
    }
    .head-top {
      padding: 0 2.3rem 3rem;
      justify-content: space-around;
      @include media-breakpoint-down(md) {
        padding: 0 2.6rem 2.3rem;
      }
      @extend .d-flex, .align-items-center;
      .menu-head-box {
        font-size: 1.6rem;
        @extend .d-flex, .justify-content-center;
        text-align: center;
        .menu-head-icon {
          font-size: 2.5rem;
          font-weight: 600;
          width: 4.482rem;
          height: 4.482rem;
          background-color: rgba(243,224,234,.57);
          color: $primary;
          border-radius: 50%;
          text-align: center;
          margin: 0 auto 0.9rem;
          @extend .d-flex, .align-items-center, .justify-content-center;
        }
        .menu-head-text {
          color: $white;
        }
      }
    }
  }
  .menu-body {
    background-color: $white;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      border-top: 0.1rem solid $gray-light-300;
      li {
        border-bottom: 0.1rem solid $gray-light-300;
        a {
          padding: 01.6rem 2rem;
          font-size: 16px;
          @extend .d-flex, .align-items-center;
          @include media-breakpoint-down(md) {
            padding: 01.6rem 2.6rem;
          }
        }
        .custom-topic-icon {
          background-color: #fbebf3;
          width: 4rem;
          height: 4rem;
          padding-top: 1rem;
          padding-left: 1rem;
          text-align: center;
          border-radius: 50%;
          align-self: center;
      }
        img {
          margin-right: 1.125rem;
          width: 4rem;
          font-size: 2rem;
        }
        i {
          color: #d9368b;
          margin-right: 1.125rem;
          width: 3rem;
          font-size: 2rem;
        }
        span {
          font-weight: 500;
          color: $black;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  .menu-foot {
    background-color: $dark-gray-500;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: $black;
        border-bottom: 0.03rem solid $gray-light-300;
        a,
        div {
          color: $dark-gray-600;
          padding: 01.6rem 2.3rem;
          font-size: 1.6rem;
          @extend .d-flex, .align-items-center, .justify-content-between;
          @include media-breakpoint-down(md) {
            padding: 01.6rem 2.6rem;
          }
        }
        i {
          font-size: 1.1rem;
        }
        &:last-child {
          border-bottom: 0;
          &.menu-logout {
            display: none;
            @include media-breakpoint-down(md) {
              display: block;
            }
          }
        }
        &.menu-foot {
          display: none;
          @include media-breakpoint-down(md) {
            display: block;
          }
        }
      }
    }
  }
}
