.main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media-breakpoint-down(xl) {
    display: block;
  }
  .left-container {
    flex-basis: 10rem !important;
    .live-card-box {
      margin-bottom: 0.5rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
      .live-top-bar {
        padding: 1.6rem 2.4rem;
        color: $white;
        border-radius: 1.5rem 1.5rem 0px 0px;
        @extend .d-flex, .justify-content-between, .align-items-center;

        &.bar-bg-5 {
          background-color: #6fcbd0;
        }
        .live-title-left {
          font-size: 2.7rem;
          @extend .d-flex,
            .justify-content-between,
            .align-items-center,
            .fw-medium;
          .live-circle {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background-color: $red;
            margin-right: 1.6rem;
          }
        }
      }
    }
  }
  .live-title-left {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 19.3265px;
    line-height: 100.9%;
    color: #ce0808;

    @extend .d-flex, .align-items-center, .fw-medium;
    .live-circle {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background-color: $red;
      margin-right: 1.6rem;
    }
  }
  .right-sidebar {
    flex: 0 0 53.1rem;
    min-width: 30rem;
    padding-left: 1.5rem;
    @media (max-width: 1800px) {
      flex: 0 0 43.1rem;
    }
    @media (max-width: 1440px) {
      flex: 0 0 37.4rem;
      padding-left: 1.2rem;
    }
    @include media-breakpoint-down(xxl) {
      flex: 0 0 37.4rem;
      padding-left: 1.2rem;
    }
    .good-morning-sec {
      display: block;
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }
  }
  .good-morning-sec {
    display: none;
    background-color: $white;
    border-radius: 1.5rem;
    padding: 1.8rem;
    position: relative;
    margin-bottom: 1.8rem;
    width: 100%;
    position: relative;
    filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.1));
    @include media-breakpoint-down(xl) {
      display: block;
    }
    @include media-breakpoint-down(md) {
      border-radius: 1rem;
      padding-top: 0.05rem;
    }
    cursor: pointer;
    .morning-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      @include media-breakpoint-down(xl) {
        display: none;
      }
      .top-title {
        h3 {
          font-size: 2.8rem;
          font-weight: 500;
          @media (max-width: 1800px) {
            font-size: 2rem;
          }
          a {
            color: $primary;
          }
        }
      }
      .open-morning-icon {
        span {
          font-size: 1.8rem;
          color: $primary;
          margin-right: 0.2rem;
        }
      }
    }
    .morning-body {
      @extend .d-flex, .align-items-center;
      margin-bottom: 2.6rem;
      @media (max-width: 1200px) {
        margin-top: 1rem;
        margin-bottom: 1.8rem;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 0.05rem;
      }
      .morning-clock {
        @extend .d-flex, .align-items-center, .justify-content-center;
        flex: 0 0 6.4rem;
        height: 6.4rem;
        width: 6.4rem;
        background-color: $skyblue-light-300;
        border-radius: 50%;
        margin-right: 2.8rem;
        span {
          font-size: 3.8rem;
          margin-left: -0.6rem;
          color: $skyblue-light-100;
        }
      }
      .morning-content {
        display: flex;
        align-items: center;
        width: calc(100% - 9.2rem);
        @include media-breakpoint-down(xl) {
          align-items: flex-end;
        }
        .morning-text {
          h3 {
            font-size: 2rem;
            font-weight: 500;
            display: none;
            @include media-breakpoint-down(xl) {
              display: block;
            }
          }
          p {
            font-size: 2.8rem;
            color: $black;
            margin-bottom: 0;
            @media (max-width: 1800px) {
              font-size: 2rem;
            }
          }
        }
        .morning-arrow {
          margin-left: auto;
          display: none;
          @include media-breakpoint-down(xl) {
            display: block;
          }
          @media (max-width: 767px) {
            margin-bottom: 2rem;
          }
          span {
            padding: 0.5rem;
            color: $primary;
          }
        }
      }
    }
    &::after {
      content: "";
      width: 100%;
      height: 0.8rem;
      background-color: $primary;
      border-radius: 0px 0px 10px 10px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
.live-card-body {
  padding: 0rem 1.5rem 0.75rem 0rem;
  background-color: $white;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  @extend .d-flex;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    padding: 1.5rem 1.5rem 1.05rem;
  }
  @media screen and (max-width: 500px) {
    padding: 0rem 0rem 0rem 0rem;
  }
  .livecar-left-col {
    flex: 0 0 48%;
    @extend .d-flex, .flex-column, .justify-content-between;
    margin-right: 1.4rem;
    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 3rem;
      flex: 0 0 100%;
      border-bottom: 0.1rem solid #cecdcd;
      padding-bottom: 3rem;
    }
    .live-video-banner {
      position: relative;
      border-radius: 1.5rem;
      overflow: hidden;
      margin-bottom: 1.8rem;
      width: 100%;
      .video-banner-wrap {
        a {
          @include img-box-react(56.25%);
        }
      }
      .video-duration {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.7) 100%
        );
        color: $white;
        padding: 1.6rem;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        i {
          padding-right: 0.8rem;
        }
        span {
          font-size: 1.6rem;
          align-self: baseline;
        }
      }
    }
    .live-headline-body {
      @extend .d-flex, .align-items-start, .flex-column;
      .more-col {
        width: auto;
        flex: 0 0 auto;
        font-size: 1.8rem;
        cursor: pointer;
        margin-top: 0.8rem;
      }
    }
  }
  .livecar-right-col {
    width: 100%;
    .schedule-dates-box {
      margin-bottom: 2.4rem;
      .live-schedule-top {
        @extend .d-flex, .align-items-center, .justify-content-between;
        margin-bottom: 1.5rem;
        h5 {
          margin-bottom: 0;
        }
        .view-schedule {
          a {
            @extend .d-flex, .align-items-center;
            span {
              font-size: 1.2rem;
              margin-left: 0.5rem;
            }
          }
        }
      }
      .schedule-dates-list {
        .live-dates-list {
          padding: 0;
          margin: 0;
          list-style: none;
          @extend .d-flex,
            .align-items-center,
            .justify-content-between,
            .flex-wrap;
          li {
            background-color: $gray-light-300;
            padding: 0.8rem 1rem;
            border: 0;
            border-radius: 1.5rem;
            text-align: center;
            cursor: pointer;
            &:last-child {
              margin-right: 0;
            }
            &.active {
              color: $white;
              background-color: $primary;
            }
            .date-month {
              display: flex;
              font-size: 1.2rem;
              font-weight: 400;
            }
            .day {
              font-size: 1.4rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .live-card-carousel {
    width: 100%;
    box-shadow: 1px 2px 4px rgb(0 0 0 / 10%);
    border-radius: 0.48rem 0.48rem 1.2rem 1.2rem;
    .carousel {
      // margin-bottom: 3.9rem;
      .carousel-item {
        .live-card-box {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          background-color: #f3f4f6;
        }
      }
      .carousel-indicators {
        bottom: -3.5rem;
        margin-bottom: 2rem;
        [data-bs-target] {
          background-color: $gray-light-400;
          &.active {
            background-color: $green;
          }
        }
      }
    }
  }
}
.live-show-left {
  flex: 1;
  min-width: 30rem;
  .liveVideos > iframe {
    height: 191px;
    width: 35rem;
    @media screen and (min-width: 1500px) {
      height: 247px;
      width: 45rem;
    }
    @media screen and (max-width: 1000px) {
      height: 350px;
    }
    @media screen and (max-width: 820px) {
      height: 350px;
    }
    @media screen and (max-width: 620px) {
      height: 300px;
    }
    @media screen and (max-width: 500px) {
      height: 217px !important;
      width: 100% !important;
    }
  }
  @include media-breakpoint-down(md) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
  }
  .live-video-banner {
    position: relative;
    border-radius: 1.5rem;
    overflow: hidden;
    // margin-bottom: 1.8rem;
    width: 100%;
    .video-banner-wrap {
      border-radius: 1.5rem;
      a {
        @include img-box-react(56.25%);
      }
    }
    .video-duration {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
      );
      color: $white;
      padding: 1.6rem;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      i {
        padding-right: 0.8rem;
        font-size: 1.6rem;
      }
      span {
        font-size: 1.6rem;
        align-self: baseline;
      }
    }
  }
}
.live-backlink-bar {
  margin-bottom: 0.5rem !important;
  padding-left: 1.8rem;
}
.search-backlink-bar {
  padding-left: 1.8rem;
}
.live-show-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  .headlive-box {
    justify-content: space-between;
  }
  @include media-breakpoint-down(md) {
    margin-top: 2rem;
  }
}
.flex-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  h5 {
    color: black !important;
  }
}
.ongoing-tag {
  color: #45b55e;
}
.headlive-box {
  @extend .d-flex, .align-items-start;
  .headline-live {
    width: 100%;
    .live-ongoing {
      .ongoing-tag {
        font-size: 1.6rem;
        font-weight: 600;
        color: $green-medium;

        border-radius: 0.5rem;
      }
      .hashtags-list {
        @extend .d-flex, .list-unstyled, .mb-0, .flex-wrap;
        li {
          a {
            font-size: 1.6rem;
            color: $skyblue-light-100;
            @extend .d-flex;
          }
          + li {
            margin-left: 1rem;
          }
        }
      }
    }
    h3 {
      font-size: 1.8rem;
      margin-bottom: 0;
      a {
        color: $black;
        &:hover {
          color: $primary;
        }
      }
    }
    p {
      color: $gray;
      &:empty {
        display: none;
      }
    }
  }
  .more-col {
    width: auto;
    flex: 0 0 auto;
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: 0.8rem;
  }
}
.headline-footer {
  @extend .d-flex, .list-unstyled, .mb-0;

  li:not(.like-item) {
    a {
      font-size: 1.4rem;
      color: $dark-gray-400;
      @extend .d-flex;

      i {
        font-size: 1.8rem;
        margin-right: 0.7rem;
        @include media-breakpoint-down(xl) {
          font-size: 1.5rem;
        }
      }
    }
    + li {
      margin-left: 1.8rem;
    }
  }
  .like-item {
    color: $dark-gray-400;
    @extend .d-flex, .ms-auto;

    span {
      font-size: 1.4rem;
    }
    i {
      cursor: pointer;
      font-size: 1.8rem;
      color: $primary;
      margin-right: 0.7rem;
    }
  }
}
.schedule-card-box {
  background: $white;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  padding: 1.4rem;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  @extend .d-flex;
  .schedule-banner {
    position: relative;
    flex: 0 0 40%;
    margin-right: 1.2rem;
    border-radius: 0.5rem;
    overflow: hidden;
    a {
      display: block;
      border-radius: 0.5rem;
      overflow: hidden;
      .banner-section {
        @include img-box-react(56.25%);
      }
    }
    .schedule-banner-bot {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
      );
      border-radius: 0 0 0.5rem 0.5rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 2.5rem;
    }
  }
  .schedule-content {
    width: 100%;
    .headline-body {
      @extend .d-flex, .justify-content-between;
      .headline-info {
        .series-row {
          .hashtags-list {
            @extend .d-flex, .list-unstyled, .mb-0, .flex-wrap;
            li {
              a {
                font-size: 1.4rem;
                color: $skyblue-light-100;
                @extend .d-flex;
              }
              + li {
                margin-left: 0.7rem;
              }
            }
          }
        }
        h3 {
          font-size: 1.4rem;
          margin-bottom: 0;
          a {
            color: $black;
          }
        }
      }
      .more-col {
        .icon-three-dots {
          width: auto;
          flex: 0 0 auto;
          font-size: 1.8rem;
          cursor: pointer;
          margin-right: -0.5rem;
        }
      }
    }
    .schedule-box-footer {
      @extend .d-flex, .align-items-center, .justify-content-between;
      .notify-box {
        font-size: 1.2rem;
        font-weight: 600;
        color: $primary;
        padding: 0.4rem 1.2rem;
        background-color: $pink-bglight;
        border-radius: 0.3rem;
      }
      .foot-box-right {
        @extend .d-flex;
        .schedule-duration {
          font-size: 0.8rem;
          color: $primary;
          text-align: right;
          p {
            font-size: 1.2rem;
            color: $dark-gray-400;
            margin: 0;
          }
          & + .schedule-duration {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}
.liveTabs {
  padding: 1rem 1.5rem 0 1.5rem !important;
  background-color: white;
}

.nav-tabs .nav-link.active {
  color: #d9368b !important;
  font-weight: 500;
}
.nav-tabs .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.vp-center {
  align-items: flex-start !important;
}
.live-body {
  margin-bottom: 0 !important;
  @media (max-width: 769px) {
    padding-bottom: 1.5rem !important;
  }
}
.chat-container {
  height: 100% !important;
}
.chat-container > iframe,
.card-right > div {
  min-height: 350px;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1200px) {
    min-height: 550px;
  }
  @media screen and (max-width: 820px) {
    min-height: 500px;
  }
  @media screen and (max-width: 620px) {
    min-height: 480px;
  }
  @media screen and (max-width: 500px) {
    min-height: 300px !important;
  }
}
.live-icon-image {
  margin-right: 1rem;
}
